import { Component, OnInit } from '@angular/core';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import Swal from 'sweetalert2';

import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';

var that: any;
@Component({
  selector: 'app-booking-dashboard',
  templateUrl: './booking-dashboard.component.html',
  styleUrls: ['./booking-dashboard.component.css']
})
export class BookingDashboardComponent implements OnInit {

  routesPath = AdminRoutesPath;
  constructor(
    private adminDataService: AdminDataService,
  ) { that = this }

  booking_list: any = [];
  dtOptions: DataTables.Settings = {};
  ngOnInit(): void {

    this.fetchAllBookingList();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: []
    };
  }

  fetchAllBookingList() {
    console.log("fecthing photeam data");
    this.adminDataService.getAllBookingList("fetch all booking list", this.successCB, this.errorCB);
  }

  successCB(results: any) {
    if (results.api == 'fetch all booking list') {
      if (results.data != null && results.data != undefined) {
        that.booking_list = results.data;
        console.log("booking_list", results.data);

      }
    }

    // if (results.api == 'change photeam state') {
    //   if (results.servercode == ServerCode.SUCCESS) {
    //     console.log(results);
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Your changes has been saved',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })


    //   }

    // }
  }

  errorCB(error: any) {
    if (error != null && error != undefined) {
      console.log(error);
    }
  }

}
