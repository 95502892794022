import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;
@Injectable({
  providedIn: 'root',
})
export class GenricService {
  private renderer: Renderer2;

  constructor(
    private toastr: ToastrService,
    private rendererFactory: RendererFactory2 // Inject RendererFactory2 instead
  ) {
    this.renderer = rendererFactory.createRenderer(null, null); // Create an instance of Renderer2
  }

  GST_regex =
    /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([1-9a-zA-Z]){1}([zZ]){1}([0-9a-zA-Z]){1}?$/;
  PAN_regex = /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
  studio_username_regex = /^[a-z0-9]{5,50}$/;

  is_premium(profile) {
    const membershipType = profile.membership_data.membership_type;
    const planId = profile.plan_data.plan_id;
    const renewAt = profile.plan_data.validity.renew_at;

    // Check if membership type is 'PREM'
    if (membershipType !== 'PREM') {
      return false;
    }

    // Check if plan_id is not 'PKG_00'
    if (planId === 'PKG_00') {
      return false;
    }

    // Check if today's date is less than renew_at
    const today = new Date();
    const renewDate = new Date(renewAt);

    if (today >= renewDate) {
      return false;
    }

    return true;
  }

  is_var_exist(variable, is_zero_allowed = false) {
    if (
      typeof variable !== 'undefined' &&
      variable !== null &&
      !(
        typeof variable === 'number' &&
        !is_zero_allowed &&
        (variable <= 0 || isNaN(variable))
      ) &&
      !(typeof variable === 'string' && variable.trim() === '') &&
      !(Array.isArray(variable) && variable.length === 0) &&
      !(
        typeof variable === 'object' &&
        Object.keys(variable).length === 0 &&
        variable.constructor === Object
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  generateUniqueId(length): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueId = '';
    for (let i = 0; i < length; i++) {
      uniqueId += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return uniqueId;
  }

  get_uuid() {
    return uuidv4();
  }

  accept_only_numbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      this.toastr.error('Enter Number');
      return false;
    } else {
      return true;
    }
  }

  is_email_valid(value) {
    let email = value;
    if (email != '' && email != null && email != undefined) {
      email = email.toLowerCase();
      email = email.trim();
      if (email.match('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') != null) {
        return true;
      }
      return false;
    }
  }

  is_mobile_valid(value) {
    var regex = /^[5-9]\d{9}$/g;
    return regex.test(value);
  }

  isDateValid(value) {
    // Parse the date string using Date object
    const dateParts = value.split('-');

    // Ensure there are exactly three parts (year, month, day) after splitting
    if (dateParts.length !== 3) {
      return false;
    }

    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is 0-based (0 for January, 11 for December)
    const day = parseInt(dateParts[2], 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Check if the parsed date is valid
    // - Valid month (0-11), valid day of the month (1-31), valid year (according to the calendar)
    // - Date object handles invalid date values (e.g., February 30th) by rolling over to the next valid date
    return (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    );
  }

  onNgChange(changes) {
    let keys = Object.keys(changes);
    // console.log(keys);
    keys.forEach((k) => {
      if (changes[k].previousValue != changes[k].currentValue) {
        // console.log(
        //   k + ' --changed--',
        //   changes[k].previousValue,
        //   changes[k].currentValue
        // );
      }
    });
  }

  scrollTop(el: ElementRef): void {
    setTimeout(() => {
      // Check if sidenavContent is present
      const sidenavContent = el.nativeElement.closest('.mat-sidenav-content');
      if (sidenavContent) {
        // Scroll to the top of sidenavContent
        this.renderer.setProperty(sidenavContent, 'scrollTop', 0);
      } else {
        // Scroll to the top of the body as a fallback
        const body = document.querySelector('body');
        if (body) {
          this.renderer.setProperty(body, 'scrollTop', 0);
          // Alternatively, you can use window.scrollTo for cross-browser compatibility
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    }, 1000);
  }

  form_error(id) {
    this.scroll_to_id(id);
    this.error_blink(id);
  }

  error_blink(id) {
    // Add the blink class to the input field
    $(`#${id}`).addClass('blink');
    // Remove the blink class after 2 seconds
    setTimeout(function () {
      $(`#${id}`).removeClass('blink');
    }, 3000);
  }

  scroll_to_id(id: string) {
    const element = document.getElementById(id);
    const container = document.querySelector('.mat-sidenav-content');
    const offset = 100; // Adjust this value as needed

    if (element && container) {
      // Scroll within the container
      const headerOffset = 200;
      // Get element's position relative to the container
      const elementPosition =
        element.getBoundingClientRect().top + container.scrollTop;
      const offsetPosition = elementPosition - headerOffset;

      container.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else if (element) {
      // Scroll normally to the element with an offset
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  is_uuid_v4(str: string): boolean {
    // Correct regex pattern for UUID v4
    const uuidv4Pattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidv4Pattern.test(str);
  }

  validate_GST(value: string): boolean {
    const trimmedValue = value?.trim();
    // Check if the value is non-empty, has length 15, and matches the regex
    return trimmedValue?.length === 15 && this.GST_regex.test(trimmedValue);
  }

  formatFileSize(bytes: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

    return `${size} ${sizes[i]}`;
  }

  khulasaKaroKunji(alteredString: string, indexes: number[]): string {
    // Convert the altered string to an array of characters
    let charArray = alteredString.split('');

    // Sort the indexes array in descending order
    indexes.sort((a, b) => b - a);

    // Remove characters at the specified indexes
    for (let i = 0; i < indexes.length; i++) {
      charArray.splice(indexes[i], 1);
    }

    // Join the array back into a string and return
    return charArray.join('');
  }

  // Function to get today's date in the format 'yyyy-MM-dd'
  getTodayDate(): string {
    const today = new Date();
    return today.toISOString().split('T')[0];
  }
}
