import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { Component, OnInit } from '@angular/core';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
var that: any;
@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {
  routesPath = AdminRoutesPath;
  constructor(private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal) { that = this; }

  booking: any;

  ngOnInit(): void {


    let booking_id = this.route.snapshot.params.booking_id;

    if (booking_id != null && booking_id != undefined) {
      this.fetchBookingDetailsById(booking_id);
    } else {
      this.router.navigateByUrl('/404');
    }
  }

  fetchBookingDetailsById(booking_id: any) {
    this.adminDataService.getBookingDetailsById("fetch booking by id", booking_id, this.successCB, this.errorCB);
  }

  successCB(results: any) {
    if (results.api == 'fetch booking by id') {
      if (results.data != null && results.data != undefined) {
        that.booking = results.data;
        console.log("booking", results.data);

      }
    }
  }

  errorCB(error: any) {
    if (error != null && error != undefined) {
      console.log(error);
    }
  }


}
