import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
var that

@Component({
  selector: 'app-invoice-pdf-download',
  templateUrl: './invoice-pdf-download.component.html',
  styleUrls: ['./invoice-pdf-download.component.css']
})
export class InvoicePdfDownloadComponent implements OnInit {


  @Input() invoice_data: any;
  @Input() type: any;
  loader = false;

  constructor(private toastr: ToastrService) { that = this; }


  activeformat = { gst: 'Percent', discount: 'Value' };
  discountFieldValue: number = 0;
  gstFieldValue: number = 0;


  onDownloadPDF() {
    this.loader = true;
    this.toastr.success("Please wait for the download!")

    setTimeout(function () {
      //your code to be executed after 2 second
      that.exportAsPDF();
    }, 2000);
  }

  exportAsPDF() {

    var file_name = this.invoice_data.client_details.clt_name + '- invoice -' + this.invoice_data.header.serial_no + '.pdf';

    const options = {
      margin: [5, 5, 5, 5],
      filename: file_name,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, logging: true },
      jsPDF: {
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16
      },
      pagebreak: { before: '.page_break' }
    };
    const content: Element = document.getElementById('element-to-export')!;

    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        var totalPages = pdf.internal.getNumberOfPages();
        var i: number = 1;
        for (i = 1; i <= totalPages; i++) {
          // 290
          // console.log(pdf.internal.pageSize.getHeight());
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor(150);
          pdf.text(i + ' / ' + totalPages, (pdf.internal.pageSize.getWidth() / 2) - 2, pdf.internal.pageSize.getHeight() - 8);
        }
        that.toastr.success("Download Success!")
      }).save();
    this.loader = false;
  }

  check_pay_tnc() {

    let value = false;
    this.invoice_data.pay_tnc.forEach(item => {
      if (item.pay_amount && item.pay_before) {
        value = true;
      }
    });

    // console.log(value);
    return value;
  }

  check_bank_dtls() {


    if (this.invoice_data.bank_dtls.account_holder_name) {
      return true;
    } else if (this.invoice_data.bank_dtls.account_number) {
      return true;
    } else if (this.invoice_data.bank_dtls.bank_name) {
      return true;
    } else if (this.invoice_data.bank_dtls.ifsc_code) {
      return true;
    } else if (this.invoice_data.bank_dtls.bank_upi) {
      return true;
    }

    return false;

  }

  check_tnc() {
    let value = false;
    this.invoice_data.tnc.forEach(item => {
      if (item.text) {
        value = true;
      }
    });

    // console.log(value);
    return value;
  }

  ngOnInit(): void {
  }


}
