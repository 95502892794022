import { Component, Input, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
var that;

@Component({
  selector: 'app-bill-pdf-download',
  templateUrl: './bill-pdf-download.component.html',
  styleUrls: ['./bill-pdf-download.component.css']
})
export class BillPdfDownloadComponent implements OnInit {

  constructor(private toastr: ToastrService) {
    that = this;
  }

  @Input() bill_data: any;
  loader = false;

  activeformat = { gst: 'Percent', discount: 'Value' };
  discountFieldValue: number = 0;
  gstFieldValue: number = 0;

  ngOnInit(): void {
  }

  onDownloadPDF() {
    this.loader = true;
    this.toastr.success("Please wait for the download!")

    setTimeout(function () {
      //your code to be executed after 2 second
      that.exportAsPDF();
    }, 2000);
  }

  exportAsPDF() {

    var file_name = this.bill_data?.client_details?.clt_name + '- bill -' + this.bill_data.basic.serial_no + '.pdf';

    const options = {
      margin: [1, 1, 1, 1],
      filename: file_name,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2, logging: true,
      },
      jsPDF: {
        orientation: 'p',
        unit: 'mm',
        format: 'a5',
        putOnlyUsedFonts: true,
        floatPrecision: 16
      },
      // pagebreak: { before: '.page_break' }
    };
    const content: Element = document.getElementById('element-to-export')!;

    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        var totalPages = pdf.internal.getNumberOfPages();
        var i: number = 1;
        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor(150);
          pdf.text(i + ' / ' + totalPages, (pdf.internal.pageSize.getWidth() / 2) - 2, pdf.internal.pageSize.getHeight() - 8);
        }
        that.toastr.success("Download Success!")
      }).save();
    this.loader = false;

  }

}
