<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Master Data</h1>
    <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
        class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Constants & Enums List</li>
    </ol>
  </nav>
  <!-- Content Row -->
  <div class="row">
    <div class="col-lg-4">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Genre skill level</h6>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive custom-scroll" *ngIf="ConstantsEnum?.length>0"
            style="overflow: auto; height: 220px;">
            <table class="table " id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category</th>
                  <th>value</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of ConstantsEnum">
                  <tr *ngIf="item.category=='skill_level'">
                    <td>{{item.code}}</td>
                    <td>{{item.category}}</td>
                    <td>{{item.value}}</td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Event Timeline (Post requirement) </h6>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive custom-scroll" *ngIf="ConstantsEnum?.length>0"
            style="overflow: auto; height: 220px;">
            <table class="table " id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category</th>
                  <th>value</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of ConstantsEnum">
                  <tr *ngIf="item.category=='event_timeline'">
                    <td>{{item.code}}</td>
                    <td>{{item.category}}</td>
                    <td>{{item.value}}</td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Photeam members role</h6>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive custom-scroll" *ngIf="ConstantsEnum?.length>0"
            style="overflow: auto; height: 220px;">
            <table class="table " id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category</th>
                  <th>value</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of ConstantsEnum">
                  <tr *ngIf="item.category=='user_role'">
                    <td>{{item.code}}</td>
                    <td>{{item.category}}</td>
                    <td>{{item.value}}</td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Photeam - User relation</h6>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive custom-scroll" *ngIf="ConstantsEnum?.length>0"
            style="overflow: auto; height: 350px;">
            <table id="myTable" class="table " id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category</th>
                  <th>value</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of ConstantsEnum">
                  <tr *ngIf="item.category=='photeam_user_relation'">
                    <td>{{item.code}}</td>
                    <td>{{item.category}}</td>
                    <td>{{item.value}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Ratings & Reviews</h6>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive custom-scroll" *ngIf="ConstantsEnum?.length>0"
            style="overflow: auto; height: 350px;">
            <table id="myTable" class="table " id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category</th>
                  <th>value</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of ConstantsEnum">
                  <tr *ngIf="item.category=='pteam_rating'">
                    <td>{{item.code}}</td>
                    <td>{{item.category}}</td>
                    <td>{{item.value}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Ratings & Reviews</h6>
        </div>
        <div class="card-body p-0 ">
          <div class="table-responsive custom-scroll" *ngIf="ConstantsEnum?.length>0"
            style="overflow: auto; height: 350px;">
            <table id="myTable" class="table " id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category</th>
                  <th>value</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of ConstantsEnum">
                  <tr *ngIf="item.category=='photeam_sort_value'">
                    <td>{{item.code}}</td>
                    <td>{{item.category}}</td>
                    <td>{{item.value}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Packages enum</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
        <table datatable [dtOptions]="dtOptions" id="myTable" class="table " id="dataTable" width="100%"
          cellspacing="0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Category</th>
              <th>value</th>
              <th>Display Text</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let item of ConstantsEnum">
              <tr *ngIf="item.category=='user_role'">

                <td>{{item.code}}</td>
                <td>{{item.category}}</td>
                <td>{{item.value}}</td>
                <td>{{item.displayText}}</td>

              </tr>
            </ng-container>

          </tbody>
        </table>
      </div>

    </div>
  </div> -->


  <!-- Packages collapse section -->
  <div class="card shadow mb-4">
    <!-- Card Header - Accordion -->
    <a href="#collapsePackagesMainCard" class="d-block card-header py-3" data-toggle="collapse" role="button"
      aria-expanded="true" aria-controls="collapsePackagesMainCard">
      <h6 class="m-0 font-weight-bold text-primary">Packages</h6>
    </a>
    <!-- Card Content - Collapse -->
    <div class="collapse show" id="collapsePackagesMainCard" style="">
      <div class="card-body p-0">

        <div class="row">
          <div class="col-lg-4">
            <div class="card shadow mb-4 mt-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Shoot Duration</h6>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
                  <table class="table " id="dataTable" width="100%" cellspacing="0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>value</th>
                        <th>Display Text</th>
                      </tr>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let item of ConstantsEnum">
                        <tr *ngIf="item.category_code=='shoot_duration'">
                          <td>{{item.code}}</td>
                          <td>{{item.value}}</td>
                          <td>{{item.displayText}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card shadow mb-4 mt-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Crew member count</h6>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
                  <table class="table " id="dataTable" width="100%" cellspacing="0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>value</th>
                        <th>Display Text</th>
                      </tr>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let item of ConstantsEnum">
                        <tr *ngIf="item.category_code=='assist_count'">
                          <td>{{item.code}}</td>
                          <td>{{item.value}}</td>
                          <td>{{item.displayText}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card shadow mb-4 mt-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Deliverables</h6>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
                  <table class="table " id="dataTable" width="100%" cellspacing="0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>value</th>
                        <th>Display Text</th>
                      </tr>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let item of ConstantsEnum">
                        <tr *ngIf="item.category_code=='deliverable'">
                          <td>{{item.code}}</td>
                          <td>{{item.value}}</td>
                          <td>{{item.displayText}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card shadow mb-4 mt-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Digital Photo Count</h6>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
                  <table class="table " id="dataTable" width="100%" cellspacing="0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>value</th>
                        <th>Display Text</th>
                      </tr>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let item of ConstantsEnum">
                        <tr *ngIf="item.category_code=='digital_photo_count'">
                          <td>{{item.code}}</td>
                          <td>{{item.value}}</td>
                          <td>{{item.displayText}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card shadow mb-4 mt-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Print Photo Count</h6>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
                  <table class="table " id="dataTable" width="100%" cellspacing="0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>value</th>
                        <th>Display Text</th>
                      </tr>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let item of ConstantsEnum">
                        <tr *ngIf="item.category_code=='print_photo_count'">
                          <td>{{item.code}}</td>
                          <td>{{item.value}}</td>
                          <td>{{item.displayText}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card shadow mb-4 mt-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Delivery Timeline</h6>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
                  <table class="table " id="dataTable" width="100%" cellspacing="0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>value</th>
                        <th>Display Text</th>
                      </tr>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let item of ConstantsEnum">
                        <tr *ngIf="item.category_code=='delivery_timeline'">
                          <td>{{item.code}}</td>
                          <td>{{item.value}}</td>
                          <td>{{item.displayText}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!-- --------------- -->
  <div class="card shadow mb-4">
    <!-- Card Header - Accordion -->
    <a href="#collapseMainTable" class="d-block card-header py-3" data-toggle="collapse" role="button"
      aria-expanded="true" aria-controls="collapseMainTable">
      <h6 class="m-0 font-weight-bold text-primary">Master const & enum Table</h6>
    </a>
    <!-- Card Content - Collapse -->
    <div class="collapse hide" id="collapseMainTable">
      <div class="card-body">
        <div class="table-responsive" *ngIf="ConstantsEnum?.length>0">
          <table datatable [dtOptions]="dtOptions" id="myTable" class="table " id="dataTable" width="100%"
            cellspacing="0">
            <thead>
              <tr>
                <th>Code</th>
                <th>Category</th>
                <th>Module</th>
                <th>Value</th>
                <th>displayText</th>
                <th>subText</th>
                <th>sortOrder</th>
                <th>category_code</th>
                <!-- <th>is_deleted</th>
                <th>deleted_at</th>
                <th>created_at</th>
                <th>updated_at</th> -->
              </tr>
            </thead>

            <tbody>
              <ng-container *ngFor="let item of ConstantsEnum">
                <tr>
                  <td>{{item.code}}</td>
                  <td>{{item.category}}</td>
                  <td>{{item.module}}</td>
                  <td>{{item.value}}</td>
                  <td>{{item.displayText}}</td>
                  <td>{{item.subText}}</td>
                  <td>{{item.sortOrder}}</td>
                  <td>{{item.category_code}}</td>
                  <!-- <td>{{item.is_deleted}}</td>
                  <td>{{item.deleted_at}}</td>
                  <td>{{item.created_at}}</td>
                  <td>{{item.updated_at}}</td> -->
                </tr>
              </ng-container>

            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
  <!-- --------------- -->

</div>
