import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { LocalStorage } from 'src/app/data/constants/local-storage-constants';
import { ResponsiveService } from 'src/app/data/services/responsive.service';

@Component({
  selector: 'app-photeam-selection-dropdown',
  templateUrl: './photeam-selection-dropdown.component.html',
  styleUrls: ['./photeam-selection-dropdown.component.css']
})
export class PhoteamSelectionDropdownComponent implements OnChanges, OnInit {
  @Input() photeam_list: any;
  @Input() selected_photeam: any;
  @Output() onDropdownChange = new EventEmitter<any>();

  storageURL = GlobalConstants.storageURL;
  isMobile: Boolean;

  constructor(
    private responsiveService: ResponsiveService,
  ) { }

  ngOnInit(): void {
    this.onResize();
    this.isMobile = localStorage.getItem(LocalStorage.DEVICE_TYPE) == 'mobile' ? true : false;

  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("photeam-selection-dropdown-change ", this.selected_photeam.photeam_data.photeam_name);
  }

  is_dropdown_open = false;
  tooglePhoteamSelectionDropDown() {
    this.is_dropdown_open = !this.is_dropdown_open;
  }

  changeSelectedPhoteam(photeam) {
    this.selected_photeam = photeam;
    this.tooglePhoteamSelectionDropDown();
    this.onDropdownChange.emit(photeam);
  }



}
