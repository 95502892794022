
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';

var that: any;
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  routesPath = AdminRoutesPath
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
  ) { that = this; }

  user_id: any;
  user: any;
  photographer_dtls: any;
  storageURL = GlobalConstants.storageURL;
  photeamData: any;
  quotationData: any;
  kyc_data: any;
  fetchPhotographerDetailsById() {
    this.dataService.getPhotographerById("fetch photographer by id", this.user_id, this.successCB, this.errorCB);
  }

  fetchUserDetailsById() {
    this.dataService.getUserById("fetch user by id", this.user_id, this.successCB, this.errorCB);
  }

  fetchUserPhoteamData() {
    this.adminDataService.getUserPhoteamData("fetch user photeam data", this.user_id, this.successCB, this.errorCB);
  }

  fetchUserQuotationData() {
    this.adminDataService.getUserQuotationData("fetch user quotation data", this.user_id, this.successCB, this.errorCB);
  }

  fetchUserKYCData() {
    this.adminDataService.getUserKYCData("fetch user kyc data", this.user_id, this.successCB, this.errorCB);
  }

  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.user_id = this.route.snapshot.params.user_id;

    if (this.user_id != null && this.user_id != undefined) {
      this.fetchUserDetailsById()
      this.fetchUserPhoteamData()
      this.fetchUserQuotationData()
      this.fetchUserKYCData()
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order: []
    };

  }

  getToken() {
    this.adminDataService.getUserAccesToken("get user access token", this.user_id, this.successCB, this.errorCB);
  }

  user_acces_token: any;

  successCB(results: any) {

    if (results.api == "fetch user by id") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.user = results.data;
          console.log("user_data", results.data);

        }

        if (that.user != null && that.user != undefined && that.user.is_photographer == 1) {
          that.fetchPhotographerDetailsById()
        }

      }
    }

    else if (results.api == "fetch photographer by id") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.photographer_dtls = results.data;
          console.log("photographer_data", results.data);
        }

      }
    }

    else if (results.api == "fetch user photeam data") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.photeamData = results.data;
          console.log("user_photeam_data", results.data);
        }
      }
    }

    else if (results.api == "fetch user quotation data") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.quotationData = results.data;
          console.log("user_quotation_data", results.data);
        }
      }
    }

    else if (results.api == "fetch user kyc data") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.kyc_data = results.data.kyc_data;
          console.log("user_kyc_data", results.data);
        }
      }
    }

    else if (results.api == "get user access token") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {

          let token = that.user_acces_token = results.data.access_token
          // alert(token)
          console.log("user access token", token);
        }
      }
    }


  }

  errorCB(error: any) {
    console.log(error);
  }

}
