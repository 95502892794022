import { ADMIN_APIURL } from './../../url/adminUrl-constants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIURL } from '../../url/url-constants';

import { GlobalConstants } from '../../constants/global-constants';
@Injectable({
  providedIn: 'root'
})
export class PaymentsDataService {

  constructor(
    private http: HttpClient
  ) { }

  create_offplatform_Order_Txn(apiname: string, user_id: any, photeam_id: any, cart: any, cost_breakup: any, payment_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.offplatform_Order_Txn;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      cart: cart,
      cost_breakup: cost_breakup,
      payment_data: payment_data,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

}
