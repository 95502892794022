export class ServerCode {
  public static SUCCESS: number = 2000;
  public static ERROR: number = 2004;
  public static NO_USER: number = 4000;
  public static USER_EXIST: number = 4001;
  public static INVALID_CRED: number = 4002;
  public static INCOMPLETE_PHOTEAM: number = 4003;
  public static PTEAM_USERNAME_EXIST: number = 4004;
  public static LINK_EXPIRED: number = 4010;
}
