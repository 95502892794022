<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Photeam</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Photeams</li>
    </ol>
  </nav>
  <!-- Content Row -->
  <div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div
          class="card-body"
          *ngIf="countData != null && countData != undefined"
        >
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-primary text-uppercase mb-1"
              >
                Total Photeam
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ countData.all_photeams.all }}
              </div>
            </div>
          </div>
          <hr />
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
                30 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.all_photeams.month }}
              </div>
            </div>
            <div class="col-auto">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
                7 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.all_photeams.week }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div
          class="card-body"
          *ngIf="countData != null && countData != undefined"
        >
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-success text-uppercase mb-1"
              >
                Completed
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ countData.completed.all }}
              </div>
            </div>
          </div>
          <hr />
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-success text-uppercase mb-1"
              >
                30 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.completed.month }}
              </div>
            </div>
            <div class="col-auto">
              <div
                class="text-xs font-weight-bold text-success text-uppercase mb-1"
              >
                7 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.completed.week }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-info shadow h-100 py-2">
        <div
          class="card-body"
          *ngIf="countData != null && countData != undefined"
        >
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-info text-uppercase mb-1"
              >
                Draft
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ countData.draft.all }}
              </div>
            </div>
          </div>
          <hr />
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-info text-uppercase mb-1"
              >
                30 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.draft.month }}
              </div>
            </div>
            <div class="col-auto">
              <div
                class="text-xs font-weight-bold text-info text-uppercase mb-1"
              >
                7 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.draft.week }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-danger shadow h-100 py-2">
        <div
          class="card-body"
          *ngIf="countData != null && countData != undefined"
        >
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-danger text-uppercase mb-1"
              >
                Deleted
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ countData.deleted.all }}
              </div>
            </div>
          </div>
          <hr />
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-danger text-uppercase mb-1"
              >
                Monthly
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.deleted.month }}
              </div>
            </div>
            <div class="col-auto">
              <div
                class="text-xs font-weight-bold text-danger text-uppercase mb-1"
              >
                Weekly
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.deleted.week }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Row -->
  <div class="row">
    <!-- Area Chart -->
    <div class="col-xl-8 col-lg-7">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div
          class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
        >
          <h6 class="m-0 font-weight-bold text-primary">
            Monthy Photeams Trend
          </h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-area">
            <canvas
              baseChart
              [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              [plugins]="lineChartPlugins"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>
    <!-- Pie Chart -->
    <div class="col-xl-4 col-lg-5">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div
          class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
        >
          <h6 class="m-0 font-weight-bold text-primary">Photeams</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-pie pt-4 pb-2">
            <canvas
              baseChart
              [data]="doughnutChartData"
              [labels]="doughnutChartLabels"
              [options]="doughnutChartOptions"
              [colors]="doughnutChartColors"
              [chartType]="doughnutChartType"
            >
            </canvas>
          </div>
          <div class="mt-4 text-center small">
            <span class="mr-2">
              <i class="fas fa-circle text-danger"></i> Deleted
            </span>
            <span class="mr-2">
              <i class="fas fa-circle text-info"></i> Draft
            </span>
            <span class="mr-2">
              <i class="fas fa-circle text-success"></i> Complted
            </span>
            <span class="mr-2">
              <i class="fas fa-circle text-primary"></i> Total
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">User List</h6>
    </div>
    <div class="card-body">
      <div
        class="table-responsive"
        *ngIf="photeamList?.length > 0"
        style="height: 800px"
      >
        <table
          datatable
          [dtOptions]="dtOptions"
          id="myTable"
          class="table"
          id="dataTable"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Created By</th>
              <th>Name</th>
              <th>is_prem</th>
              <th>Completed</th>
              <th>Verified</th>
              <th>Public</th>
              <th>Deleted</th>
              <th>Created_at</th>
              <th>Deleted at</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let photeam of photeamList"
              [ngClass]="{
                deleted: photeam.is_deleted == 1,
                draft: photeam.is_completed == 0 && photeam.is_deleted == 0
              }"
            >
              <td
                class="cursor-pointer text-warning"
                routerLink="/{{routesPath.PHOTEAM_OVERVIEW}}/{{ photeam.photeam_id }}"
              >
                {{ photeam.photeam_id }}
              </td>
              <td *ngIf="photeam.is_completed == 1">
                <a
                  target="_blank"
                  href="https://pickleit.in/studio/{{
                    photeam.photeam_username
                  }}"
                  >{{ photeam.photeam_username }}</a
                >
              </td>
              <td *ngIf="photeam.is_completed == 0">
                {{ photeam.photeam_username }}
              </td>
              <td
                class="cursor-pointer text-warning"
                routerLink="/{{routesPath.USER_DETAILS}}/{{ photeam.created_by }}"
              >
                {{ photeam.created_by }}
              </td>
              <td>{{ photeam.photeam_name }}</td>

              <td class="text-center">
                <span *ngIf="photeam.is_prem == 1">
                  PREM
                </span>
                <button
                  *ngIf="photeam.is_prem == 0"
                  (click)="upgradeMembership(photeam.created_by,photeam.photeam_id)"
                  class="btn btn-primary"
                >
                  Upgrade
                </button>
              </td>
              <td>
                {{ photeam.is_completed == 1 ? "completed" : "Incomplete" }}
                <mat-slide-toggle
                  [(ngModel)]="photeam.is_completed"
                  (change)="
                    toggle_state(
                      photeam.photeam_id,
                      photeam.is_completed,
                      'is_completed',
                      $event
                    )
                  "
                >
                </mat-slide-toggle>
              </td>
              <td>
                {{ photeam.is_verified == 1 ? "verified" : "Unverified" }}
                <mat-slide-toggle
                  [(ngModel)]="photeam.is_verified"
                  (change)="
                    toggle_state(
                      photeam.photeam_id,
                      photeam.is_verified,
                      'is_verified',
                      $event
                    )
                  "
                >
                </mat-slide-toggle>
              </td>
              <td>
                {{ photeam.is_public == 1 ? "Live" : "Hidden" }}
                <mat-slide-toggle
                  [(ngModel)]="photeam.is_public"
                  (change)="
                    toggle_state(
                      photeam.photeam_id,
                      photeam.is_public,
                      'is_public',
                      $event
                    )
                  "
                >
                </mat-slide-toggle>
              </td>
              <td>
                {{ photeam.is_deleted == 1 ? "Deleted" : "Active" }}
                <mat-slide-toggle
                  [(ngModel)]="photeam.is_deleted"
                  (change)="
                    toggle_state(
                      photeam.photeam_id,
                      photeam.is_deleted,
                      'is_deleted',
                      $event
                    )
                  "
                >
                </mat-slide-toggle>
              </td>
              <td>
                <span class="d-none">{{
                  photeam.created_at | date: "yMMdd"
                }}</span>
                {{ photeam.created_at | date: "MMMM d, y, h:mm:ss a" }}
              </td>
              <td>{{ photeam.deleted_at | date: "MMMM d, y, h:mm:ss a" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
