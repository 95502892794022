import { Component, OnInit } from '@angular/core';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

var that:any;

@Component({
  selector: 'app-master-language-lists',
  templateUrl: './master-language-lists.component.html',
  styleUrls: ['./master-language-lists.component.css']
})
export class MasterLanguageListsComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private modalService: NgbModal
  ) {that = this; }

  languageList:any;
  languageData: any;
  formType= '';

  open(content: any) {
    this.modalService.open(content);
  }

  fetchLanguageList() {
    this.dataService.getLanguagesList("fetch language list", this.successCB, this.errorCB);
  }

  editAction(item: any) {
    this.formType = "edit"
    this.languageData = item;
    // console.log(this.languageData);
  }

  addAction() {
    this.formType = "add"
    this.languageData = {
      language_id: "",
      language_name: "",
      sort_order: -1
    };
  }

  updateLanguage() {

    if (this.languageData.language_id != "" && this.languageData.language_name != "" && this.languageData.sort_orer != "") {
      // console.log("new_values", this.languageData);
      Swal.fire({
        title: 'Are you sure?',
        // text: 'You will change the state!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {

          this.adminDataService.addMasterLanguageData("update master language data", this.languageData, this.successCB, this.errorCB);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )

          // console.log(e.source.checked );
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Some Feilds are emplty!',
        showConfirmButton: false,
        timer: 1500
      })
    }



  }

  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.fetchLanguageList();
    console.log(this.languageData);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      order: []
    };
  }

  successCB(results:any) {

    if (results.api == "fetch language list") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.languageList = results.data;
          console.log("language",results.data);

        }
      }
    }

    if (results.api == "update master language data") {
      if (results.servercode == ServerCode.SUCCESS) {

        Swal.fire({
          icon: 'success',
          title: 'Changes saved!',
          showConfirmButton: false,
          timer: 1500
        })

        that.fetchLanguageList();
      }
    }



  }

  errorCB(error:any) {
    console.log(error);
  }

}
