import { ActivatedRoute } from '@angular/router';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { Component, OnInit } from '@angular/core';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
var that: any;

@Component({
  selector: 'app-quotaion-dashboard',
  templateUrl: './quotaion-dashboard.component.html',
  styleUrls: ['./quotaion-dashboard.component.css']
})
export class QuotaionDashboardComponent implements OnInit {
  routesPath = AdminRoutesPath
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
  ) { that = this; }

  dtOptions: DataTables.Settings = {};
  ngOnInit(): void {

    this.fetchAllQuotationData()

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: []
    };
  }

  quotation_list: any;

  fetchAllQuotationData() {
    this.adminDataService.getAllQuotationData("fetch all quotation data", this.successCB, this.errorCB);
  }

  successCB(results: any) {
    if (results.api == "fetch all quotation data") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.quotation_list = results.data;
          console.log("quotation_list_data", results.data);
        }
      }
    }
  }

  errorCB(error: any) {
    console.log(error);
  }
}
