import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
var that;

@Component({
  selector: 'app-photeam-purchases',
  templateUrl: './photeam-purchases.component.html',
  styleUrls: ['./photeam-purchases.component.css']
})
export class PhoteamPurchasesComponent implements OnInit {

  @ViewChild('json_modal', { static: true }) json_modal: ElementRef;
  modalRef: any;
  view_json: any;

  routesPath = AdminRoutesPath;
  constructor(
    // private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    // private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    that = this;
    this.photeam_id = this.route.snapshot.params.photeam_id;
  }

  photeam_id: any;
  cashfree_txn_list = [];
  OffPlatform_txn_list = [];
  dtOptions: DataTables.Settings = {};

  confirmed_order_list = []
  products_list = []

  ngOnInit(): void {
    if (this.photeam_id != null && this.photeam_id != undefined) {
      this.fetchCashfreeTxnbyPhoteamId();
      this.fetchOffPlatformTxnbyPhoteamId();
      this.fetchConfirmedOrderTxnbyPhoteamId();
      this.fetchProductsbyPhoteamId();
    } else {
      this.router.navigateByUrl('/404');
    }
  }


  fetchCashfreeTxnbyPhoteamId() {
    this.adminDataService.getPackagesOrdersCashfreeTxnbyPhoteam("fetch cashfree txn by photeam_id", this.photeam_id, this.successCB, this.errorCB);
  }


  fetchOffPlatformTxnbyPhoteamId() {
    this.adminDataService.getPackagesOrdersOffPlatformTxnbyPhoteam("fetch OffPlatform txn by photeam_id", this.photeam_id, this.successCB, this.errorCB);
  }

  fetchConfirmedOrderTxnbyPhoteamId() {
    this.adminDataService.getConfirmedOrderTxnbyPhoteam("fetch confirmed order list by photeam_id", this.photeam_id, this.successCB, this.errorCB);
  }

  fetchProductsbyPhoteamId() {
    this.adminDataService.getAllProductsbyPhoteam("fetch products list by photeam_id", this.photeam_id, this.successCB, this.errorCB);
  }

  viewJSON(data) {
    this.view_json = data;
    this.openModal()
  }

  openModal() {
    this.modalRef = this.modalService.open(this.json_modal, {
      centered: true, size: 'lg'

    });
  }

  onModalClose() {
    this.modalRef.close();
  }

  successCB(results: any) {

    if (results.api == "fetch cashfree txn by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('cashfree txn', results.data)
        that.cashfree_txn_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

    if (results.api == "fetch OffPlatform txn by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('OffPlatform txn', results.data)
        that.OffPlatform_txn_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

    if (results.api == "fetch confirmed order list by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('confirmed_order_list', results.data)
        that.confirmed_order_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

    if (results.api == "fetch products list by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('products_list', results.data)
        that.products_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

  }

  errorCB(error: any) {
    console.log(error);
  }

}
