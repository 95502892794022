<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Products Data</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Products List</li>
    </ol>
  </nav>

  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3 d-flex justify-content-between">
      <h6 class="m-0 font-weight-bold text-primary">Products List</h6>

      <span
        class="btn btn-sm btn-info"
        (click)="openModal(productModal); addProduct()"
      >
        <i class="fas fa-plus fa-sm text-white-50"></i> Add New
      </span>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="productsList?.length > 0">
        <table
          datatable
          [dtOptions]="dtOptions"
          id="myTable"
          class="table"
          id="dataTable"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>product_id</th>
              <th>product_code</th>
              <th>name</th>
              <th>desc</th>
              <th>max_uses_limit</th>
              <th>max_uses_per_user</th>
              <th>category</th>
              <th>type</th>
              <th>base_value</th>
              <th>discount</th>
              <th>gst</th>
              <th>final_value</th>
              <th>validity</th>
              <th>pricing_options</th>
              <th>data</th>
              <th>Actions</th>
              <th>category</th>
              <th>is_active</th>
              <th>sort_order</th>
              <th>is_display_only</th>
              <th>deleted_at</th>
              <th>created_at</th>
              <th>updated_at</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let product of productsList">
              <td>{{ product.product_id }}</td>
              <td>{{ product.product_code }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.desc }}</td>
              <td>{{ product.max_uses_limit }}</td>
              <td>{{ product.max_uses_per_user }}</td>
              <td>{{ product.category }}</td>
              <td>{{ product.type }}</td>
              <td>{{ product.base_value }}</td>
              <td>
                <pre>{{ product.discount | json }}</pre>
              </td>
              <td>
                <pre>{{ product.gst | json }}</pre>
              </td>
              <td>{{ product.final_value }}</td>
              <td>
                <pre>{{ product.validity | json }}</pre>
              </td>
              <td>
                <span
                  class="btn btn-sm btn-info"
                  (click)="
                    openModal(pricingOptionModal); addPricingOption(product)
                  "
                >
                  <i class="fas fa-plus fa-sm text-white-50"></i> Add Option
                </span>
                <table style="width: 100%">
                  <tbody *ngFor="let option of product.pricing_options">
                    <tr>
                      <th>Action</th>
                      <td>
                        <span
                          title="Edit"
                          (click)="
                            openModal(pricingOptionModal);
                            editPricingOption(product, option.option_id)
                          "
                          class="text-light btn-warning btn-circle"
                        >
                          <i class="fas fa-edit"></i>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>option_id</th>
                      <td>{{ option.option_id }}</td>
                    </tr>
                    <tr>
                      <th>option_name</th>
                      <td>{{ option.option_name }}</td>
                    </tr>
                    <tr>
                      <th>base_price</th>
                      <td>{{ option.base_price }}</td>
                    </tr>
                    <tr>
                      <th>per_month_price</th>
                      <td>{{ option.per_month_price }}</td>
                    </tr>
                    <tr>
                      <th>discount_value</th>
                      <td>{{ option.discount_value }}</td>
                    </tr>
                    <tr>
                      <th>discount_percentage</th>
                      <td>{{ option.discount_percentage }}%</td>
                    </tr>
                    <tr>
                      <th>gst_value</th>
                      <td>{{ option.gst_value }}</td>
                    </tr>
                    <tr>
                      <th>gst_percetage</th>
                      <td>{{ option.gst_percetage }}%</td>
                    </tr>
                    <tr>
                      <th>validity_type</th>
                      <td>{{ option.validity_type }}</td>
                    </tr>
                    <tr>
                      <th>validity_value</th>
                      <td>{{ option.validity_value }}</td>
                    </tr>
                    <tr>
                      <th>total_price</th>
                      <td>{{ option.total_price }}</td>
                    </tr>
                    <!-- Divider between options -->
                    <tr>
                      <td colspan="2"><hr /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <pre>{{ product.data | json }}</pre>
              </td>
              <td>
                <span
                  title="Edit"
                  (click)="openModal(productModal); editAction(product)"
                  class="text-light btn-warning btn-circle"
                >
                  <i class="fas fa-edit"></i>
                </span>
              </td>
              <td>{{ product.category }}</td>
              <td>{{ product.is_active }}</td>
              <td>{{ product.sort_order }}</td>
              <td>{{ product.is_display_only }}</td>
              <td>{{ product.deleted_at }}</td>
              <td>{{ product.created_at }}</td>
              <td>{{ product.updated_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <pre>
        {{ productData | json }}
      </pre> -->
    </div>
  </div>

  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3 d-flex justify-content-between">
      <h6 class="m-0 font-weight-bold text-primary">
        Products List Checkout View
      </h6>

      <span
        class="btn btn-sm btn-info"
        (click)="openModal(productModal); addProduct()"
      >
        <i class="fas fa-plus fa-sm text-white-50"></i> Add New
      </span>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="productsList?.length > 0">
        <table
          datatable
          [dtOptions]="dtOptions"
          id="myTable"
          class="table"
          id="dataTable"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>product_id</th>
              <th>product_code</th>
              <th>type</th>
              <th>product_name</th>
              <th>product_description</th>
              <th>product_features</th>
              <th>category</th>
              <th>base_price</th>
              <th>
                pricing_options
                <span
                  class="btn btn-sm btn-info"
                  (click)="openModal(productModal); addProduct()"
                >
                  <i class="fas fa-plus fa-sm text-white-50"></i> Add Option
                </span>
              </th>
              <th>is_applicable</th>
              <th>sort_order</th>
              <th>is_active</th>
              <th>created_at</th>
              <th>updated_at</th>
              <th>deleted_at</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let product of productsList_checkout">
              <td>{{ product.product_id }}</td>
              <td>{{ product.product_code }}</td>
              <td>{{ product.type }}</td>
              <td>{{ product.product_name }}</td>
              <td>{{ product.product_description }}</td>
              <td>{{ product.product_features }}</td>
              <td>{{ product.category }}</td>
              <td>{{ product.base_price }}</td>
              <td>
                <table style="width: 100%">
                  <tbody *ngFor="let option of product.pricing_options">
                    <tr>
                      <th>option_id</th>
                      <td>{{ option.option_id }}</td>
                    </tr>
                    <tr>
                      <th>option_name</th>
                      <td>{{ option.option_name }}</td>
                    </tr>
                    <tr>
                      <th>validity_type</th>
                      <td>{{ option.validity_type }}</td>
                    </tr>
                    <tr>
                      <th>validity_value</th>
                      <td>{{ option.validity_value }}</td>
                    </tr>
                    <tr>
                      <th>start_date</th>
                      <td>{{ option.start_date }}</td>
                    </tr>
                    <tr>
                      <th>end_date</th>
                      <td>{{ option.end_date }}</td>
                    </tr>
                    <tr>
                      <th>base_price</th>
                      <td>{{ option.base_price }}</td>
                    </tr>
                    <tr>
                      <th>per_month_price</th>
                      <td>{{ option.per_month_price }}</td>
                    </tr>
                    <tr>
                      <th>discount_amount</th>
                      <td>{{ option.discount_amount }}</td>
                    </tr>
                    <tr>
                      <th>discount_percentage</th>
                      <td>{{ option.discount_percentage }}%</td>
                    </tr>
                    <tr>
                      <th>gst_amount</th>
                      <td>{{ option.gst_amount }}</td>
                    </tr>
                    <tr>
                      <th>gst_percetage</th>
                      <td>{{ option.gst_percetage }}%</td>
                    </tr>
                    <tr>
                      <th>total_price</th>
                      <td>{{ option.total_price }}</td>
                    </tr>
                    <!-- Divider between options -->
                    <tr>
                      <td colspan="2"><hr /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>{{ product.is_applicable }}</td>
              <td>{{ product.sort_order }}</td>
              <td>{{ product.is_active }}</td>
              <td>{{ product.created_at }}</td>
              <td>{{ product.updated_at }}</td>
              <td>{{ product.deleted_at }}</td>
              <td>
                <span
                  title="Edit"
                  (click)="openModal(productModal); editAction(product)"
                  class="text-light btn-warning btn-circle"
                >
                  <i class="fas fa-edit"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <pre>
        {{ productData | json }}
      </pre> -->
    </div>
  </div>

  <ng-template #productModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType == 'edit'">
        Update Product
      </h4>
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType == 'add'">
        Create Product
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body admin-form">
      <form *ngIf="productsData != undefined">
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Product ID</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.product_id == '' }"
            name="product_id"
            disabled="true"
            [(ngModel)]="productsData.product_id"
          />
          <div *ngIf="productsData.product_id == ''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Product Code</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.product_code == '' }"
            name="product_code"
            [(ngModel)]="productsData.product_code"
          />
          <div *ngIf="productsData.product_code == ''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Product Name</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.name == '' }"
            name="product_name"
            [(ngModel)]="productsData.name"
          />
          <div *ngIf="productsData.name == ''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Description</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.desc == '' }"
            name="product_desc"
            [(ngModel)]="productsData.desc"
          />
          <div *ngIf="productsData.desc == ''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800"
            >Max Uses Limit</label
          >
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.max_uses_limit < 0 }"
            name="product_max_uses_limit"
            type="number"
            [(ngModel)]="productsData.max_uses_limit"
          />
          <div *ngIf="productsData.max_uses_limit < 0" class="invalid-feedback">
            Positive Number
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800"
            >Max Uses Per User</label
          >
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.max_uses_per_user < 0 }"
            name="product_max_uses_per_user"
            type="number"
            [(ngModel)]="productsData.max_uses_per_user"
          />
          <div
            *ngIf="productsData.max_uses_per_user < 0"
            class="invalid-feedback"
          >
            Positive Number
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Category</label>
          <select
            (change)="changeCategory($event)"
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.category == '' }"
            name="category"
            [(ngModel)]="productsData.category"
          >
            <option disabled>Choose a Category</option>
            <option
              *ngFor="let item of product_categories"
              value="{{ item.category }}"
            >
              {{ item.category }}
            </option>
          </select>
          <div *ngIf="productsData.category == ''" class="invalid-feedback">
            Select an option
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Type</label>
          <select
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.type == '' }"
            name="type"
            [(ngModel)]="productsData.type"
          >
            <option disabled>Choose a Type</option>
            <option *ngFor="let item of product_type" [value]="item">
              {{ item }}
            </option>
          </select>
          <div *ngIf="productsData.type == ''" class="invalid-feedback">
            Select Type
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Base Price</label>
          <input
            type="number"
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.base_value < 0 }"
            name="base_value"
            [(ngModel)]="productsData.base_value"
          />
          <div *ngIf="productsData.base_value < 0" class="invalid-feedback">
            Positive number
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div class="form-group" *ngIf="productsData.discount">
            <label class="h6 font-weight-bold text-gray-800"
              >Discount Value</label
            >
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': productsData.discount.value < 0 }"
              name="discount_value"
              [(ngModel)]="productsData.discount.value"
            />
          </div>
          <div class="form-group" *ngIf="productsData.discount">
            <div>
              <input
                type="radio"
                name="discount_type"
                [value]="'percentage'"
                [(ngModel)]="productsData.discount.discount_type"
              /><label for="">Percentage</label>
            </div>
            <div>
              <input
                type="radio"
                name="discount_type"
                [value]="'amount'"
                [(ngModel)]="productsData.discount.discount_type"
              /><label for="">Amount</label>
            </div>
          </div>

          <div class="form-group text-center">
            <label class="h6 font-weight-bold text-gray-800">Discount</label>
            <input
              type="checkbox"
              class="form-control"
              name="discount_null"
              [checked]="productsData.discount != null"
              (change)="toogle_discount_applicable($event)"
            />
            <span>{{
              productsData.discount != null ? "Exist" : "Not Exist"
            }}</span>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div class="form-group" *ngIf="productsData.gst">
            <label class="h6 font-weight-bold text-gray-800">GST</label>
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': productsData.gst.value < 0 }"
              name="gst_value"
              [(ngModel)]="productsData.gst.value"
            />
          </div>

          <div class="form-group" *ngIf="productsData.gst">
            <div>
              <input
                type="radio"
                name="gst_type"
                [value]="'percentage'"
                [(ngModel)]="productsData.gst.gst_type"
              /><label for="">Percentage</label>
            </div>
            <div>
              <input
                type="radio"
                name="gst_type"
                [value]="'amount'"
                [(ngModel)]="productsData.gst.gst_type"
              /><label for="">Amount</label>
            </div>
          </div>

          <div class="form-group text-center">
            <label class="h6 font-weight-bold text-gray-800">GST</label>
            <input
              type="checkbox"
              class="form-control"
              name="gst_null"
              [checked]="productsData.gst != null"
              (change)="toogle_gst_applicable($event)"
            />
            <span>{{ productsData.gst != null ? "Exist" : "Not Exist" }}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Final Price</label>
          <input
            type="number"
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.final_value < 0 }"
            name="final_value"
            [(ngModel)]="productsData.final_value"
          />
          <div *ngIf="productsData.final_value < 0" class="invalid-feedback">
            Positive number
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group" *ngIf="productsData.validity">
            <label class="h6 font-weight-bold text-gray-800">Duration </label>
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': productsData.validity.validity < 0 }"
              name="validity_days"
              [(ngModel)]="productsData.validity.validity"
            />
            <div
              *ngIf="productsData.validity.validity < 0"
              class="invalid-feedback"
            >
              Positive number
            </div>
          </div>

          <div class="form-group" *ngIf="productsData.validity">
            <label class="h6 font-weight-bold text-gray-800">Type</label>
            <select
              class="form-control"
              [ngClass]="{
                'is-invalid': productsData.validity.validity_type == ''
              }"
              name="validity_type"
              [(ngModel)]="productsData.validity.validity_type"
            >
              <option value="">Choose a Type</option>
              <option
                *ngFor="let item of product_validity_type"
                value="{{ item }}"
              >
                {{ item }}
              </option>
            </select>
            <div
              *ngIf="productsData.validity.validity_type == ''"
              class="invalid-feedback"
            >
              Select an option
            </div>
          </div>

          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Validity</label>
            <input
              type="checkbox"
              class="form-control"
              name="validity_null"
              [checked]="productsData.validity != null"
              (change)="toogle_validity_applicable($event)"
            />
            <span>{{
              productsData.validity != null ? "Limited" : "No Limit"
            }}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Is Active</label
          ><br />
          <input
            type="radio"
            name="product_is_active"
            [value]="1"
            [(ngModel)]="productsData.is_active"
          /><label for="">True</label>
          <input
            type="radio"
            name="product_is_active"
            [value]="0"
            [(ngModel)]="productsData.is_active"
            style="margin-left: 20%"
          /><label for="">False</label>
        </div>

        <pre>{{ productsData | json }}</pre>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="updateProduct()"
      >
        {{ formType == "edit" ? "update" : "create" }}
      </button>
    </div>
  </ng-template>

  <ng-template #pricingOptionModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType == 'edit'">
        Update Pricing Option
      </h4>
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType == 'add'">
        Create Pricing Option
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body admin-form">
      <form *ngIf="pricingOptionData != undefined">
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Option ID</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': pricingOptionData.option_id == '' }"
            name="po_option_id"
            disabled="true"
            [(ngModel)]="pricingOptionData.option_id"
          />
          <div
            *ngIf="pricingOptionData.option_id == ''"
            class="invalid-feedback"
          >
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Option Name</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': pricingOptionData.option_name == '' }"
            name="po_option_name"
            [(ngModel)]="pricingOptionData.option_name"
          />
          <div
            *ngIf="pricingOptionData.option_name == ''"
            class="invalid-feedback"
          >
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Base Price</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': pricingOptionData.base_price < 0 }"
            name="po_base_price"
            type="number"
            [(ngModel)]="pricingOptionData.base_price"
          />
          <div
            *ngIf="pricingOptionData.base_price < 0"
            class="invalid-feedback"
          >
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800"
            >Per Month Price</label
          >
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': pricingOptionData.per_month_price < 0 }"
            name="po_per_month_price"
            type="number"
            [(ngModel)]="pricingOptionData.per_month_price"
          />
          <div
            *ngIf="pricingOptionData.per_month_price < 0"
            class="invalid-feedback"
          >
            Required
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800"
              >discount_value</label
            >
            <input
              class="form-control"
              [ngClass]="{
                'is-invalid': pricingOptionData.discount_value < 0
              }"
              name="po_discount_value"
              type="number"
              [(ngModel)]="pricingOptionData.discount_value"
            />
            <div
              *ngIf="pricingOptionData.discount_value < 0"
              class="invalid-feedback"
            >
              Required
            </div>
          </div>
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800"
              >discount_percentage</label
            >
            <input
              class="form-control"
              [ngClass]="{
                'is-invalid': pricingOptionData.discount_percentage < 0
              }"
              name="po_discount_percentage"
              type="number"
              [(ngModel)]="pricingOptionData.discount_percentage"
            />
            <div
              *ngIf="pricingOptionData.discount_percentage < 0"
              class="invalid-feedback"
            >
              Required
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">gst_value</label>
            <input
              class="form-control"
              [ngClass]="{
                'is-invalid': pricingOptionData.gst_value < 0
              }"
              name="po_gst_value"
              type="number"
              [(ngModel)]="pricingOptionData.gst_value"
            />
            <div
              *ngIf="pricingOptionData.gst_value < 0"
              class="invalid-feedback"
            >
              Required
            </div>
          </div>
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800"
              >gst_percetage</label
            >
            <input
              class="form-control"
              [ngClass]="{
                'is-invalid': pricingOptionData.gst_percetage < 0
              }"
              name="po_gst_percetage"
              type="number"
              [(ngModel)]="pricingOptionData.gst_percetage"
            />
            <div
              *ngIf="pricingOptionData.gst_percetage < 0"
              class="invalid-feedback"
            >
              Required
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800"
              >validity_value
            </label>
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': pricingOptionData.validity_value < 0 }"
              name="po_validity_value"
              [(ngModel)]="pricingOptionData.validity_value"
            />
            <div
              *ngIf="pricingOptionData.validity_value < 0"
              class="invalid-feedback"
            >
              Positive number
            </div>
          </div>

          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800"
              >validity_type</label
            >
            <select
              class="form-control"
              [ngClass]="{
                'is-invalid': pricingOptionData.validity_type == ''
              }"
              name="po_validity_type"
              [(ngModel)]="pricingOptionData.validity_type"
            >
              <option disabled>Choose a Type</option>
              <option
                *ngFor="let item of product_validity_type"
                value="{{ item }}"
              >
                {{ item }}
              </option>
            </select>
            <div
              *ngIf="pricingOptionData.validity_type == ''"
              class="invalid-feedback"
            >
              Select an option
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">total_price</label>
          <input
            type="number"
            class="form-control"
            [ngClass]="{ 'is-invalid': pricingOptionData.total_price < 0 }"
            name="po_total_price"
            [(ngModel)]="pricingOptionData.total_price"
          />
          <div
            *ngIf="pricingOptionData.total_price < 0"
            class="invalid-feedback"
          >
            Positive number
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Is Active</label
          ><br />
          <input
            type="radio"
            name="po_is_active"
            [value]="1"
            [(ngModel)]="pricingOptionData.is_active"
          /><label for="">True</label>
          <input
            type="radio"
            name="po_is_active"
            [value]="0"
            [(ngModel)]="pricingOptionData.is_active"
            style="margin-left: 20%"
          /><label for="">False</label>
        </div>

        <div class="d-flex">
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">sort_order </label>
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': pricingOptionData.sort_order < 0 }"
              name="po_sort_order"
              [(ngModel)]="pricingOptionData.sort_order"
            />
            <div
              *ngIf="pricingOptionData.sort_order < 0"
              class="invalid-feedback"
            >
              Positive number
            </div>
          </div>
        </div>

        <pre>{{ pricingOptionData | json }}</pre>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="updatePricingOption()"
      >
        {{ formType == "edit" ? "update" : "Add Option" }}
      </button>
    </div>
  </ng-template>
</div>
