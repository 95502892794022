<div *ngIf="booking" class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Booking Details - <small>ID</small></h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Booking</li>
    </ol>
  </nav>

  <!-- DataTales Example -->
  <div class="row">
    <div class="col-xl-6 col-lg-4">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Genral Details</h6>
        </div>
        <div class="card-body">
          <div class="bg-3 rounded p-3">
            <div>
              <div class="ptc1 ph6-bold mb-4">Event Details</div>

              <div>
                <div class="text-xs text-center font-weight-bold text-gray-500">
                  booking_id - {{ booking?.booking_id }}
                </div>

                <div class="text-xs text-center font-weight-bold text-gray-500">
                  Photeam_id - {{ booking?.photeam.photeam_id }}
                </div>
                <div class="text-xs text-center font-weight-bold text-gray-500">
                  User_id - {{ booking?.user.id }}
                </div>

                <div class="text-xs text-center font-weight-bold text-gray-600">
                  Created_at -
                  {{ booking?.created_at | date: "MMMM d, y, h:mm:ss a" }}
                </div>

                <div class="text-xs text-center font-weight-bold text-gray-600">
                  Updated_at -
                  {{ booking?.updated_at | date: "MMMM d, y, h:mm:ss a" }}
                </div>

                <div class="text-xs text-center font-weight-bold text-gray-600">
                  Deleted_at -
                  {{ booking?.deleted_at | date: "MMMM d, y, h:mm:ss a" }}
                </div>
              </div>
            </div>
          </div>

          <div class="bg-3 rounded p-3 mt-3">
            <div>
              <div class="ptc1 ph6-bold mb-4">Event Details</div>

              <div>
                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Booking Status
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc12"
                  >
                    {{ booking?.booking_status }}
                  </div>
                </div>

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Event Date & Time
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    {{ booking?.event_details?.eventDate | date: "d MMMM, y" }}
                  </div>
                </div>

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Event Location
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    {{ booking?.event_details?.location.city }},
                    {{ booking?.event_details?.location.state }},
                    {{ booking?.event_details?.location.pin }}
                  </div>
                </div>

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Flexible with date
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    {{
                      booking?.event_details?.is_dateFlexible == true
                        ? "Yes"
                        : "No"
                    }}
                  </div>
                </div>

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Additional note
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    {{ booking?.event_details?.eventDesc }}
                  </div>
                </div>

                <hr />

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Studio Name
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    <a
                      class="ph6-semibold ptc12"
                      target="_blank"
                      routerLink="/{{routesPath.PHOTEAM_DETAILS}}/{{ booking?.photeam?.photeam_id }}"
                    >
                      <span class="">
                        {{ booking?.photeam?.photeam_name }}</span
                      >
                    </a>
                  </div>
                </div>

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Studio's Mobile
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    {{ booking?.photeam?.studio_mobile }}
                  </div>
                </div>

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Pkg Initial Cost
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    &#8377;
                    {{ booking?.original_pkg_price?.toLocaleString("en-IN") }}
                  </div>
                </div>

                <hr />
                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Customer Contact
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    <a
                      class="ph6-semibold ptc12"
                      target="_blank"
                      routerLink="/{{routesPath.PHOTEAMS}}/{{ booking?.user?.id }}"
                    >
                      <span class="">
                        {{ booking?.user.name }} ({{
                          booking?.user.mobile
                        }})</span
                      >
                    </a>
                  </div>
                </div>

                <div class="row mb-3">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                  >
                    Customer's Quote
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3"
                  >
                    &#8377;
                    {{ booking?.price_quoted_by_user?.toLocaleString("en-IN") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-8">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Cost Details</h6>
        </div>
        <div class="card-body p-2">
          <div class="bg-3 rounded p-3">
            <div>
              <div class="ptc1 ph6-bold mb-4">Payment Details</div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  Custom built pkg
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc12"
                >
                  {{ booking?.is_custom_build_pkg }}
                </div>
              </div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  Payment Status
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc12"
                >
                  {{ booking?.payment_status }}
                </div>
              </div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  Customer's Quote
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3 text-nowrap"
                >
                  &#8377;
                  {{ booking?.price_quoted_by_user?.toLocaleString("en-IN") }}
                </div>
              </div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  Pkg Initial Cost
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3 text-nowrap"
                >
                  &#8377;
                  {{ booking?.original_pkg_price?.toLocaleString("en-IN") }}
                </div>
              </div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  Final Agreed Cost
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3 text-nowrap"
                >
                  &#8377;
                  {{ booking?.final_agreed_price?.toLocaleString("en-IN") }}
                  <br />

                  <div class="text-xs font-weight-bold text-gray-600">
                    (&#8377;
                    {{
                      booking?.cost_breakup?.to_studio?.amount?.toLocaleString(
                        "en-IN"
                      )
                    }}
                    + ₹{{
                      (
                        booking?.cost_breakup?.to_platform?.gross?.amount +
                        booking?.cost_breakup?.to_paymentGateway?.amount
                      ).toLocaleString("en-IN")
                    }})
                  </div>
                </div>
              </div>

              <hr />
              <div class="ptc1 ph6-bold mb-4">Cost Break Up</div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  To Studio
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3 text-nowrap"
                >
                  &#8377;
                  {{
                    booking?.cost_breakup?.to_studio?.amount?.toLocaleString(
                      "en-IN"
                    )
                  }}
                </div>
              </div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  To PaymentGateway
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 ph6-bold ptc3 text-nowrap"
                >
                  &#8377;
                  {{
                    booking?.cost_breakup?.to_paymentGateway?.amount?.toLocaleString(
                      "en-IN"
                    )
                  }}
                  ({{ booking?.cost_breakup?.to_paymentGateway?.percentage }}%)
                </div>
              </div>

              <div class="row mb-3">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 ph6-semibold ptc14 text-nowrap"
                >
                  To Platform
                </div>
                <div
                  class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 text-nowrap"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Amount</th>
                        <th>Percentage</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th>Gross</th>
                        <td>
                          {{
                            booking?.cost_breakup?.to_platform?.gross?.amount
                          }}
                        </td>
                        <td>
                          {{
                            booking?.cost_breakup?.to_platform?.gross
                              ?.percentage
                          }}
                        </td>
                      </tr>

                      <tr>
                        <th>Net</th>
                        <td>
                          {{ booking?.cost_breakup?.to_platform?.net?.amount }}
                        </td>
                        <td>
                          {{
                            booking?.cost_breakup?.to_platform?.net?.percentage
                          }}
                        </td>
                      </tr>

                      <tr>
                        <th>GST on Net</th>
                        <td>
                          {{
                            booking?.cost_breakup?.to_platform?.gst_on_net
                              ?.amount
                          }}
                        </td>
                        <td>
                          {{
                            booking?.cost_breakup?.to_platform?.gst_on_net
                              ?.percentage
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-8">
      <div class="card shadow mb-4 mt-4">
        <div class="card-header py-3 d-flex justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Pkg Details</h6>

          <div *ngIf="booking?.is_custom_build_pkg">
            <span
              class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            >
              Custom built Pkg
            </span>
          </div>
        </div>
        <app-booking-pkg-details
          [package]="booking?.final_package_details"
        ></app-booking-pkg-details>
      </div>
    </div>
  </div>
</div>
