<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Users</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Users</li>
    </ol>
  </nav>
  <!-- Content Row -->
  <div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div
          class="card-body"
          *ngIf="countData != null && countData != undefined"
        >
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-primary text-uppercase mb-1"
              >
                Total Users
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ countData.all_users.all }}
              </div>
            </div>
          </div>
          <hr />
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
                30 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.all_users.month }}
              </div>
            </div>
            <div class="col-auto">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
                7 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.all_users.week }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div
          class="card-body"
          *ngIf="countData != null && countData != undefined"
        >
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-success text-uppercase mb-1"
              >
                Photographer
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ countData.photographers.all }}
              </div>
            </div>
          </div>
          <hr />
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-success text-uppercase mb-1"
              >
                30 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.photographers.month }}
              </div>
            </div>
            <div class="col-auto">
              <div
                class="text-xs font-weight-bold text-success text-uppercase mb-1"
              >
                7 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.photographers.week }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-info shadow h-100 py-2">
        <div
          class="card-body"
          *ngIf="countData != null && countData != undefined"
        >
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-info text-uppercase mb-1"
              >
                Normal
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ countData.normal_users.all }}
              </div>
            </div>
          </div>
          <hr />
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-info text-uppercase mb-1"
              >
                30 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.normal_users.month }}
              </div>
            </div>
            <div class="col-auto">
              <div
                class="text-xs font-weight-bold text-info text-uppercase mb-1"
              >
                7 days
              </div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">
                {{ countData.normal_users.week }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-warning shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-lg font-weight-bold text-warning text-uppercase mb-1"
              >
                Without Photeam
              </div>
              <div class="h4 mb-0 font-weight-bold text-gray-800">
                {{ photographers_without_photeam?.length }}
              </div>
            </div>
          </div>
          <hr />
          <!-- <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Monthly</div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">$40,000</div>
            </div>
            <div class="col-auto">
              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Weekly</div>
              <div class="h6 mb-0 font-weight-bold text-gray-500">$40,000</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Content Row -->
  <div class="row">
    <!-- Area Chart -->
    <div class="col-xl-8 col-lg-7">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div
          class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
        >
          <h6 class="m-0 font-weight-bold text-primary">Monthy Users Trend</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-area">
            <canvas
              baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>
    <!-- Pie Chart -->
    <div class="col-xl-4 col-lg-5">
      <ng-container *ngIf="show_pie == false">
        <div class="card shadow mb-4">
          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 font-weight-bold text-primary">
              users Without Photeam
            </h6>
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="toogle_pie(show_pie)"
            >
              change view
            </button>
          </div>

          <div class="card-body">
            <div
              class="table-responsive"
              *ngIf="photographers_without_photeam?.length > 0"
              style="zoom: 0.68"
            >
              <table
                datatable
                [dtOptions]="dtOptions"
                id="myTable"
                class="table"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created_at</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of photographers_without_photeam">
                    <td
                      class="cursor-pointer text-warning"
                      routerLink="/{{ routesPath.USER_DETAILS }}/{{ item.id }}"
                    >
                      {{ item.name }}
                    </td>
                    <td>{{ item.created_at | date: "MMMM d, y" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="show_pie == true">
        <div class="card shadow mb-4">
          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 font-weight-bold text-primary">Users</h6>

            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="toogle_pie(show_pie)"
            >
              change view
            </button>
          </div>

          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <canvas
                baseChart
                [data]="doughnutChartData"
                [labels]="doughnutChartLabels"
                [options]="doughnutChartOptions"
                [colors]="doughnutChartColors"
                [chartType]="doughnutChartType"
              >
              </canvas>
            </div>
            <div class="mt-4 text-center small">
              <span class="mr-2">
                <i class="fas fa-circle text-success"></i> Photographers
              </span>
              <span class="mr-2">
                <i class="fas fa-circle text-primary"></i> Users
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">User List</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="userList?.length > 0">
        <table
          datatable
          [dtOptions]="dtOptions"
          id="myTable"
          class="table"
          id="dataTable"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Referral</th>
              <th>Photographer</th>
              <!-- <th>Membership</th> -->
              <th>Deleted</th>
              <th>Created_at</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let user of userList">
              <td
                class="cursor-pointer text-warning"
                routerLink="/{{routesPath.USER_DETAILS}}/{{ user.id }}"
              >
                {{ user.id }}
              </td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.mobile }}</td>
              <td>{{ user.referral_code }}</td>
              <td>{{ user.is_photographer }}</td>
              <!-- <td class="text-center">
                <span *ngIf="user.membership_type != null">{{
                  user.membership_type
                }}</span>
                <button
                  *ngIf="user.is_photographer == 1"
                  (click)="upgradeMembership(user.id)"
                  class="btn btn-primary"
                >
                  Upgrade
                </button>
              </td> -->
              <td>{{ user.is_deleted }}</td>
              <td>{{ user.created_at | date: "MMMM d, y, h:mm:ss a" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
