import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
var that: any;

@Component({
  selector: 'app-invoice-dashboard',
  templateUrl: './invoice-dashboard.component.html',
  styleUrls: ['./invoice-dashboard.component.css']
})

export class InvoiceDashboardComponent implements OnInit {
  routesPath = AdminRoutesPath
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
  ) { that = this; }

  dtOptions: DataTables.Settings = {};
  ngOnInit(): void {

    this.fetchAllInvoiceData()

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: []
    };
  }

  invoice_list: any;

  fetchAllInvoiceData() {
    this.adminDataService.getAllInvoiceData("fetch all invoice data", this.successCB, this.errorCB);
  }

  successCB(results: any) {
    if (results.api == "fetch all invoice data") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.invoice_list = results.data;
          console.log("invoice_list_data", results.data);
        }
      }
    }
  }

  errorCB(error: any) {
    console.log(error);
  }

}
