<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Master Data</h1>
    <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
        class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Genre List</li>
    </ol>
  </nav>
  <!-- Content Row -->

  <!-- <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Genre List </h6>
    </div>
    <div class="card-body">

    </div>
  </div> -->



  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3 d-flex justify-content-between">
      <h6 class="m-0 font-weight-bold text-primary">Genre List </h6>
      <span class="btn btn-sm btn-info" (click)="open(content); addAction()">
        <i class="fas fa-plus fa-sm text-white-50"></i> Add New
      </span>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="genreList?.length>0">
        <table datatable [dtOptions]="dtOptions" id="myTable" class="table " id="dataTable" width="100%"
          cellspacing="0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>img_src</th>
              <th>mobile_img_src</th>
              <th>sort_order</th>
              <!-- <th>sub_text</th> -->
              <th>color</th>
              <th>Actions</th>
              <!-- <th>is_deleted</th> -->
              <!-- <th>deleted_at</th> -->
              <!-- <th>created_at</th> -->
              <!-- <th>updated_at</th> -->
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let genre of genreList">

              <td>{{genre.genre_id}}</td>
              <td>{{genre.genre_name}}</td>
              <td>{{genre.img_src}} <br>
                <img height="100px" width="100px" src="{{genre.img_src}}" alt="" srcset="">
              </td>
              <td>{{genre.mobile_img_src}} <br>
                <img height="100px" width="100px" src="{{genre.mobile_img_src}}" alt="" srcset="">
              </td>
              <td>{{genre.sort_order}}</td>
              <!-- <td>{{genre.sub_text}}</td> -->
              <td>

                <div style="background-color: {{genre.color}}" class="card text-white shadow">
                  <div class="card-body">
                    <div class="text-gray-900 small">{{genre.color}}</div>
                  </div>
                </div>
              </td>
              <td>
                <span title="Edit" (click)="open(content); editAction(genre)" class="text-light btn-warning btn-circle">
                  <i class="fas fa-edit"></i>
                </span>
              </td>

              <!-- <td>{{genre.is_deleted}}</td> -->
              <!-- <td>{{genre.deleted_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
              <!-- <td>{{genre.created_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
              <!-- <td>{{genre.updated_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType=='edit'">Update Genre</h4>
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType=='add'">Create Genre</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body admin-form">
      <form *ngIf="genreData!=undefined">
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Genre ID</label>
          <input class="form-control" [ngClass]="{'is-invalid': genreData.genre_id=='' }" name="id"
            [(ngModel)]='genreData.genre_id'>
          <div *ngIf="genreData.genre_id==''" class="invalid-feedback">
            Required
          </div>
        </div>
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Genre Name</label>
          <input class="form-control " [ngClass]="{'is-invalid': genreData.genre_name=='' }" name="name"
            [(ngModel)]='genreData.genre_name'>
          <div *ngIf="genreData.genre_name==''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Imgae Src</label>
          <input class="form-control " [ngClass]="{'is-invalid': genreData.img_src=='' }" name="img_src"
            [(ngModel)]='genreData.img_src'>
          <div *ngIf="genreData.img_src==''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Mobile Img Src</label>
          <input class="form-control " [ngClass]="{'is-invalid': genreData.mobile_img_src=='' }" name="mobile_img_src"
            [(ngModel)]='genreData.mobile_img_src'>
          <div *ngIf="genreData.mobile_img_src==''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Sort Order</label>
          <input type="number" class="form-control " [ngClass]="{'is-invalid': genreData.sort_order<0 }"
            name="sort_order" [(ngModel)]='genreData.sort_order'>
          <div *ngIf="genreData.sort_order<0" class="invalid-feedback">
            Positive integer
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Color</label>
          <input class="form-control " type="color" [ngClass]="{'is-invalid': genreData.color=='' }" name="color"
            [(ngModel)]='genreData.color'>
          <div *ngIf="genreData.color==''" class="invalid-feedback">
            Required
          </div>
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark"
        (click)="modal.close('Save click');updateGenre() ">{{formType=='edit'?'update':"create"}}</button>
    </div>
  </ng-template>

</div>
