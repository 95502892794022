<div class="py-5">
    <app-invoice-pdf-preview
      [invoice_data]="invoice_data"
    ></app-invoice-pdf-preview>
    
  </div>
  
  <app-invoice-pdf-download [invoice_data]="invoice_data">
  </app-invoice-pdf-download>
  
  <div class="d-flex justify-content-center mb-4">
    <button (click)="onDownload()" type="button" class="btn btn-outline-primary">
      Download PDF
    </button>
  </div>
  