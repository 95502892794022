<div class="photeam-select-dropdown" [ngClass]="{ 'pt-3': isMobile }">
  <div
    class="dropdown-option"
    [ngClass]="{ is_open: is_dropdown_open }"
    (click)="tooglePhoteamSelectionDropDown()"
  >
    <img
      [src]="
        selected_photeam.photeam_data.photeam_dp != null
          ? storageURL +
            '/photeam/' +
            selected_photeam.photeam_data.photeam_id +
            '/display/' +
            selected_photeam.photeam_data.photeam_dp
          : 'assets/landing/Photographer.svg'
      "
      class="rounded-circle shadow"
      alt=""
    />

    <div class="text-truncate">
      <div class="ph7 ptc5 m-0">Selected Studio</div>
      <div class="ph4-semibold ptc1 text-truncate">
        {{ selected_photeam.photeam_data.photeam_name }}
      </div>
    </div>
    <!-- <div>
      <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L5.82385 5.20713L11.2516 0H0Z" fill="#5F5F5F"></path>
      </svg>
    </div> -->

    <div class="svg">
      <mat-icon>expand_more</mat-icon>
    </div>
  </div>

  <div
    *ngIf="is_dropdown_open && photeam_list?.length>1"
    class="open options drop photeam-select-dropdown-open"
    style="animation: 100ms linear 0ms 1 normal both running"
  >

    <ng-container *ngFor="let photeam of photeam_list">
      <div *ngIf="photeam.photeam_data.photeam_id !=  selected_photeam.photeam_data.photeam_id" class="dropdown-option" (click)="changeSelectedPhoteam(photeam)">
        <img
          [src]="
            photeam.photeam_data.photeam_dp != null
              ? storageURL +
                '/photeam/' +
                photeam.photeam_data.photeam_id +
                '/display/' +
                photeam.photeam_data.photeam_dp
              : 'assets/landing/Photographer.svg'
          "
          class="rounded-circle"
        />
        <span class="ph4-semibold ptc1 text-truncate">{{
          photeam.photeam_data.photeam_name
        }}</span>
      </div>
    </ng-container>
  </div>
</div>
