import { Component, OnInit } from '@angular/core';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { ActivatedRoute, Router } from '@angular/router';

var that: any;

@Component({
  selector: 'app-blog-dashboard',
  templateUrl: './blog-dashboard.component.html',
  styleUrls: ['./blog-dashboard.component.css']
})
export class BlogDashboardComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService) {
    that = this;
  }

  blogData = { id: null, title: null,category:null, published_date:null,cover_image: null,description: null ,url: null, content: null };
  blogList:any;

  clearform() {
    this.blogData = { id: null, title: null, category:null, published_date:null,cover_image: null,description: null ,url: null, content: null };
  }

  saveBlog() {
    if (this.blogData.title != null && this.blogData.url != null && this.blogData.content != null) {
      console.log("save blog");

      this.adminDataService.saveBlogData("save blog data", this.blogData, this.successCB, this.errorCB);
    }
  }

  fetchBloglist() {
    this.adminDataService.getBloglist("get blog list", this.successCB, this.errorCB);
  }

  editBlog(url:any) {
    console.log("edit blog");

    this.dataService.getBlogByUrl("get blog by url", url, this.successCB, this.errorCB);
  }

  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.fetchBloglist();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order: []
    };
  }

  successCB(results:any) {

    if (results.api == "save blog data") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log("blog_saved",results);
        that.fetchBloglist()
        that.clearform();
      }
    }

    else if (results.api == "get blog list") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.blogList = results.data;
        }
        console.log("blog_list",that.blogList);
      }
    }

    else if (results.api == "get blog by url") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.blogData = results.data;
        }
        console.log("blog_url_data",that.blogData);
      }
    }
  }

  errorCB(error:any) {
    console.log(error);
  }

}
