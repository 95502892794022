<!-- Page Wrapper -->
<div id="wrapper">
  <!-- Sidebar -->
  <app-side-bar *ngIf="currentRoute!='/login'"></app-side-bar>

  <!-- End of Sidebar -->
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <!-- Top navbar -->
      <app-nav-bar *ngIf="currentRoute!='/login'"></app-nav-bar>
      <!-- End of Top navbar -->
      <!-- Begin Page Content -->
      <!-- <p>{{currentRoute}}</p> -->
      <router-outlet></router-outlet>
      <!-- /.container-fluid -->
    </div>
    <!-- End of Main Content -->
    <!-- Footer -->
    <app-footer></app-footer>
    <!-- End of Footer -->
  </div>
  <!-- End of Content Wrapper -->
</div>
<!-- End of Page Wrapper -->
<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>
<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <a class="btn btn-primary" href="#" data-dismiss="modal">Logout</a>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
