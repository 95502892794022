<div class="card-body p-2">
  <div class="bg-3 rounded p-3">
    <div>
      <div class="d-flex justify-content-between">
        <div class="ptc1 ph6-bold mb-3">{{ package.pkg_name }}</div>

        <div class="d-flex justify-content-between">
          <button *ngIf="view_type=='detail'" (click)="view_type='normal'">normal view</button>
          <button *ngIf="view_type=='normal'" (click)="view_type='detail'">detail view</button>
        </div>
      </div>

      <div class="ph6-bold">
        <!-- {{ package | json }} -->
        <div>
          <div class="ph7-semibold ptc5 my-2">
            <span *ngFor="let genre of package.pkg_genre; let i = index"
              >{{ genre.genre_name
              }}<span *ngIf="i != package.pkg_genre.length - 1">,&nbsp;</span>
            </span>
          </div>
        </div>

        <div *ngIf="view_type == 'normal'">
          <ul style="padding-left: 18px">
            <ng-container *ngFor="let item of package.pkg_items">
              <li
                *ngIf="
                  item.type == 'services' &&
                  item?.changes?.change_type != 'removed'
                "
              >
                <div class="my-3">
                  <div class="justify-content-between d-flex">
                    <div class="ph5-semibold ptc1">{{ item.name }}</div>
                    <div>
                      <span class="ph5-bold ptc1">{{ item.value }}</span>
                      <span class="ph8 ptc5 ml-2">Sessions</span>
                    </div>
                  </div>
                  <div
                    class="ph7 ptc5 mt-1"
                    *ngIf="item.desc != undefined && item.desc != null"
                    style="max-width: 250px"
                  >
                    {{ item.desc }}
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
          <hr />
          <ul style="padding-left: 18px">
            <ng-container *ngFor="let item of package.pkg_items">
              <li
                *ngIf="
                  item.type == 'deliverables' &&
                  item?.changes?.change_type != 'removed'
                "
              >
                <div class="my-3">
                  <div class="justify-content-between d-flex">
                    <div class="ph5-semibold ptc1">{{ item.name }}</div>
                    <div>
                      <span class="ph5-bold ptc1">{{ item.value }}</span>
                      <span class="ph8 ptc5 ml-2">Units</span>
                    </div>
                  </div>
                  <div
                    class="ph7 ptc5 mt-1"
                    *ngIf="item.desc != undefined && item.desc != null"
                    style="max-width: 250px"
                  >
                    {{ item.desc }}
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>

        <div *ngIf="view_type == 'detail'">
          <ul style="padding-left: 18px">
            <ng-container *ngFor="let item of package.pkg_items">
              <ng-container *ngIf="item.type == 'services'">
                <ng-container *ngIf="item?.changes; else no_change">
                  <li>
                    <div class="my-3">
                      <div class="justify-content-between d-flex">
                        <div>
                          <div class="d-flex align-items-center">
                            <span
                              class="mr-1 ptc5 ph8"
                              *ngIf="item?.changes?.change_type == 'removed'"
                              >(-)</span
                            >
                            <span
                              class="mr-1 ptc5 ph8"
                              *ngIf="item?.changes?.change_type == 'added'"
                              >(+)</span
                            >
                            <span
                              title="By {{ item?.changes?.changed_by }}"
                              class="ph5-semibold ptc1"
                              [ngClass]="{
                                pkg_item_removed:
                                  item?.changes?.change_type == 'removed',
                                pkg_item_added:
                                  item?.changes?.change_type == 'added'
                              }"
                              >{{ item.name }}</span
                            >
                          </div>
                          <div class="ph7-semibold ptc5">
                            {{ item?.changes?.change_type }} by
                            {{ item?.changes?.changed_by }} at
                            {{ item?.changes?.changed_at | date: "d-MMM-yy" }}
                          </div>
                        </div>
                        <div>
                          <span class="ph5-bold ptc1">{{ item.value }}</span>
                          <span class="ph8 ptc5 ml-2">Sessions</span>
                        </div>
                      </div>
                      <div
                        class="ph7 ptc5 mt-1"
                        *ngIf="
                          item.desc != undefined &&
                          item.desc != null &&
                          item?.changes?.change_type != 'removed'
                        "
                        style="max-width: 250px"
                      >
                        {{ item.desc }}
                      </div>
                    </div>
                  </li>
                </ng-container>

                <ng-template #no_change>
                  <li>
                    <div class="my-3">
                      <div class="justify-content-between d-flex">
                        <div class="d-flex align-items-center">
                          <span class="ph5-semibold ptc1">{{ item.name }}</span>
                        </div>
                        <div>
                          <span class="ph5-bold ptc1">{{ item.value }}</span>
                          <span class="ph8 ptc5 ml-2">Sessions</span>
                        </div>
                      </div>
                      <div
                        class="ph7 ptc5 mt-1"
                        *ngIf="item.desc != undefined && item.desc != null"
                        style="max-width: 250px"
                      >
                        {{ item.desc }}
                      </div>
                    </div>
                  </li></ng-template
                >
              </ng-container>
            </ng-container>
          </ul>
          <hr />
          <ul style="padding-left: 18px">
            <ng-container *ngFor="let item of package.pkg_items">
              <ng-container *ngIf="item.type == 'deliverables'">
                <ng-container *ngIf="item?.changes; else no_change">
                  <li>
                    <div class="my-3">
                      <div class="justify-content-between d-flex">
                        <div>
                          <div class="d-flex align-items-center">
                            <span
                              class="mr-1 ptc5 ph8"
                              *ngIf="item?.changes?.change_type == 'removed'"
                              >(-)</span
                            >
                            <span
                              class="mr-1 ptc5 ph8"
                              *ngIf="item?.changes?.change_type == 'added'"
                              >(+)</span
                            >
                            <span
                              title="By {{ item?.changes?.changed_by }}"
                              class="ph5-semibold ptc1"
                              [ngClass]="{
                                pkg_item_removed:
                                  item?.changes?.change_type == 'removed',
                                pkg_item_added:
                                  item?.changes?.change_type == 'added'
                              }"
                              >{{ item.name }}</span
                            >
                          </div>
                          <div class="ph7-semibold ptc5">
                            {{ item?.changes?.change_type }} by
                            {{ item?.changes?.changed_by }} at
                            {{ item?.changes?.changed_at | date: "d-MMM-yy" }}
                          </div>
                        </div>
                        <div>
                          <span class="ph5-bold ptc1">{{ item.value }}</span>
                          <span class="ph8 ptc5 ml-2">Sessions</span>
                        </div>
                      </div>
                      <div
                        class="ph7 ptc5 mt-1"
                        *ngIf="
                          item.desc != undefined &&
                          item.desc != null &&
                          item?.changes?.change_type != 'removed'
                        "
                        style="max-width: 250px"
                      >
                        {{ item.desc }}
                      </div>
                    </div>
                  </li>
                </ng-container>

                <ng-template #no_change>
                  <li>
                    <div class="my-3">
                      <div class="justify-content-between d-flex">
                        <div class="d-flex align-items-center">
                          <span class="ph5-semibold ptc1">{{ item.name }}</span>
                        </div>
                        <div>
                          <span class="ph5-bold ptc1">{{ item.value }}</span>
                          <span class="ph8 ptc5 ml-2">Sessions</span>
                        </div>
                      </div>
                      <div
                        class="ph7 ptc5 mt-1"
                        *ngIf="item.desc != undefined && item.desc != null"
                        style="max-width: 250px"
                      >
                        {{ item.desc }}
                      </div>
                    </div>
                  </li></ng-template
                >
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
