import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from './../../constants/global-constants';
import { ADMIN_APIURL } from './../../url/adminUrl-constants';
import { APIURL } from './../../url/url-constants';


@Injectable({
  providedIn: 'root'
})

export class AdminDataService {
  constructor(
    private http: HttpClient
  ) { }

  //---------------- user ----------------
  createUser(apiname: string, user: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.createUser;
    const body = {
      user: user
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getUserData(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getUserData;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getUserAccesToken(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getUserAccesToken;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getUserKYCData(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getKycData;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ---------------- Photeams ----------------
  getUserPhoteamData(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getUserPhoteamData;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  changePhoteamState(apiname: string, photeam_id: any, value: boolean, state: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.changePhoteamState;
    const body = {
      photeam_id: photeam_id,
      state: state,
      value: value
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  updatePhoteamDescription(apiname: string, photeam_id: any, description: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.updatePhoteamDescription;
    const body = {
      photeam_id: photeam_id,
      description: description
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  changePhoteamBankState(apiname: string, photeam_id: any, value: boolean, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.changePhoteamBankState;
    const body = {
      photeam_id: photeam_id,
      value: value
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ---------------------------------

  getUserQuotationData(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getUserQuotationData;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamData(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getPhoteamData;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  saveBlogData(apiname: string, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getSaveData;
    const body = {
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getBloglist(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getBlogList;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // -------------- Master Data-------
  addMasterServicesData(apiname: string, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.addMasterServicesData;
    const body = {
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  addMasterGenreData(apiname: string, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.addMasterGenreData;
    const body = {
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  addMasterLanguageData(apiname: string, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.addMasterLanguageData;
    const body = {
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  addMasterLocationsData(apiname: string, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.addMasterLocationsData;
    const body = {
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // --------- Bookings ---------

  getAllBookingList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getAllBookingList;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getBookingDetailsById(apiname: string, booking_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getBookingDetailsById;
    const body = {
      booking_id: booking_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // --------- Quotaion ---------
  getAllQuotationData(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getQuotationData;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getQuotationDataById(apiname: string, quotation_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getQuotationDataByBillId;
    const body = {
      quotation_id: quotation_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // -------- Invoive -----------
  getAllInvoiceData(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getInvoiceData;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getInvoiceDataById(apiname: string, invoice_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getInvoiceDataByBillId;
    const body = {
      invoice_id: invoice_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ----------- Billing --------
  getAllBillingData(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getBillData;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getBillingDataById(apiname: string, bill_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getBillDataByBillId;
    const body = {
      bill_id: bill_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }


  // --------- Product ---------

  addProductData(apiname: string, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.addProductData;
    const body = {
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // --------- Coupon ---------

  addCouponData(apiname: string, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.addCouponData;
    const body = {
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ------------- payment orders --------------
  getAllPackagesOrdersCashfreeTxn(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getAllPackagesOrdersCashfreeTxn;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getCashfreeTxnData(apiname: string, txn_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getCashfreeTxnData;
    const body = {
      txn_id: txn_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getAllPackagesOrdersOffPlatformTxn(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getAllPackagesOrdersOffPlatformTxn;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getOffPlatformTxnData(apiname: string, txn_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getOffPlatformTxnData;
    const body = {
      txn_id: txn_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPackagesOrdersCashfreeTxnbyPhoteam(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getPackagesOrdersCashfreeTxnbyPhoteam;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPackagesOrdersOffPlatformTxnbyPhoteam(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getPackagesOrdersOffPlatformTxnbyPhoteam;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getConfirmedOrderTxnbyPhoteam(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getConfirmedOrderTxnbyPhoteam;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getAllProductsbyPhoteam(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.getAllProductsbyPhoteam;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

}
