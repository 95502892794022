
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ADMIN_APIURL } from './../../url/adminUrl-constants';
import { GlobalConstants } from './../../constants/global-constants';
import { LocalStorage } from './../../constants/local-storage-constants';
import { ServerCode } from './../../constants/server-code-constants';


@Injectable({
    providedIn: 'root'
})

export class AdminAuthService {
    constructor(
        private http: HttpClient,
        public jwtHelper: JwtHelperService,
    ) { }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem(LocalStorage.ACCESSTOKEN) || '';
        // Check whether the token is expired and return
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
    }

    public getUserIdfromJWT(): string {
        const token = localStorage.getItem(LocalStorage.ACCESSTOKEN) || '';
        if (!this.jwtHelper.isTokenExpired(token)) {
            var data = this.jwtHelper.decodeToken(token);
            if (data != null && data != undefined)
                return data.sub;
        }
        return '';
    }

    //user login api
    login(apiname: string, email: string, password: string, successCB: Function, errorCB: Function) {
        const body = {
            email: email,
            password: password
        };
        const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.login;
        var promise = this.http.post<any>(url, body).toPromise();
        promise.then((data) => {
            if (data.servercode == ServerCode.SUCCESS) {
                if (data.data.access_token != undefined && data.data.access_token != null && data.data.access_token != '') {
                    localStorage.setItem(LocalStorage.ACCESSTOKEN, data.data.access_token);
                } else {
                    // console.log("Invalid access token in response");
                }
            }
            data.api = apiname;
            successCB(data);
        }).catch((err) => {
            errorCB(err);
        })
    }


    logout(apiname: string, successCB: Function, errorCB: Function) {
        const url = GlobalConstants.baseURL + '/' + ADMIN_APIURL.logout;
        const body = null;
        var promise = this.http.post<any>(url, body).toPromise();

        promise.then((data) => {
            if (data.servercode == ServerCode.SUCCESS) {
                localStorage.removeItem(LocalStorage.ACCESSTOKEN);
            }
            data.api = apiname;
            successCB(data);
        }).catch((err) => {
            errorCB(err);
        })
    }


}
