import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
var that;
@Component({
  selector: 'app-transactions-dashboard',
  templateUrl: './transactions-dashboard.component.html',
  styleUrls: ['./transactions-dashboard.component.css']
})
export class TransactionsDashboardComponent implements OnInit {

  @ViewChild('json_modal', { static: true }) json_modal: ElementRef;
  modalRef: any;
  view_json: any;

  routesPath = AdminRoutesPath;
  constructor(
    // private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    // private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    that = this;
    // this.photeam_id = this.route.snapshot.params.photeam_id;
  }

  photeam_id: any;
  cashfree_txn_list = [];
  OffPlatform_txn_list = [];
  dtOptions: DataTables.Settings = {};

  confirmed_order_list = []
  products_list = []

  ngOnInit(): void {
    this.fetchAllCashfreeTxn();
    this.fetchAllOffPlatformTxn();
  }


  fetchAllCashfreeTxn() {
    this.adminDataService.getAllPackagesOrdersCashfreeTxn("fetch all cashfree txn", this.successCB, this.errorCB);
  }


  fetchAllOffPlatformTxn() {
    this.adminDataService.getAllPackagesOrdersOffPlatformTxn("fetch all OffPlatform txn", this.successCB, this.errorCB);
  }

  txn_details(txn_id, txn_src) {
    let url = `${AdminRoutesPath.TRANSACTION_DETAILS}/${txn_src}/${txn_id}`
    this.router.navigateByUrl(url);
  }

  viewJSON(data) {
    this.view_json = data;
    this.openModal()
  }

  openModal() {
    this.modalRef = this.modalService.open(this.json_modal, {
      centered: true, size: 'lg'

    });
  }

  onModalClose() {
    this.modalRef.close();
  }

  successCB(results: any) {

    if (results.api == "fetch all cashfree txn") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('cashfree txn', results.data)
        that.cashfree_txn_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

    if (results.api == "fetch all OffPlatform txn") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('OffPlatform txn', results.data)
        that.OffPlatform_txn_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

    if (results.api == "fetch confirmed order list by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('confirmed_order_list', results.data)
        that.confirmed_order_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

    if (results.api == "fetch products list by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('products_list', results.data)
        that.products_list = results.data;
        // that.toastr.success('Super highlighted');
      }
    }

  }

  errorCB(error: any) {
    console.log(error);
  }
}
