import { Component } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from "@angular/router";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentRoute: string = "";


  constructor(location: Location, router: Router) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.currentRoute = location.path();
      } else {
        this.currentRoute = "Home";
      }
    });
  }
  title = 'pickle-admin-v2';
}
