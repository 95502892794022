import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminAuthService } from 'src/app/data/dataservice/auth-dataservice/adminAuth.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';

var that:any;
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AdminAuthService) {
    that = this;
   }

  OnLogoutclick() {
    console.log('logout');

    this.authService.logout(
      "logout api",
      this.successCB,
      this.errorCB
    );

  }

  ngOnInit(): void {
  }

  successCB(results:any) {
    if (results.api == 'logout api') {
      if (results.servercode == ServerCode.SUCCESS) {
        // that.toastr.success('Logged-in successfully');
        that.router.navigateByUrl('/login');
      }
    }

  }

  errorCB(error:any) {
    if (error != null && error != undefined) {
      // console.log(error);

    }
  }

}
