<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Photeam Purchases</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/{{ routesPath.PHOTEAMS }}">Photeams</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/{{ routesPath.PHOTEAM_OVERVIEW }}/{{ photeam_id }}"
          >Photeam Overview</a
        >
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Photeam Purchases
      </li>
    </ol>
  </nav>

  <!-- Page Content -->
  <div>
    <!-- Order Txn -->
    <div class="row">
      <div class="col-md-6" *ngIf="cashfree_txn_list.length > 0">
        <!-- Cashfree Orders -->
        <div class="card shadow mb-4">
          <!-- Card Header - Accordion -->
          <a
            href="#CashfreeOrdersCard"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            aria-controls="collapseCardExample"
          >
            <h6 class="m-0 font-weight-bold text-primary">Cashfree Txn</h6>
          </a>
          <!-- Card Content - Collapse -->
          <div class="collapse show" id="CashfreeOrdersCard">
            <div
              class="card-body custom-scroll"
              style="max-height: 400px; overflow: auto"
            >
              <div
                class="table-responsive"
                *ngIf="cashfree_txn_list?.length > 0"
                style="max-height: 800px"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  id="myTable"
                  class="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Txn_id</th>
                      <th>Photeam</th>
                      <th>User</th>
                      <th>cf_order_id</th>
                      <th>Order_ID</th>
                      <th>order_amount</th>
                      <th>item_type</th>
                      <th>order_status</th>
                      <th>payment_status</th>
                      <th>Data</th>
                      <th>Created_at</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let item of cashfree_txn_list">
                      <td>{{ item.txn_id }}</td>
                      <td
                        class="cursor-pointer text-warning"
                        routerLink="/{{ routesPath.PHOTEAM_DETAILS }}/{{
                          item.photeam_id
                        }}"
                      >
                        {{ item.photeam_id }}
                      </td>
                      <td
                        class="cursor-pointer text-warning"
                        routerLink="/{{ routesPath.USER_DETAILS }}/{{
                          item.user_id
                        }}"
                      >
                        {{ item.user_id }}
                      </td>

                      <td>{{ item.cf_order_id }}</td>
                      <td>{{ item.order_id }}</td>
                      <td>{{ item.order_amount }}</td>
                      <td>{{ item.item_type }}</td>
                      <td>{{ item.order_status }}</td>
                      <td>{{ item.payment_status }}</td>
                      <td>
                        <div
                          class="tw-cursor-pointer hover:tw-text-red-500"
                          (click)="viewJSON(item.cost_breakup)"
                        >
                          Cost_Breakup
                        </div>
                        <div
                          class="tw-cursor-pointer hover:tw-text-red-500"
                          (click)="viewJSON(item.items_data)"
                        >
                          Cart_Items
                        </div>
                        <div
                          class="tw-cursor-pointer hover:tw-text-red-500"
                          (click)="viewJSON(item.cashfree_api_data)"
                        >
                          Cashfree_Payload
                        </div>
                        <div
                          class="tw-cursor-pointer hover:tw-text-red-500"
                          (click)="viewJSON(item.cashfree_webhook_data)"
                        >
                          Cashfree_Response
                        </div>
                      </td>

                      <td>
                        {{ item.created_at | date: "MMMM d, y, h:mm:ss a" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="OffPlatform_txn_list.length > 0">
        <!-- Off-Platform Orders -->
        <div class="card shadow mb-4">
          <!-- Card Header - Accordion -->
          <a
            href="#OffPlatformCard"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            aria-controls="collapseCardExample"
          >
            <h6 class="m-0 font-weight-bold text-primary">Off-Platform Txn</h6>
          </a>
          <!-- Card Content - Collapse -->
          <div class="collapse show" id="OffPlatformCard">
            <div
              class="card-body custom-scroll"
              style="max-height: 400px; overflow: auto"
            >
              <!-- {{ OffPlatform_txn_list | json }} -->

              <div
                class="table-responsive"
                *ngIf="OffPlatform_txn_list?.length > 0"
                style="max-height: 800px"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  id="myTable"
                  class="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Txn_id</th>
                      <th>Photeam</th>
                      <th>User</th>
                      <th>Order ID</th>
                      <th>order_amount</th>
                      <th>item_type</th>
                      <th>Data</th>

                      <th>Created_at</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let item of OffPlatform_txn_list">
                      <td>{{ item.txn_id }}</td>
                      <td
                        class="cursor-pointer text-warning"
                        routerLink="/{{ routesPath.PHOTEAM_DETAILS }}/{{
                          item.photeam_id
                        }}"
                      >
                        {{ item.photeam_id }}
                      </td>
                      <td
                        class="cursor-pointer text-warning"
                        routerLink="/{{ routesPath.USER_DETAILS }}/{{
                          item.user_id
                        }}"
                      >
                        {{ item.user_id }}
                      </td>

                      <td>{{ item.order_id }}</td>
                      <td>{{ item.order_amount }}</td>
                      <td>{{ item.item_type }}</td>
                      <td>
                        <div
                          class="tw-cursor-pointer hover:tw-text-red-500"
                          (click)="viewJSON(item.cost_breakup)"
                        >
                          Cost_Breakup
                        </div>
                        <div
                          class="tw-cursor-pointer hover:tw-text-red-500"
                          (click)="viewJSON(item.items_data)"
                        >
                          Cart_Items
                        </div>
                        <div
                          class="tw-cursor-pointer hover:tw-text-red-500"
                          (click)="viewJSON(item.payment_data)"
                        >
                          Payment_Data
                        </div>
                      </td>
                      <td>
                        {{ item.created_at | date: "MMMM d, y, h:mm:ss a" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmed Order -->
    <div class="card shadow mb-4" *ngIf="confirmed_order_list.length > 0">
      <!-- Card Header - Accordion -->
      <a
        href="#ConfirmedOrdersCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">Confirmed Orders</h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse show" id="ConfirmedOrdersCard">
        <div
          class="card-body custom-scroll"
          style="max-height: 400px; overflow: auto"
        >
          <div class="table-responsive" style="max-height: 800px">
            <table
              datatable
              [dtOptions]="dtOptions"
              id="myTable"
              class="table"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <!-- <th>ID</th>
                  <th>user_id</th>
                  <th>Photeam_id</th> -->
                  <th>order_id</th>
                  <th>purchase_src</th>
                  <th>order_amount</th>
                  <th>item_type</th>
                  <th>Data</th>
                  <th>Created_at</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of confirmed_order_list">
                  <!-- <td>{{ item.id }}</td>
                  <td
                    class="cursor-pointer text-warning"
                    routerLink="/{{ routesPath.PHOTEAM_DETAILS }}/{{
                      item.photeam_id
                    }}"
                  >
                    {{ item.photeam_id }}
                  </td>
                  <td
                    class="cursor-pointer text-warning"
                    routerLink="/{{ routesPath.USER_DETAILS }}/{{
                      item.user_id
                    }}"
                  >
                    {{ item.user_id }}
                  </td> -->

                  <td>{{ item.order_id }}</td>
                  <td>{{ item.purchase_src }}</td>
                  <td>{{ item.order_amount }}</td>
                  <td>{{ item.item_type }}</td>
                  <td>
                    <div
                      class="tw-cursor-pointer hover:tw-text-red-500"
                      (click)="viewJSON(item.items_data)"
                    >
                      Cart_Items
                    </div>
                  </td>
                  <td>
                    {{ item.created_at | date: "MMMM d, y, h:mm:ss a" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Products -->
    <div class="card shadow mb-4" *ngIf="products_list.length > 0">
      <!-- Card Header - Accordion -->
      <a
        href="#ProductsCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">Products</h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse show" id="ProductsCard">
        <div
          class="card-body custom-scroll"
          style="max-height: 400px; overflow: auto"
        >
          <div class="table-responsive" style="max-height: 800px">
            <table
              datatable
              [dtOptions]="dtOptions"
              id="myTable"
              class="table"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <!-- <th>ID</th>
                    <th>user_id</th>
                    <th>Photeam_id</th> -->
                  <th>order_id</th>
                  <th>product_code</th>
                  <th>product_name</th>
                  <th>Data</th>
                  <th>Created_at</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of products_list">
                  <!-- <td>{{ item.id }}</td>
                    <td
                      class="cursor-pointer text-warning"
                      routerLink="/{{ routesPath.PHOTEAM_DETAILS }}/{{
                        item.photeam_id
                      }}"
                    >
                      {{ item.photeam_id }}
                    </td>
                    <td
                      class="cursor-pointer text-warning"
                      routerLink="/{{ routesPath.USER_DETAILS }}/{{
                        item.user_id
                      }}"
                    >
                      {{ item.user_id }}
                    </td> -->

                  <td>{{ item.order_id }}</td>
                  <td>{{ item.item_data.product_code }}</td>
                  <td>{{ item.item_data.name }}</td>

                  <td>
                    <div
                      class="tw-cursor-pointer hover:tw-text-red-500"
                      (click)="viewJSON(item.item_data)"
                    >
                      Cart_Items
                    </div>
                    <div
                      class="tw-cursor-pointer hover:tw-text-red-500"
                      (click)="viewJSON(item.misc_data)"
                    >
                      Misc_Data
                    </div>
                  </td>
                  <td>
                    {{ item.created_at | date: "MMMM d, y, h:mm:ss a" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #json_modal let-modal>
  <div class="container p-3">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <div class="ph5-bold ptc1">View Data</div>
        <!-- <div class="ph7 ptc5">
          You have not created any Studio yet. Please create your studio.
        </div> -->
      </div>
      <div>
        <mat-icon class="ph4 ptc1 cursor_pointer" (click)="onModalClose()"
          >close</mat-icon
        >
      </div>
    </div>
    <ng-container>
      <pre>{{ view_json | json }}</pre>
    </ng-container>
  </div>
</ng-template>
