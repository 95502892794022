<div class="tw-bg-[#F8F9FF] tw-relative">
  <div class="container" style="max-width: 1437px">
    <!-- info content -->
    <div class="tw-py-[100px]">
      <div class="position-relative">
        <div class="text-center" *ngIf="user?.membership_type != 'PREM'">
          <div class="ph1-bold ptc1">Upgrade to our premium plan</div>
          <div class="ph1-lite ptc1">to get everything unlimited</div>
        </div>
        <div class="text-center" *ngIf="user?.membership_type == 'PREM'">
          <div class="ph1-bold ptc1">Your premium membership is active</div>
          <div class="ph1-lite ptc1">Buy add-ons to enhance your studio</div>
        </div>
        <div class="text-center pt-3 ptc5 ph5 tw-text-[##757E9D]">
          <!-- <span
            ><a
              href="{{ pricing_page }}"
              class="ptc12 ph5-semibold"
              target="_blank"
              >Click here</a
            >
          </span> -->
          to read more about our premium plan offerings.
          <br />
          <span
            ><span
              (click)="upgrade_adapter('schedule_demo')"
              class="ptc12 ph5-semibold cursor_pointer"
              >Click here</span
            >
            to set up a free demo.
          </span>
        </div>

        <img class="stonks" src="/assets/generic/stonks.svg" alt="" />
      </div>
    </div>

    <!-- payment module -->
    <div class="row">
      <!-- left -->
      <div class="col-lg-8 py-3">
        <div class="order_items_div cursor_pointer">
          <!-- Skeleton Loader -->
          <ng-container *ngIf="isLoading">
            <div class="skeleton-loader">
              <div class="skeleton-item" *ngFor="let product of [1, 2, 3]">
                <div class="skeleton-img"></div>
                <div class="skeleton-content">
                  <div class="skeleton-text large"></div>
                  <div class="skeleton-text small"></div>
                  <div class="skeleton-text small"></div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- Product List -->
          <ng-container *ngIf="!isLoading">
            <div
              class="product_div"
              [ngClass]="{ active: product.selected }"
              *ngFor="let product of product_list_master"
              (click)="addProductToCart(product)"
            >
              <div class="product_div_img">
                <img
                  class="img-fluid tw-w-[430px]"
                  src="\assets\V4\illustration\prime_package.png"
                />
              </div>
              <div class="product_content">
                <div>
                  <div class="f-24 fw-600">{{ product.product_name }}</div>
                  <div class="f-12 fw-400">
                    {{ product.product_description }}
                  </div>
                </div>
                <div class="tw-text-right">
                  <div class="f-24 fw-600">
                    <span> ₹{{ getSelectedPricingOption(product) }}/Month</span>
                  </div>
                  <div class="f-11 fw-400">
                    Get 5% OFF for 6 Months & 10% for 12 Months purchase
                  </div>
                </div>
              </div>
            </div>

            <div class="product_div">
              <div class="product_div_img">
                <img
                  class="img-fluid"
                  src="\assets\V4\illustration\prime_package.png"
                  alt=""
                />
              </div>
              <div class="product_content">
                <div>
                  <div class="f-24 fw-600">Adapter</div>
                  <div class="f-12 fw-400">
                    Tailored for photographers with unique needs, this package
                    offers custom solutions and deliverables to fit your
                    specific business requirements.
                  </div>
                </div>
                <div class="tw-text-right">
                  <!-- <app-secondary-button
                    buttonText="Get in touch"
                    size="small"
                    class="tw-text-nowrap"
                    (onButtonClick)="upgrade_adapter('adapter_plan')"
                  >
                  </app-secondary-button> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- right -->
      <div class="col-lg-4 py-3">
        <div class="order_summery_div sticky">
          <div class="ph3">Order Summary</div>
          <!-- selected photeam -->
          <div class="d-flex tw-items-center tw-gap-4 mt-2">
            <div
              class="tw-size-12 tw-rounded-full d-flex tw-overflow-hidden shadow-bottom"
            >
              <img
              *ngIf="selected_photeam"
                [src]="
                  selected_photeam.photeam_data.photeam_dp != null
                    ? storageURL +
                      '/photeam/' +
                      selected_photeam.photeam_data.photeam_id +
                      '/display/' +
                      selected_photeam.photeam_data.photeam_dp
                    : 'assets/landing/Photographer.svg'
                "
              />
            </div>

            <div class="text-truncate">
              <div class="ph6 ptc5 mb-1">Current Studio</div>
              <div class="ph5-semibold ptc1 text-truncate">
                {{ selected_photeam?.photeam_data?.photeam_name }}
              </div>
            </div>
          </div>
          <!-- selected photeam -->

          <div class="car_summary mb-4">
            <div class="ph5-bold ptc1">Payment Details</div>
            <div class="py-2">
              <select class="form-control" [(ngModel)]="payment_data.received_by">
                <option value="">Who Received Payment ?</option>
                <option value="bhargav">Bhargav</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="py-2">
              <select
                class="form-control"
                [(ngModel)]="payment_data.payment_mode"
              >
                <option value="">Select Payment Method</option>
                <option value="upi">UPI</option>
                <option value="cash">Cash</option>
                <option value="bank_transfer">Bank Transfer</option>
                <option value="card">Card</option>
                <option value="payment_pending">payment_pending</option>
                <option value="coupon">coupon</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div class="py-2">
              <label for="pay_img">Payment Date</label>
              <input
                class="form-control"
                [(ngModel)]="payment_data.payment_date"
                type="date"
              />
            </div>

            <div class="py-2">
              <label for="pay_img">Payment Screnshot</label>
              <input
                class="form-control"
                F
                type="file"
                (change)="onSelectFile($event)"
                accept="image/jpg, image/jpeg, image/png"
                id="pay_img"
              />

              <div class="text-center">
                <img
                  [src]="payment_data.img"
                  class="img-fluid"
                  style="max-height: 200px"
                  alt=""
                />
              </div>
            </div>
          </div>

          <!-- coupon -->
          <div class="coupon_card mt-4">
            <!-- Apply coupon -->
            <div class="apply_coupon">
              <div>
                <div class="ph5-semibold">Apply Discount Code</div>
                <!-- <div class="ptc1 ph7 pt-1">
                  Apply <span class="ptc12">'EARLYBIRD'</span> to get
                  <span class="bold">15% discount</span>
                </div> -->
              </div>

              <div class="flex_between_center tw-gap-3">
                <div class="md-form md-outline form_v3">
                  <input
                    id="coupon_code"
                    class="form-control"
                    type="text"
                    mdbInput
                    name="coupon_code"
                    [(ngModel)]="coupon_code"
                    (ngModelChange)="coupon_code = coupon_code.toUpperCase()"
                  />
                  <label for="coupon_code">Enter Code</label>
                </div>

                <div class="flex">
                  <button class="secondary_btn f-12" (click)="SearchCoupon()">
                    Apply
                  </button>
                </div>
              </div>
            </div>

            <!-- Applied coupon -->
            <div
              class="pt-3 applied_coupon"
              *ngIf="cart.cart_coupon.coupon_code"
            >
              <div class="d-flex align-items-center">
                <div class="ph7-semibold mr-3">Coupon applied</div>
                <div class="coupon-label">
                  <span class="ph7-bold ptc0">{{
                    cart.cart_coupon.coupon_code.toUpperCase()
                  }}</span>
                  <mat-icon class="ml-2 cursor_pointer" (click)="removeCoupon()"
                    >close</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- coupon -->

          <div class="mt-4">
            <!-- item list -->
            <div *ngFor="let item of cart.cart_items">
              <ng-container *ngIf="item.category == 'PACKAGE'">
                <div class="flex_between">
                  <div>
                    <div class="ph5-semibold">
                      <div class="ptc13 tw-w-[140px]">
                        {{ item.product_name }}
                      </div>
                    </div>
                    <div
                      *ngIf="item?.discount?.discount_value > 0"
                      class="ph8 ptc18"
                    >
                      {{ item?.discount?.discount_value }}% off applied (₹{{
                        item?.discount?.round_off_amount
                      }})
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="tw-w-[60px]">
                      <div class="ph4-bold ptc0">
                        ₹{{
                          item.price_after_discount?.toLocaleString("en-IN")
                        }}
                      </div>
                      <div
                        *ngIf="
                          item.total_final_price != item.price_after_discount
                        "
                        class="ph8 ptc5 tw-line-through"
                      >
                        ₹{{ item.total_final_price?.toLocaleString("en-IN") }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Package Duration -->
                <div class="flex_between mt-3 align-items-center">
                  <div class="ph6 ptc5">Package Duration</div>
                  <mat-form-field
                    appearance="outline"
                    class="tw-text-[12px] p-0"
                  >
                    <mat-select
                      [value]="getSelectedOption(selected_product).option_id"
                      (selectionChange)="
                        onPackageOptionChange($event.value, item.product_id)
                      "
                    >
                      <mat-option
                        *ngFor="let option of selected_product.pricing_options"
                        [value]="option.option_id"
                        >{{ option.option_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- Renews on -->
                <div class="flex_between mt-3">
                  <div>
                    <div class="ph6 ptc5">Renews on</div>
                  </div>
                  <div class="d-flex">
                    <div class="ph6-semibold ptc5">
                      {{ item?.validity.end_date | date : "mediumDate" }}
                    </div>
                    <!-- <div class="tw-w-[30px]"></div> -->
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="item.category == 'ADDONS'">
                <div class="flex_between">
                  <div>
                    <div class="ph5-semibold d-flex">
                      <div class="ptc13 tw-w-[140px]">{{ item.name }}</div>
                      <div *ngIf="item.unit_count > 1" class="ptc12 ml-2">
                        x {{ item.unit_count }}
                      </div>
                    </div>
                    <div *ngIf="item?.discount?.value > 0" class="ph8 ptc18">
                      {{ item?.discount?.value }}% off applied (₹{{
                        item?.discount?.round_off_amount
                      }})
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="tw-w-[60px]">
                      <div class="ph4-bold ptc0">
                        ₹{{
                          item.price_after_discount?.toLocaleString("en-IN")
                        }}
                      </div>
                      <div
                        *ngIf="
                          item.total_final_price != item.price_after_discount
                        "
                        class="ph8 ptc5 tw-line-through"
                      >
                        ₹{{ item.total_final_price?.toLocaleString("en-IN") }}
                      </div>
                    </div>

                    <div class="tw-w-[30px] d-flex tw-justify-end">
                      <mat-icon
                        *ngIf="item.category == 'ADDONS'"
                        title="delete"
                        (click)="removeProductFromCart(item)"
                        class="cursor_pointer tw-text-[#606781] hover:tw-text-red-500"
                        >delete_outline</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </ng-container>
              <hr />
            </div>

            <!-- sub total & discount -->
            <div>
              <div class="flex_between_center mt-3">
                <div>
                  <div class="ph6 ptc5">
                    Subtotal ({{ cart.cart_items.length }} Items)
                  </div>
                  <div
                    class="ph8 ptc18"
                    *ngIf="cart.total_price.internal_discount_total"
                  >
                    You Save ₹{{
                      cart.total_price.internal_discount_total.toLocaleString(
                        "en-IN"
                      )
                    }}
                  </div>
                </div>

                <div class="d-flex">
                  <div class="ph6-semibold ptc5">
                    ₹{{
                      cart.total_price.final_price_total?.toLocaleString(
                        "en-IN"
                      )
                    }}
                  </div>
                  <!-- <div class="tw-w-[30px]"></div> -->
                </div>
              </div>

              <div
                *ngIf="cart.cart_discount.discount_value > 0"
                class="flex_between_center mt-3"
              >
                <div>
                  <div class="ph6 ptc5">
                    Discount ({{ cart.cart_discount.discount_value }}%)
                  </div>
                  <!-- <div class="ph8 ptc18">Combo offer</div> -->
                </div>

                <div class="d-flex">
                  <div class="ph6-semibold ptc18 tw-w-[60px] text-nowrap">
                    (-) ₹{{
                      cart.cart_discount.round_off_amount.toLocaleString(
                        "en-IN"
                      )
                    }}
                  </div>
                  <!-- <div class="tw-w-[30px]"></div> -->
                </div>
              </div>

              <hr class="horizontalLine" />
            </div>

            <!-- total  -->
            <div>
              <div class="flex_between_center mt-3">
                <div>
                  <div class="ph5-semibold ptc13">Total Payable</div>
                  <div class="ph8 ptc5">(Incl all charges)</div>
                </div>

                <div class="d-flex">
                  <div class="ph4-bold ptc12">
                    <!-- ₹{{ cart.payable_amount?.toLocaleString("en-IN") }} -->
                    <input
                    type="number"
                    [(ngModel)]="cart.payable_amount"
                    (ngModelChange)="onPayableAmountChange($event)"
                    min="0"
                    class="w-100"
                  />
                  </div>
                  <!-- <div class="tw-w-[30px]"></div> -->
                </div>
              </div>
            </div>

            <div class="f-11 fw-400 fc-s mt-3">
              By proceeding to payment, you agree to our
              <!-- <a [href]="privacy_policy">Payment Policy</a> and
              <a [href]="cancellation_policy">Cancellation Policy</a> -->
            </div>
          </div>

          <div class="mt-3">
            <!-- <app-primary-button
              class="text-center"
              buttonText="Confirm Order"
              (onButtonClick)="placeOrder()"
              size="medium"
            ></app-primary-button> -->
            <button
            class="btn btn-primary w-100"
            (click)="placeOrder()"
            type="button"
          >
            Confirm Order
          </button>
          </div>
          <!-- <pre>cost_breakup : {{ cost_breakup | json }}</pre> -->
          <!-- <pre>selected_product : {{ selected_product | json }}</pre> -->
          <!-- <pre>product_list_master : {{ product_list_master | json }}</pre> -->
        </div>
      </div>
    </div>
  </div>

  <div class="tw-absolute tw-left-8 tw-top-24">
    <mat-icon class="tw-scale-150" (click)="returnToDashboard()"
      >close</mat-icon
    >
  </div>
</div>


