import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
var that;
@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.css']
})
export class TransactionDetailsComponent implements OnInit {

  @ViewChild('json_modal', { static: true }) json_modal: ElementRef;
  modalRef: any;
  view_json: any;

  routesPath = AdminRoutesPath;
  constructor(
    // private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    // private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    that = this;
    this.txn_src = this.route.snapshot.params.txn_src;
    this.txn_id = this.route.snapshot.params.txn_id;
  }

  txn_id;
  txn_src;
  data;

  ngOnInit(): void {
    this.fetchTxnDatabyTxn_id();
  }

  fetchTxnDatabyTxn_id() {
    if (this.txn_src == 'CASHFREE') {
      this.adminDataService.getCashfreeTxnData("fetch data by txn_id", this.txn_id, this.successCB, this.errorCB);
    } else {
      this.adminDataService.getOffPlatformTxnData("fetch data by txn_id", this.txn_id, this.successCB, this.errorCB);
    }
  }

  // fetchConfirmedOrderTxnbyPhoteamId() {
  //   this.adminDataService.getConfirmedOrderTxnbyPhoteam("fetch confirmed order list by photeam_id", this.photeam_id, this.successCB, this.errorCB);
  // }

  // fetchProductsbyPhoteamId() {
  //   this.adminDataService.getAllProductsbyPhoteam("fetch products list by photeam_id", this.photeam_id, this.successCB, this.errorCB);
  // }

  viewJSON(data) {
    this.view_json = data;
    this.openModal()
  }

  openModal() {
    this.modalRef = this.modalService.open(this.json_modal, {
      centered: true, size: 'lg'

    });
  }

  onModalClose() {
    this.modalRef.close();
  }

  successCB(results: any) {

    if (results.api == "fetch data by txn_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('data', results.data)
        that.data = results.data;
      }
    }

    if (results.api == "fetch confirmed order list by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('confirmed_order_list', results.data)
        that.confirmed_order_list = results.data;
      }
    }

    if (results.api == "fetch products list by photeam_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('products_list', results.data)
        that.products_list = results.data;
      }
    }

  }

  errorCB(error: any) {
    console.log(error);
  }
}
