<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">User Details</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/{{routesPath.USER_DETAILS}}">Users</a></li>
      <li class="breadcrumb-item active" aria-current="page">Users Profile</li>
    </ol>
  </nav>

  <div class="row">
    <!-- Content Column -->
    <div class="col-lg-8 mb-4">
      <!-- Approach -->
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">View Profile</h6>
        </div>
        <div class="card-body admin-form">
          <ng-container *ngIf="user != null && user != undefined">
            <div class="row">
              <div class="col-lg-4 col-12">
                <div class="form-group">
                  <label class="text-gray-900">Name</label>
                  <input
                    value="{{ user.name }}"
                    class="form-control form-control-admin"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="text-gray-900">Mobile</label>
                  <input
                    value="{{ user.mobile }}"
                    class="form-control form-control-admin"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="text-gray-900">Email</label>
                  <input
                    value="{{ user.email }}"
                    class="form-control form-control-admin"
                    readonly
                  />
                </div>
              </div>
            </div>
          </ng-container>

          <!-- PHOTOGRAPHER DETAILS -->
          <ng-container
            *ngIf="
              photographer_dtls != null &&
              photographer_dtls != undefined &&
              user.is_photographer == 1
            "
          >
            <hr />
            <div class="text-left">
              <h1 class="text-lg text-uppercase mb-4 font-weight-bold">
                PHOTOGRAPHER DETAILS
              </h1>
            </div>

            <div class="row">
              <div class="col-lg-5">
                <div class="form-group">
                  <label class="text-gray-900">Website</label>
                  <input
                    value="{{ photographer_dtls.website }}"
                    class="form-control form-control-admin"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-5">
                <div class="form-group">
                  <label class="text-gray-900">Total Experience</label>
                  <input
                    value="{{ photographer_dtls.total_experience }}"
                    class="form-control form-control-admin"
                    readonly
                  />
                </div>
              </div>
            </div>
          </ng-container>

          <!-- Awards -->
          <ng-container *ngIf="photographer_dtls?.awards.length > 0">
            <hr />
            <div>
              <h1 class="text-lg text-uppercase mb-4 font-weight-bold">
                Awards
              </h1>
            </div>

            <div class="row">
              <div
                class="col-lg-4 col-md-4 col-sm-6 my-2"
                *ngFor="let award of photographer_dtls.awards"
              >
                <div class="rounded border bg-gray-100 p-3">
                  <div class="text-truncate">
                    <span class="font-weight-bold" title="{{ award.name }}">{{
                      award.name
                    }}</span>
                    <br />
                    <span
                      class="text-gray-600"
                      title="{{ award.organisation }}"
                      >{{ award.organisation }}</span
                    >
                    <br />
                    <span
                      class="text-xs text-gray-500"
                      title="{{ award.date }}"
                      >{{ award.date }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- /Awards -->

          <!-- certifications -->
          <ng-container *ngIf="photographer_dtls?.certifications.length > 0">
            <hr />
            <div>
              <h6 class="text-lg text-uppercase mb-4 font-weight-bold">
                Certifications
              </h6>
            </div>

            <div class="row">
              <div
                class="col-lg-4 col-md-4 col-sm-6 my-2"
                *ngFor="let certificat of photographer_dtls.certifications"
              >
                <div class="rounded border bg-gray-100 p-3">
                  <div class="text-truncate">
                    <span
                      class="font-weight-bold"
                      title="{{ certificat.name }}"
                      >{{ certificat.name }}</span
                    >
                    <br />
                    <span class="text-gray-600" title="{{ certificat.name }}">{{
                      certificat.organisation
                    }}</span>
                    <br />
                    <span class="text-xs text-gray-500">{{
                      certificat.date
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- /certifications -->

          <!-- genres -->
          <ng-container *ngIf="photographer_dtls?.genres.length > 0">
            <hr />
            <div>
              <h1 class="text-lg text-uppercase mb-4 font-weight-bold">
                Genres
              </h1>
            </div>

            <div class="row">
              <div
                class="col-lg-2 col-md-2 col-sm-3 col-4 my-2"
                *ngFor="let genre of photographer_dtls.genres"
              >
                <div class="genric_card">
                  <img class="rounded" src="{{ genre.img_src }}" alt="" />
                  <h4 class="my-2 text-xs font-weight-bold">
                    {{ genre.genre_name }}
                    <br />
                    {{ genre.skill_level }}
                  </h4>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- /genres -->
        </div>
      </div>
    </div>

    <div class="col-lg-4 mb-4">
      <!-- user card -->
      <div class="card card-user shadow mb-4">
        <div class="card-body">
          <div class="row" *ngIf="user != null && user != undefined">
            <div class="col-lg-12">
              <div class="p-5">
                <!-- <div class="image">
                  <img src="../assets/img/bg5.jpg" alt="...">
                </div> -->

                <div class="text-center">
                  <img
                    class="avatar border border-primary"
                    [src]="
                      user.avatar
                        ? storageURL +
                          '/user/' +
                          user.id +
                          '/avatar/' +
                          user.avatar
                        : 'assets/img/pickle_logo.jpg'
                    "
                  />

                  <h1 class="title text-primary h4 text-gray-900 mb-4">
                    {{ user.name }}
                  </h1>
                  <span class="text-xs text-gray-600">#{{ user.id }}</span>
                  <hr />
                  <p class="text-truncate">
                    <span>{{ user.mobile }}</span> <br />
                    <span title="{{ user.email }}">{{ user.email }}</span>
                  </p>
                </div>
                <p class="description text-center">
                  {{ user.created_at | date: "MMMM d, y, h:mm:ss a" }}
                </p>
                <div class="text-center">
                  <button
                    class="btn btn-primary btn-round"
                    (click)="getToken()"
                  >
                    Get Token
                  </button>
                </div>

                <p class="description text-center" *ngIf="user_acces_token">
                  {{user_acces_token}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="d-sm-flex align-items-center border-bottom-primary justify-content-between mb-4"
  >
    <h1 class="h3 mb-0 text-gray-800">Photeam Details</h1>
    <!-- <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
          class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> -->
  </div>

  <div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                  >
                    Created
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ photeamData?.created.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12" *ngIf="photeamData?.created.length > 0">
          <div
            class="card mt-4 table-responsive custom-scroll"
            style="overflow: auto; max-height: 400px"
          >
            <table class="table" id="dataTable" width="100%" cellspacing="0">
              <tbody>
                <ng-container *ngFor="let item of photeamData.created">
                  <tr>
                    <td>{{ item.photeam_user_relation.photeam_name }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-success text-uppercase mb-1"
                  >
                    Joined
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ photeamData?.member.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12" *ngIf="photeamData?.member.length > 0">
          <div
            class="card mt-4 table-responsive custom-scroll"
            style="overflow: auto; max-height: 400px"
          >
            <table class="table" id="dataTable" width="100%" cellspacing="0">
              <tbody>
                <ng-container *ngFor="let item of photeamData.member">
                  <tr>
                    <td>{{ item.photeam_user_relation.photeam_name }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    Member
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {{ photeamData?.member.length }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12" *ngIf="photeamData?.member.length > 0">
          <div
            class="card mt-4 table-responsive custom-scroll"
            style="overflow: auto; max-height: 400px"
          >
            <table class="table" id="dataTable" width="100%" cellspacing="0">
              <tbody>
                <ng-container *ngFor="let item of photeamData.member">
                  <tr>
                    <td>{{ item.photeam_user_relation.photeam_name }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="card border-left-warning shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                  >
                    Editor
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ photeamData?.editor.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12" *ngIf="photeamData?.editor.length > 0">
          <div
            class="card mt-4 table-responsive custom-scroll"
            style="overflow: auto; max-height: 400px"
          >
            <table class="table" id="dataTable" width="100%" cellspacing="0">
              <tbody>
                <ng-container *ngFor="let item of photeamData.editor">
                  <tr>
                    <td>{{ item.photeam_user_relation.photeam_name }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- photeam vs user relation -->
  <div class="card shadow mb-4">
    <!-- Card Header - Accordion -->
    <a
      href="#collapseCardExample"
      class="d-block card-header py-3"
      data-toggle="collapse"
      role="button"
      aria-expanded="true"
      aria-controls="collapseCardExample"
    >
      <h6 class="m-0 font-weight-bold text-primary">
        Photeam Vs Users Relations
      </h6>
    </a>
    <!-- Card Content - Collapse -->
    <div class="collapse hide" id="collapseCardExample">
      <div class="card-body">
        <div class="table-responsive" *ngIf="photeamData?.all?.length > 0">
          <table
            datatable
            [dtOptions]="dtOptions"
            id="myTable"
            class="table"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <!-- <th>ID</th> -->
                <th>photeam_id</th>
                <th>photeam_name</th>
                <!-- <th>user_id</th> -->
                <!-- <th>name</th> -->
                <!-- <th>email</th> -->
                <!-- <th>mobile</th> -->
                <th>role</th>
                <th>invite_count</th>
                <th>join_type</th>
                <th>is_verified</th>
                <th>is_deleted</th>
                <!-- <th>deleted_at</th> -->
                <th>created_at</th>
                <!-- <th>updated_at</th> -->
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of photeamData.all">
                <!-- <td class="cursor-pointer text-warning" routerLink="{{item.id}}">{{item.id}}</td> -->
                <td>{{ item.photeam_id }}</td>
                <td
                  class="cursor-pointer text-warning"
                  routerLink="/{{routesPath.PHOTEAM_DETAILS}}/{{
                    item.photeam_user_relation.photeam_id
                  }}"
                >
                  {{ item.photeam_user_relation.photeam_name }}
                </td>
                <!-- <td>{{item.user_id}}</td> -->
                <!-- <td>{{item.name}}</td> -->
                <!-- <td>{{item.email}}</td> -->
                <!-- <td>{{item.mobile}}</td> -->
                <td>{{ item.role }}</td>
                <td>{{ item.invite_count }}</td>
                <td *ngIf="item.join_type == 'join_type_0'">
                  invited by admin
                </td>
                <td *ngIf="item.join_type == 'join_type_1'">request to join</td>
                <td *ngIf="item.join_type == 'join_type_2'">Self Created</td>

                <td>{{ item.is_verified }}</td>
                <td>{{ item.is_deleted }}</td>
                <!-- <td>{{item.deleted_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
                <td>{{ item.created_at | date: "MMMM d, y, h:mm:ss a" }}</td>
                <!-- <td>{{item.updated_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- kyc -->
  <div class="card shadow mb-4">
    <!-- Card Header - Accordion -->
    <a
      href="#collapseCardKyc"
      class="d-block card-header py-3"
      data-toggle="collapse"
      role="button"
      aria-expanded="true"
      aria-controls="collapseCardExample"
    >
      <h6 class="m-0 font-weight-bold text-primary">KYC details</h6>
    </a>
    <!-- Card Content - Collapse -->
    <!-- {{kyc_data|json}} -->
    <div class="collapse hide" id="collapseCardKyc" *ngIf="kyc_data">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <h3>Aadhar No.</h3>

            <div class="text-center">
              <h4>{{ kyc_data.aadhar_no }}</h4>
            </div>

            <div>
              <img
                class="card-img img-fluid genre_img border"
                width="100%"
                [src]="
                  storageURL +
                  '/user/' +
                  user_id +
                  '/kyc/' +
                  kyc_data.aadhar_front_img
                "
              />
            </div>
          </div>
          <div class="col-lg-6">
            <h3>PAN No.</h3>

            <div class="text-center">
              <h4>{{ kyc_data.pan_no }}</h4>
            </div>
            <div>
              <img
                class="card-img img-fluid genre_img border"
                width="100%"
                [src]="
                  storageURL + '/user/' + user_id + '/kyc/' + kyc_data.pan_img
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- quoations -->
  <div class="card shadow mb-4">
    <!-- Card Header - Accordion -->
    <a
      href="#collapseCardQuotation"
      class="d-block card-header py-3"
      data-toggle="collapse"
      role="button"
      aria-expanded="true"
      aria-controls="collapseCardQuotation"
    >
      <h6 class="m-0 font-weight-bold text-primary">Invoice & Quotation</h6>
    </a>
    <!-- Card Content - Collapse -->
    <div class="collapse show" id="collapseCardQuotation">
      <div class="card-body">
        <div class="table-responsive" *ngIf="quotationData?.length > 0">
          <table
            datatable
            [dtOptions]="dtOptions"
            id="myTable"
            class="table"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>serial_no</th>
                <th>title</th>
                <th>date</th>
                <th>client_name</th>
                <th>cost_total</th>
                <th>type</th>
                <th>deleted_at</th>
                <th>created_at</th>
                <th>updated_at</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of quotationData">
                <td
                  class="cursor-pointer text-warning"
                  routerLink="/quotations-invoice/{{ item.bill_id }}"
                >
                  {{ item.bill_id }}
                </td>
                <td>{{ item.bill_data.header.serial_no }}</td>
                <td>{{ item.bill_data.header.title }}</td>
                <td>{{ item.bill_data.header.date }}</td>

                <td>{{ item.bill_data.client_details.clt_name }}</td>
                <td>{{ item.bill_data.cost.total }}</td>
                <td>{{ item.type }}</td>

                <td [title]="item.deleted_at | date: 'd-MMM-yy, h:mm:ss a'">
                  {{ item.deleted_at | date: "d-MMM-yy" }}
                </td>
                <td [title]="item.created_at | date: 'd-MMM-yy, h:mm:ss a'">
                  {{ item.created_at | date: "d-MMM-yy" }}
                </td>
                <td [title]="item.updated_at | date: 'd-MMM-yy, h:mm:ss a'">
                  {{ item.updated_at | date: "d-MMM-yy" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
