<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Photeam Details</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/{{ routesPath.PHOTEAMS }}">Photeams</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/{{ routesPath.PHOTEAM_OVERVIEW }}/{{photeam_id}}">Photeam Overview</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Photeam Details
      </li>
    </ol>
  </nav>

  <!-- Page Content -->
  <div>
    <!-- DP & Bio -->
    <div class="card shadow mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <div
              class="m-auto mt-4"
              style="
                width: 250px;
                height: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                class="profile-picture rounded w-100 img-fluid"
                [src]="url_dp != null ? url_dp : 'assets/img/pickle_logo.jpg'"
              />
            </div>

            <div class="h4 mt-3 text-center font-weight-bold text-gray-800">
              {{ photeam_dtls?.photeam.photeam_name }}
            </div>
            <div
              class="h6 my-1 text-center font-weight-bold text-gray-600"
              *ngIf="photeam_dtls?.photeam.is_completed == 1"
            >
              <a
                target="_blank"
                href="https://pickleit.in/studio/{{
                  photeam_dtls?.photeam.photeam_username
                }}"
              >
                @{{ photeam_dtls?.photeam.photeam_username }}
              </a>
            </div>
            <div class="text-xs text-center font-weight-bold text-gray-600">
              {{
                photeam_dtls?.photeam.created_at | date: "MMMM d, y, h:mm:ss a"
              }}
            </div>
            <div class="text-xs text-center font-weight-bold text-gray-500">
              Photeam_id - {{ photeam_dtls?.photeam.photeam_id }}
            </div>
            <div class="text-xs text-center font-weight-bold text-gray-500">
              Created_by - {{ photeam_dtls?.photeam.created_by }}
            </div>

            <div
              *ngIf="photeam_dtls?.photeam.is_deleted == 0"
              class="progresses my-4 align-items-center"
            >
              <div class="d-flex align-items-center">
                <div class="steps step-completed" style="position: relative">
                  <span><i class="fa fa-check"></i></span>
                  <span class="text-dark" style="position: absolute; top: 40px"
                    >Draft</span
                  >
                </div>

                <span
                  class="line"
                  [ngClass]="
                    photeam_dtls?.photeam.is_completed == 1
                      ? 'completed-line'
                      : 'incompleted-line'
                  "
                ></span>
              </div>

              <div class="d-flex align-items-center">
                <div
                  class="steps"
                  [ngClass]="
                    photeam_dtls?.photeam.is_completed == 1
                      ? 'step-completed'
                      : 'step-incompleted'
                  "
                  style="position: relative"
                >
                  <span><i class="fa fa-check"></i></span>
                  <span class="text-dark" style="position: absolute; top: 40px"
                    >Completed</span
                  >
                </div>

                <span
                  class="line"
                  [ngClass]="
                    photeam_dtls?.photeam.is_verified == 1
                      ? 'completed-line'
                      : 'incompleted-line'
                  "
                ></span>
              </div>

              <div class="d-flex align-items-center">
                <div
                  class="steps"
                  [ngClass]="
                    photeam_dtls?.photeam.is_verified == 1
                      ? 'step-completed'
                      : 'step-incompleted'
                  "
                  style="position: relative"
                >
                  <span><i class="fa fa-check"></i></span>
                  <span class="text-dark" style="position: absolute; top: 40px"
                    >Verified</span
                  >
                </div>

                <span
                  class="line"
                  [ngClass]="
                    photeam_dtls?.photeam.is_public == 1
                      ? 'completed-line'
                      : 'incompleted-line'
                  "
                ></span>
              </div>

              <div class="d-flex align-items-center">
                <div
                  class="steps"
                  [ngClass]="
                    photeam_dtls?.photeam.is_public == 1
                      ? 'step-completed'
                      : 'step-incompleted'
                  "
                  style="position: relative"
                >
                  <!-- <span class="font-weight-bold">3</span> -->
                  <span><i class="fa fa-check"></i></span>
                  <span class="text-dark" style="position: absolute; top: 40px"
                    >Public</span
                  >
                </div>
              </div>
            </div>

            <a
              *ngIf="photeam_dtls?.photeam.is_deleted == 1"
              class="btn btn-danger btn-icon-split my-4"
            >
              <span class="icon text-white-50">
                <i class="fas fa-trash"></i>
              </span>
              <span class="text"
                >{{
                  photeam_dtls?.photeam.deleted_at
                    | date: "MMMM d, y, h:mm:ss a"
                }}
                June 20, 2021, 12:16:48 PM</span
              >
            </a>
          </div>
          <div class="col-lg-8">
            <div class="form-group">
              <span class="m-0 font-weight-bold text-dark">About</span>
              <!-- <ckeditor data="<p>Hello, world!</p>"></ckeditor> -->
              <textarea
                class="form-control"
                [(ngModel)]="photeam_dtls?.photeam.photeam_description"
                name="photeam_description"
                rows="15"
              ></textarea>
            </div>
            <button
              type="button"
              class="btn btn-primary mt-2 float-right"
              (click)="update_description()"
            >
              Update Description
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Details -->
    <div class="card shadow mb-4">
      <!-- Card Header - Accordion -->
      <a
        href="#DetailscollapseCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">Details</h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse show" id="DetailscollapseCard">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 my-2">
              <div class="card-header py-3 border-bottom">
                <h6 class="m-0 font-weight-bold text-primary text-uppercase">
                  Location
                </h6>
              </div>

              <div
                class="table-responsive"
                *ngIf="
                  photeam_dtls?.photeam.photeam_location != null &&
                  photeam_dtls?.photeam.photeam_location != undefined
                "
              >
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr>
                      <th>Country</th>
                      <td>
                        {{ photeam_dtls.photeam.photeam_location.country }}
                      </td>
                    </tr>
                    <tr>
                      <th>State</th>
                      <td>{{ photeam_dtls.photeam.photeam_location.state }}</td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{{ photeam_dtls.photeam.photeam_location.city }}</td>
                    </tr>
                    <tr>
                      <th>Area</th>
                      <td>{{ photeam_dtls.photeam.photeam_location.area }}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>
                        {{ photeam_dtls.photeam.photeam_location.address }}
                      </td>
                    </tr>
                    <tr>
                      <th>Zip Code</th>
                      <td>
                        {{ photeam_dtls.photeam.photeam_location.zip_code }}
                      </td>
                    </tr>
                    <tr>
                      <th>GST_no</th>
                      <td>
                        {{ photeam_dtls.photeam.photeam_location.GST_no }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-4 my-2">
              <div class="card-header py-3 border-bottom">
                <h6 class="m-0 font-weight-bold text-primary text-uppercase">
                  SOCIAL MEDIA
                </h6>
              </div>

              <div
                class="table-responsive custom-scroll"
                *ngIf="
                  photeam_dtls?.photeam.photeam_social_media != null &&
                  photeam_dtls?.photeam.photeam_social_media != undefined
                "
              >
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr>
                      <th>Website</th>
                      <td>
                        <a href=""></a>

                        {{ photeam_dtls.photeam.photeam_social_media.website }}
                      </td>
                    </tr>
                    <tr>
                      <th>Instagaram</th>
                      <td>
                        <a
                          target="blank"
                          href="{{
                            photeam_dtls.photeam.photeam_social_media
                              .instagram_handle
                          }}"
                          >{{
                            photeam_dtls.photeam.photeam_social_media
                              .instagram_handle
                          }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Facebook</th>
                      <td>
                        <a
                          target="blank"
                          href="{{
                            photeam_dtls.photeam.photeam_social_media
                              .facebook_handle
                          }}"
                          >{{
                            photeam_dtls.photeam.photeam_social_media
                              .facebook_handle
                          }}</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-4 my-2">
              <div class="card-header py-3 border-bottom">
                <h6 class="m-0 font-weight-bold text-primary text-uppercase">
                  TABS STATUS
                </h6>
              </div>

              <div
                class="table-responsive"
                *ngIf="
                  photeam_dtls?.photeam.tabs_status != null &&
                  photeam_dtls?.photeam.tabs_status != undefined
                "
              >
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr>
                      <th>General</th>
                      <td>
                        <span
                          *ngIf="
                            photeam_dtls.photeam.tabs_status.general == 1;
                            else elseBlock
                          "
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-warning btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>
                    <tr>
                      <th>Services</th>
                      <td>
                        <span
                          *ngIf="
                            photeam_dtls.photeam.tabs_status.services == 1;
                            else elseBlock
                          "
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-warning btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>

                    <tr>
                      <th>Packages</th>
                      <td>
                        <span
                          *ngIf="
                            photeam_dtls.photeam.tabs_status.packages == 1;
                            else elseBlock
                          "
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-warning btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>

                    <tr
                      *ngIf="photeam_dtls.photeam.tabs_status.faq != undefined"
                    >
                      <th>Faqs</th>
                      <td>
                        <span
                          *ngIf="
                            photeam_dtls.photeam.tabs_status.faq == 1;
                            else elseBlock
                          "
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-warning btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>

                    <tr>
                      <th>Members</th>
                      <td>
                        <span
                          *ngIf="
                            photeam_dtls.photeam.tabs_status.members == 1;
                            else elseBlock
                          "
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-warning btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>

                    <tr>
                      <th>Photos</th>
                      <td>
                        <span
                          *ngIf="
                            photeam_dtls.photeam.tabs_status.photos == 1;
                            else elseBlock
                          "
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-warning btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>

                    <tr>
                      <th>Accounts</th>
                      <td>
                        <span
                          *ngIf="
                            photeam_dtls.photeam.tabs_status.accounts == 1;
                            else elseBlock
                          "
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-warning btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sevices -->
    <div class="card shadow mb-4">
      <!-- Card Header - Accordion -->
      <a
        href="#ServicescollapseCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">
          Services
          <span
            *ngIf="
              photeam_dtls?.photeam.tabs_status.services == 1;
              else elseBlock
            "
            class="ml-2 btn-success btn-circle rounded-circle btn-sm"
          >
            <i class="fas fa-check text-white"></i>
          </span>

          <ng-template #elseBlock>
            <span class="ml-2 btn-warning btn-circle rounded-circle btn-sm">
              <i class="fas fa-exclamation-triangle text-white"></i>
            </span>
          </ng-template>
        </h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse show" id="ServicescollapseCard">
        <div class="card-body" *ngIf="photeam_dtls?.photeam_service">
          <div class="row">
            <div class="col-lg-4 my-2">
              <div class="card-header py-3 border-bottom">
                <h6 class="m-0 font-weight-bold text-primary text-uppercase">
                  GENRES
                </h6>
              </div>

              <div
                class="table-responsive custom-scroll"
                style="max-height: 400px"
                *ngIf="photeam_dtls?.photeam_service.genres.length > 0"
              >
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr
                      *ngFor="let genre of photeam_dtls.photeam_service.genres"
                    >
                      <th>{{ genre.genre_name }}</th>
                      <td>{{ genre.genre_id }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-4 my-2">
              <div class="card-header py-3 border-bottom">
                <h6 class="m-0 font-weight-bold text-primary text-uppercase">
                  SERVICES
                </h6>
              </div>

              <div
                class="table-responsive custom-scroll"
                style="max-height: 400px"
                *ngIf="photeam_dtls?.photeam_service.services.length > 0"
              >
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr
                      *ngFor="
                        let service of photeam_dtls.photeam_service.services
                      "
                    >
                      <th>{{ service.service_name }}</th>
                      <td>{{ service.service_id }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-4 my-2">
              <div class="card-header py-3 border-bottom">
                <h6 class="m-0 font-weight-bold text-primary text-uppercase">
                  LOCATION
                </h6>
              </div>

              <div
                class="table-responsive custom-scroll"
                style="max-height: 400px"
                *ngIf="photeam_dtls?.photeam_service.services.length > 0"
              >
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr
                      *ngFor="
                        let location of photeam_dtls.photeam_service
                          .services_location
                      "
                    >
                      <th>{{ location.id }}</th>
                      <td>{{ location.name }}</td>
                      <td>{{ location.state }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- images -->
    <div class="card shadow mb-4">
      <!-- Card Header - Accordion -->
      <a
        href="#PortfoliocollapseCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">
          Portfolio
          <span
            *ngIf="
              photeam_dtls?.photeam.tabs_status.photos == 1;
              else elseBlock
            "
            class="ml-2 btn-success btn-circle rounded-circle btn-sm"
          >
            <i class="fas fa-check text-white"></i>
          </span>

          -- Super ({{ super_highlight_image }})

          <ng-template #elseBlock>
            <span class="ml-2 btn-warning btn-circle rounded-circle btn-sm">
              <i class="fas fa-exclamation-triangle text-white"></i>
            </span>
          </ng-template>
        </h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse hide" id="PortfoliocollapseCard">
        <div
          class="card-body custom-scroll"
          *ngIf="photeam_dtls?.photeam_portfolio_images.length > 0"
          style="max-height: 600px; overflow: auto"
        >
          <!-- filter buttons -->
          <!-- <div *ngIf="photeam_dtls?.photeam_service.genres.length > 0">
          <button
            *ngFor="let genre of photeam_dtls.photeam_service.genres"
            [ngClass]="{
              active_genre: genre.genre_name == selected_genre_name
            }"
            type="button"
            class="btn btn-outline-danger mr-1"
            (click)="selectedGenre(genre.genre_id, genre.genre_name)"
          >
            {{ genre.genre_name }}
          </button>
        </div> -->

          <mat-chip-list aria-label="Fish selection">
            <mat-chip
              color="accent"
              [selected]="selected_genre == 'all'"
              (click)="selected_genre = 'all'"
            >
              All ({{ photeam_dtls.photeam_portfolio_images.length }})</mat-chip
            >

            <mat-chip
              color="accent"
              [selected]="genre.genre_name == selected_genre_name"
              *ngFor="let genre of photeam_dtls.photeam_service.genres"
              (click)="selectedGenre(genre.genre_id, genre.genre_name)"
              >{{ genre.genre_name }} ({{
                genre_img_count[genre.genre_id].img_count
              }}) - Fav ({{
                genre_img_count[genre.genre_id].highlighted_count
              }})</mat-chip
            >
          </mat-chip-list>
          <hr />

          <!-- <div class="container">
            <div class="row"> -->
          <div class="card-columns">
            <ng-container
              *ngFor="
                let image of photeam_dtls.photeam_portfolio_images;
                index as i
              "
              class=""
            >
              <div
                class="card genre_img_tile position-relative"
                *ngIf="selected_genre == 'all'"
              >
                <img
                  class="card-img img-fluid genre_img"
                  width="100%"
                  [src]="
                    storageURL +
                    '/photeam/' +
                    image.photeam_id +
                    '/portfolio/' +
                    image.genre_id +
                    '/' +
                    image.file
                  "
                  (click)="
                    open_img_model(
                      view_img_model,
                      storageURL +
                        '/photeam/' +
                        image.photeam_id +
                        '/portfolio/' +
                        image.genre_id +
                        '/' +
                        image.file
                    )
                  "
                />

                <span class="highlighted_img" *ngIf="image.is_highlighted == 1">
                  Highlighted
                </span>

                <span
                  class="super_highlighted_img"
                  *ngIf="image.is_super_highlighted == 1"
                >
                  Super
                </span>

                <div class="action_div">
                  <span
                    class="btn-circle rounded-circle btn-sm mat_icon_div mx-2"
                    (click)="img_delete(image.id)"
                  >
                    <span class="material-icons mat_icon"> delete </span>
                  </span>

                  <span
                    class="btn-circle rounded-circle btn-sm mat_icon_div mx-2"
                    (click)="highlightImage(image)"
                  >
                    <span class="material-icons mat_icon"> star </span>
                  </span>

                  <span
                    class="btn-circle rounded-circle btn-sm mat_icon_div mx-2"
                    (click)="superhighlightImage(image)"
                  >
                    <span class="material-icons mat_icon"> flash_on </span>
                  </span>
                </div>
              </div>

              <div
                class="card genre_img_tile position-relative"
                *ngIf="selected_genre == image.genre_id"
              >
                <img
                  class="card-img img-fluid genre_img"
                  width="100%"
                  [src]="
                    storageURL +
                    '/photeam/' +
                    image.photeam_id +
                    '/portfolio/' +
                    image.genre_id +
                    '/' +
                    image.file
                  "
                  (click)="
                    open_img_model(
                      view_img_model,
                      storageURL +
                        '/photeam/' +
                        image.photeam_id +
                        '/portfolio/' +
                        image.genre_id +
                        '/' +
                        image.file
                    )
                  "
                />

                <span class="highlighted_img" *ngIf="image.is_highlighted == 1">
                  Highlighted
                </span>

                <span
                  class="super_highlighted_img"
                  *ngIf="image.is_super_highlighted == 1"
                >
                  Super
                </span>

                <div class="action_div">
                  <span
                    class="btn-circle rounded-circle btn-sm mat_icon_div mx-2"
                    (click)="img_delete(image.id)"
                  >
                    <span class="material-icons mat_icon"> delete </span>
                  </span>

                  <span
                    class="btn-circle rounded-circle btn-sm mat_icon_div mx-2"
                    (click)="highlightImage(image)"
                  >
                    <span class="material-icons mat_icon"> star </span>
                  </span>

                  <span
                    class="btn-circle rounded-circle btn-sm mat_icon_div mx-2"
                    (click)="superhighlightImage(image)"
                  >
                    <span class="material-icons mat_icon"> flash_on </span>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- </div>
          </div> -->
        </div>
      </div>
    </div>

    <ng-template #view_img_model let-modal>
      <div class="modal-header justify-content-end">
        <span
          class="btn-circle rounded-circle btn-sm cursor-pointer"
          (click)="modal.dismiss('Cross click')"
        >
          <span class="material-icons mat_icon"> close </span>
        </span>
      </div>
      <img [src]="view_portfolio_img" alt="" />
    </ng-template>

    <!-- Members -->
    <div class="card shadow mb-4">
      <!-- Card Header - Accordion -->
      <a
        href="#MemberscollapseCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">
          Members
          <span
            *ngIf="
              photeam_dtls?.photeam.tabs_status.members == 1;
              else elseBlock
            "
            class="ml-2 btn-success btn-circle rounded-circle btn-sm"
          >
            <i class="fas fa-check text-white"></i>
          </span>

          <ng-template #elseBlock>
            <span class="ml-2 btn-warning btn-circle rounded-circle btn-sm">
              <i class="fas fa-exclamation-triangle text-white"></i>
            </span>
          </ng-template>
        </h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse hide" id="MemberscollapseCard">
        <div
          class="card-body custom-scroll"
          *ngIf="photeam_dtls?.photeam_members.length > 0"
          style="max-height: 400px; overflow: auto"
        >
          <div class="row">
            <div
              class="col-xl-3 col-md-6 mb-4"
              *ngFor="let member of photeam_dtls.photeam_members"
            >
              <div
                class="card cursor-pointer border-left-info shadow h-100 py-2"
                routerLink="/{{ routesPath.USER_DETAILS }}/{{
                  member.member_user_id
                }}"
              >
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="mb-0 font-weight-bold text-gray-800">
                        Name
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="text-gray-800 text-uppercase">{{
                        member.name
                      }}</span>
                    </div>
                  </div>

                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="mb-0 font-weight-bold text-gray-800">
                        Role
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="text-gray-800 text-uppercase">{{
                        member.role
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Packages -->
    <div class="card shadow mb-4">
      <!-- Card Header - Accordion -->
      <a
        href="#PackagescollapseCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">
          Packages
          <span
            *ngIf="
              photeam_dtls?.photeam.tabs_status.packages == 1;
              else elseBlock
            "
            class="ml-2 btn-success btn-circle rounded-circle btn-sm"
          >
            <i class="fas fa-check text-white"></i>
          </span>

          <ng-template #elseBlock>
            <span class="ml-2 btn-warning btn-circle rounded-circle btn-sm">
              <i class="fas fa-exclamation-triangle text-white"></i>
            </span>
          </ng-template>
        </h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse show" id="PackagescollapseCard">
        <div
          class="card-body custom-scroll"
          *ngIf="photeam_dtls?.photeam_packages.length > 0"
        >
          <div
            class="row custom-scroll"
            style="flex-wrap: nowrap; overflow: auto"
          >
            <div
              class="col-xl-4 col-md-6 col-sm-12 col-12 mb-4"
              *ngFor="let package of photeam_dtls.photeam_packages"
            >
              <div class="card shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div
                        class="text-lg font-weight-bold text-primary text-uppercase mb-1"
                      >
                        {{ package.pkg_name }}
                      </div>
                      <div class="h4 mb-0 font-weight-bold text-gray-800">
                        <span
                          >Rs.
                          {{
                            package.cost.final_price.toLocaleString("en-IN")
                          }}</span
                        >
                        <span
                          *ngIf="
                            package.cost.final_price != package.cost.final_price
                          "
                          style="text-decoration: line-through"
                          class="ptc16 ml-3 ph5-bold"
                          >{{
                            package.cost.base_price.toLocaleString("en-IN")
                          }}</span
                        >
                      </div>
                      <div class="text-xs font-weight-bold text-gray-500 mb-1">
                        {{ package.id }}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div
                    class="custom-scroll pr-2"
                    style="max-height: 400px; overflow: auto"
                  >
                    <ul style="padding-left: 18px">
                      <ng-container *ngFor="let item of package.pkg_items">
                        <li *ngIf="item.type == 'services'">
                          <div class="my-3">
                            <div class="justify-content-between d-flex">
                              <div class="ph5-semibold ptc1">
                                {{ item.name }}
                              </div>
                              <div>
                                <span class="ph5-bold ptc1">{{
                                  item.value
                                }}</span>
                                <span class="ph8 ptc5 ml-2">Sessions</span>
                              </div>
                            </div>
                            <div
                              class="ph7 ptc5 mt-1"
                              *ngIf="
                                item.desc != undefined && item.desc != null
                              "
                              style="max-width: 250px"
                            >
                              {{ item.desc }}
                            </div>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                    <hr />
                    <ul style="padding-left: 18px">
                      <ng-container *ngFor="let item of package.pkg_items">
                        <li *ngIf="item.type == 'deliverables'">
                          <div class="my-3">
                            <div class="justify-content-between d-flex">
                              <div class="ph5-semibold ptc1">
                                {{ item.name }}
                              </div>
                              <div>
                                <span class="ph5-bold ptc1">{{
                                  item.value
                                }}</span>
                                <span class="ph8 ptc5 ml-2">Units</span>
                              </div>
                            </div>
                            <div
                              class="ph7 ptc5 mt-1"
                              *ngIf="
                                item.desc != undefined && item.desc != null
                              "
                              style="max-width: 250px"
                            >
                              {{ item.desc }}
                            </div>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                  <hr />
                  <div class="text-xs font-weight-bold text-gray-500 mb-1">
                    created_at:
                    {{ package.created_at | date: "MMMM d, y,  h:mm:ss a" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Account -->
    <div class="card shadow mb-4">
      <!-- Card Header - Accordion -->
      <a
        href="#AccountcollapseCard"
        class="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="true"
        aria-controls="collapseCardExample"
      >
        <h6 class="m-0 font-weight-bold text-primary">
          Account
          <span
            *ngIf="
              photeam_dtls?.photeam.tabs_status.accounts == 1;
              else elseBlock
            "
            class="ml-2 btn-success btn-circle rounded-circle btn-sm"
          >
            <i class="fas fa-check text-white"></i>
          </span>

          <ng-template #elseBlock>
            <span class="ml-2 btn-warning btn-circle rounded-circle btn-sm">
              <i class="fas fa-exclamation-triangle text-white"></i>
            </span>
          </ng-template>
        </h6>
      </a>
      <!-- Card Content - Collapse -->
      <div class="collapse hide" id="AccountcollapseCard">
        <div
          class="card-body"
          *ngIf="photeam_bank != null && photeam_bank != undefined"
        >
          <div class="row">
            <div class="col-lg-6 my-2">
              <div class="card-header py-3 border-bottom">
                <h6 class="m-0 font-weight-bold text-primary text-uppercase">
                  Bank Deatils
                </h6>
              </div>

              <div
                class="table-responsive custom-scroll"
                *ngIf="photeam_dtls?.photeam_service.genres.length > 0"
              >
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr>
                      <th>Account Holder Name</th>
                      <td>{{ photeam_bank.account_holder_name }}</td>
                    </tr>
                    <tr>
                      <th>Account No.</th>
                      <td>{{ photeam_bank.account_number }}</td>
                    </tr>
                    <tr>
                      <th>Bank Name</th>
                      <td>{{ photeam_bank.bank_name }}</td>
                    </tr>
                    <tr>
                      <th>Branch City</th>
                      <td>{{ photeam_bank.branch_city }}</td>
                    </tr>
                    <tr>
                      <th>Branch Name</th>
                      <td>{{ photeam_bank.branch_name }}</td>
                    </tr>
                    <tr>
                      <th>IFSC</th>
                      <td>{{ photeam_bank.ifsc_code }}</td>
                    </tr>
                    <tr>
                      <th>UPI</th>
                      <td>{{ photeam_bank.bank_upi }}</td>
                    </tr>
                    <tr>
                      <th>PAN</th>
                      <td>{{ photeam_bank.photeam_pan }}</td>
                    </tr>
                    <tr>
                      <th>Img</th>
                      <td>{{ photeam_bank.photeam_account_img }}</td>
                    </tr>
                    <tr>
                      <th>is_verified</th>
                      <td>
                        <!-- {{photeam_bank.is_verified==1?"verified":"unverified"}} -->
                        <mat-slide-toggle
                          class="mr-2"
                          [(ngModel)]="photeam_bank.is_verified"
                          (change)="
                            toggle_state(
                              photeam_bank.photeam_id,
                              photeam_bank.is_verified,
                              'is_bank_verified',
                              $event
                            )
                          "
                        >
                        </mat-slide-toggle>

                        <span
                          *ngIf="photeam_bank.is_verified == 1; else elseBlock"
                          class="btn-success btn-circle rounded-circle btn-sm"
                        >
                          <i class="fas fa-check text-white"></i>
                        </span>

                        <ng-template #elseBlock>
                          <span
                            class="btn-danger btn-circle rounded-circle btn-sm"
                          >
                            <i
                              class="fas fa-exclamation-triangle text-white"
                            ></i>
                          </span>
                        </ng-template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-6 my-2">
              <img
                class="card-img img-fluid genre_img"
                width="100%"
                [src]="
                  storageURL +
                  '/photeam/' +
                  photeam_id +
                  '/bank/' +
                  photeam_bank.photeam_account_img
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
