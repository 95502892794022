import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';

import Swal from 'sweetalert2';
import { GenricService } from 'src/app/data/services/genric.service';

var that: any;
@Component({
  selector: 'app-products-dashboard',
  templateUrl: './products-dashboard.component.html',
  styleUrls: ['./products-dashboard.component.css']
})
export class ProductsDashboardComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private modalService: NgbModal,
    private genricService: GenricService,
  ) { that = this; }

  @ViewChild('productModal', { static: true }) deleteItemModal: ElementRef;

  productsList: any;
  productsList_checkout: any;
  productsData: any;
  pricingOptionData: any;
  formType = '';
  modalRef: any;

  openModal(modal) {
    this.modalRef = this.modalService.open(modal, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  close_modal() {
    this.modalRef.close();
  }

  fetchProductList() {
    this.dataService.fetchProductList("fetch product list", this.successCB, this.errorCB);
  }

  fetchProductList_checkout() {
    this.dataService.fetchProductListV2("fetch product list checkout", 0, 0, this.successCB, this.errorCB);
  }

  product_categories: Array<any> = [
    { category: "PACKAGE", type: ["PREM"] },
    { category: "ADDONS", type: ["COIN", "FILE_TRANSFER_STORAGE", "BILLING_STORAGE"] },
  ]

  product_type: any[] = [];
  product_validity_type: any[] = ['years', 'months', 'weeks', 'days', 'hours', 'minutes'];

  changeCategory(event: any) {
    let category = event.target.value;
    this.product_type = this.product_categories.find(item => item.category == category).type;
    this.productsData.type = this.product_type[0]
  }

  toogle_validity_applicable(event: any) {
    if (!event.target.checked) {
      this.productsData.validity = null;
    } else {
      this.productsData.validity = {
        validity_type: "months",
        validity: 1
      }
    }
  }

  toogle_discount_applicable(event: any) {
    if (!event.target.checked) {
      this.productsData.discount = null;
    } else {
      this.productsData.discount = {
        discount_type: "percentage",
        value: 15
      }
    }
  }

  toogle_gst_applicable(event: any) {
    if (!event.target.checked) {
      this.productsData.gst = null;
    } else {
      this.productsData.gst = {
        gst_type: "percentage",
        value: 18
      }
    }
  }

  addProduct() {
    this.formType = "add"
    this.productsData = {
      product_id: this.genricService.get_uuid(),
      product_code: "",
      name: "",
      desc: "",
      purchased_count: null,
      max_uses_limit: null,
      max_uses_per_user: null,
      category: "",
      type: "",
      base_value: 0,
      discount: {
        discount_type: "percentage",
        value: 15
      },
      gst: {
        gst_type: "percentage",
        value: 18
      },
      final_value: 0,
      validity: {
        validity_type: "months",
        validity: 1
      },
      pricing_options: [],
      data: null,
      is_coupon_applicable: 0,
      is_active: 1,
      sort_order: 1,
      is_display_only: 1,
    }
  }

  editAction(item: any) {
    this.formType = "edit"

    let category = item.category;
    this.product_type = this.product_categories.find(item => item.category == category).type;

    this.productsData = JSON.parse(JSON.stringify(item));
    // console.log(this.productsData);
  }

  updateProduct() {

    if (this.productsData.product_code != "" && this.productsData.name != "" && this.productsData.base_value != "") {
      // console.log("new_values", this.languageData);
      Swal.fire({
        title: 'Are you sure?',
        // text: 'You will change the state!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {

          this.adminDataService.addProductData("update product data", this.productsData, this.successCB, this.errorCB);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )

          // console.log(e.source.checked );
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Some Feilds are emplty!',
        showConfirmButton: false,
        timer: 1500
      })
    }

  }

  selected_product = null
  addPricingOption(product) {
    this.selected_product = JSON.parse(JSON.stringify(product));
    this.formType = "add"
    this.pricingOptionData = {
      option_id: this.genricService.get_uuid(),
      option_name: "",
      base_price: 0,
      per_month_price: 0,
      discount_value: 0,
      discount_percentage: 0,
      gst_value: 0,
      gst_percetage: 0,
      validity_value: 1,
      validity_type: "months",
      total_price: 0,
      is_active: 1,
      sort_order: null,
    }
  }

  editPricingOption(product, option_id) {
    this.formType = "edit";
    this.selected_product = JSON.parse(JSON.stringify(product)); // Clone product to avoid direct mutations

    // Find the option with the given option_id in the product's pricing options
    const selected_option = this.selected_product.pricing_options.find(
      option => option.option_id === option_id
    );

    // Assign the found option to pricingOptionData if it exists
    this.pricingOptionData = selected_option ? selected_option : {};
  }

  updatePricingOption() {
    this.productsData = this.selected_product;

    // Find the index of the existing option with the same option_id
    const existingOptionIndex = this.productsData.pricing_options.findIndex(
      option => option.option_id === this.pricingOptionData.option_id
    );

    if (existingOptionIndex !== -1) {
      // Update the existing option
      this.productsData.pricing_options[existingOptionIndex] = this.pricingOptionData;
    } else {
      // Add the new option
      this.productsData.pricing_options.push(this.pricingOptionData);
    }

    this.updateProduct();
  }

  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.fetchProductList();
    this.fetchProductList_checkout();
    console.log(this.productsData);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      order: []
    };
  }


  successCB(results: any) {

    if (results.api == "fetch product list") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.productsList = results.data;
          console.log("productsList ", that.productsList);
        }
      }
    }

    if (results.api == "fetch product list checkout") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.productsList_checkout = results.data.products;
          console.log("productsList_checkout ", that.productsList_checkout);
        }
      }
    }

    if (results.api == "update product data") {
      if (results.servercode == ServerCode.SUCCESS) {

        Swal.fire({
          icon: 'success',
          title: 'Changes saved!',
          showConfirmButton: false,
          timer: 1500
        })

        that.close_modal()

        that.fetchProductList();
        that.fetchProductList_checkout();
      }
    }



  }

  errorCB(error: any) {
    console.log(error);
  }

}
