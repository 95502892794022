<div class="py-5">
    <app-bill-pdf-preview
      [bill_data]="bill_data"
    ></app-bill-pdf-preview>
    
  </div>
  
  <app-bill-pdf-download [bill_data]="bill_data">
  </app-bill-pdf-download>
  
  <div class="d-flex justify-content-center mb-4">
    <button (click)="onDownload()" type="button" class="btn btn-outline-primary">
      Download PDF
    </button>
  </div>
  