import { Component, OnInit } from '@angular/core';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
var that:any;

@Component({
  selector: 'app-master-constants-enum-lists',
  templateUrl: './master-constants-enum-lists.component.html',
  styleUrls: ['./master-constants-enum-lists.component.css']
})
export class MasterConstantsEnumListsComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private modalService: NgbModal
  ) { that = this; }

  ConstantsEnum:any;
  genreData: any;
  formType = '';

  open(content: any) {
    this.modalService.open(content);
  }

  fetchGenreList() {
    this.dataService.getConstantsEnum("fetch ConstantsEnum list","", this.successCB, this.errorCB);
  }


  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.fetchGenreList();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order: []
    };
  }

  successCB(results: any) {

    if (results.api == "fetch ConstantsEnum list") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.ConstantsEnum = results.data;
          console.log("ConstantsEnum", results.data);

        }
      }
    }

    // if (results.api == "update master genre data") {
    //   if (results.servercode == ServerCode.SUCCESS) {

    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Changes saved!',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })

    //     that.fetchGenreList();
    //   }
    // }


  }

  errorCB(error: any) {
    console.log(error);
  }

}
