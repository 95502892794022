import { Component, OnInit } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';

import Swal from 'sweetalert2';
import { GenricService } from 'src/app/data/services/genric.service';
import { ToastrService } from 'ngx-toastr';

var that: any;
@Component({
  selector: 'app-coupons-dashboard',
  templateUrl: './coupons-dashboard.component.html',
  styleUrls: ['./coupons-dashboard.component.css']
})
export class CouponsDashboardComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private modalService: NgbModal,
    public genricService: GenricService,
    private toastr: ToastrService
  ) { that = this; }

  discTypes = ["numeric", "percentage"]
  couponsList: any;
  couponData: any;
  formType = '';

  open(content: any) {
    this.modalService.open(content);
  }

  fetchCouponList() {
    this.dataService.fetchCouponList("fetch coupon list", this.successCB.bind(this), this.errorCB.bind(this));
  }

  fetchCouponListWithProducts() {
    this.dataService.fetchCouponListWithProduct("fetch coupon list with product", this.successCB.bind(this), this.errorCB.bind(this));
  }

  editAction(item: any) {
    this.formType = "edit"
    this.couponData = JSON.parse(JSON.stringify(item));
  }

  addAction() {
    this.formType = "add"
    this.couponData = {
      coupon_id: this.genricService.get_uuid(),
      coupon_code: "",
      name: "",
      desc: "",
      max_uses_limit: null,
      max_uses_per_user: null,
      applicable_on: "CART",
      discount: {
        discount_value_type: "percentage",
        discount_value: null,
      },
      validity: {
        valid_from: this.genricService.getTodayDate(),
        valid_till: '',
        validity_days: null,
      },
      data: null,
      is_active: 1
    };
  }

  transformToUpperCase() {
    this.couponData.coupon_code = this.couponData.coupon_code.toUpperCase();
  }

  // Function to calculate days between valid_from and valid_till
  calculateValidityDays() {
    const validFrom = new Date(this.couponData.validity.valid_from);
    const validTill = new Date(this.couponData.validity.valid_till);

    if (validTill >= validFrom) {
      const timeDiff = validTill.getTime() - validFrom.getTime();
      this.couponData.validity.validity_days = Math.ceil(timeDiff / (1000 * 3600 * 24));
    } else {
      // Reset validity_days if valid_till is earlier than valid_from
      this.couponData.validity.validity_days = 0;
    }
  }

  updateCoupon() {

    if (this.is_form_valid()) {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          this.adminDataService.addCouponData("update coupon data", this.couponData, this.successCB.bind(this), this.errorCB.bind(this));
        }
      })
    }
  }

  is_form_valid() {
    if (!this.genricService.is_var_exist(this.couponData.coupon_id)) {
      this.toastr.error("Coupon ID is required");
      return false;
    }

    if (!this.genricService.is_var_exist(this.couponData.coupon_code)) {
      this.toastr.error("Coupon Code is required");
      return false;
    }

    if (!this.genricService.is_var_exist(this.couponData.name)) {
      this.toastr.error("Name is required");
      return false;
    }

    if (!this.genricService.is_var_exist(this.couponData.discount?.discount_value_type)) {
      this.toastr.error("Discount type is required");
      return false;
    }

    if (!this.genricService.is_var_exist(this.couponData.discount?.discount_value)) {
      this.toastr.error("Discount value must be greater than zero");
      return false;
    }

    if (!this.genricService.is_var_exist(this.couponData.validity?.valid_from)) {
      this.toastr.error("Validity start date is required");
      return false;
    }

    if (
      !this.genricService.is_var_exist(this.couponData.validity?.valid_till) ||
      new Date(this.couponData.validity.valid_till) < new Date(this.couponData.validity.valid_from)
    ) {
      this.toastr.error("Validity end date is required and must be after start date");
      return false;
    }

    return true;
  }


  modalRef: any;

  openModal(modal) {
    this.modalRef = this.modalService.open(modal, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  close_modal() {
    this.modalRef.close();
  }

  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    // this.fetchCouponList();
    this.fetchCouponListWithProducts();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      order: []
    };
  }


  successCB(results: any) {

    if (results.api == "fetch coupon list") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.couponsList = results.data;
          console.log("coupon ", results.data);

        }
      }
    }
    if (results.api == "fetch coupon list with product") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.couponsList = results.data;
          console.log("coupon ", results.data);

        }
      }
    }

    if (results.api == "update coupon data") {
      if (results.servercode == ServerCode.SUCCESS) {

        Swal.fire({
          icon: 'success',
          title: 'Changes saved!',
          showConfirmButton: false,
          timer: 1500
        })
        that.close_modal()
        that.fetchCouponList();
      }
    }



  }

  errorCB(error: any) {
    console.log(error);
  }


}
