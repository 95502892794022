import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
// declare var $: any;
var that: any;
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  routesPath = AdminRoutesPath
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {

      xAxes: [{
        time: {
          unit: 'month'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },

      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 10,
          padding: 10,
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }],
    },
    tooltips: {
      titleMarginBottom: 10,
      titleFontColor: '#6e707e',
      titleFontSize: 14,
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    {
      data: [], label: 'Photographers', backgroundColor: "#1cc88a", hoverBackgroundColor: "#17a673",
      borderColor: "#1cc88a",
    },
    {
      data: [], label: 'Users', backgroundColor: "#4e73df", hoverBackgroundColor: "#2e59d9",
      borderColor: "#4e73df"
    }
  ];

  // ----------------
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
    },
    legend: {
      display: false
    },
    cutoutPercentage: 80,
  };
  public doughnutChartLabels: Label[] = ['user', 'photograper'];
  public doughnutChartData: MultiDataSet = [
    [14, 27],

  ];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartColors: Color[] = [
    {
      backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc'],
      hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf'],
      hoverBorderColor: "rgba(234, 236, 244, 1)",
    }
  ]
  // ------------------------

  constructor(private adminDataService: AdminDataService, private router: Router) {
    that = this
  }

  countData: any;
  userList: any;
  monthlyUsers: any;
  monthlyPhotographer: any;
  photographers_without_photeam: any;
  show_pie = true;

  toogle_pie(value: any) {
    this.show_pie = !value;

  }

  fetchUserData() {
    console.log("fecthing user data");
    this.adminDataService.getUserData("fetch user data", this.successCB, this.errorCB);
  }

  dtOptions: DataTables.Settings = {};


  ngOnInit(): void {
    this.fetchUserData();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: []
    };

  }

  createDoughnutGraph() {
    this.doughnutChartData[0][1] = this.countData.photographers.all;
    this.doughnutChartData[0][0] = this.countData.normal_users.all;
  }

  createBarGraph() {
    this.monthlyUsers?.forEach(function (item: any) {
      that.barChartLabels.push(item.month)
      that.barChartData[1].data.push(item.total)
    });

    this.monthlyPhotographer?.forEach(function (item: any) {
      that.barChartData[0].data.push(item.total)
    });
  }

  upgradeMembership(user_id: any) {
    let url = `${AdminRoutesPath.UPGRADE_url}/${user_id}`
    this.router.navigateByUrl(url);
  }

  successCB(results: any) {
    if (results.api == 'fetch user data') {
      if (results.data != null && results.data != undefined) {
        console.log(results.data);
        that.countData = results.data.count;
        that.userList = results.data.userlist;
        that.monthlyUsers = results.data.monthlyUsers;
        that.monthlyPhotographer = results.data.monthlyPhotographer;
        that.photographers_without_photeam = results.data.photographers_without_photeam;

        that.createBarGraph();
        that.createDoughnutGraph();
      }
    }
  }

  errorCB(error: any) {
    if (error != null && error != undefined) {
      console.log(error);
    }
  }

}
