import { ToastrService } from 'ngx-toastr';
import { ServerCode } from './../../../../data/constants/server-code-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminDataService } from './../../../../data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from './../../../../data/dataservice/user-dataservice/data.service';
import { Component, OnInit } from '@angular/core';
import { PaymentsDataService } from 'src/app/data/dataservice/payments-dataservice/payments-data.service';
var that: any;
@Component({
  selector: 'app-upgrade-user-membership',
  templateUrl: './upgrade-user-membership.component.html',
  styleUrls: ['./upgrade-user-membership.component.css']
})
export class UpgradeUserMembershipComponent implements OnInit {

  user_id: any;
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private paymentService: PaymentsDataService
  ) {
    that = this;
    this.user_id = this.route.snapshot.params.user_id;
    console.log(this.user_id);


    if (this.user_id != null && this.user_id != undefined && this.user_id != '') {
      this.fetchUserDetailsById();
    } else {
      this.router.navigateByUrl('/membership');
    }
  }

  user: any = null;
  coupon_code: string = '';   // 'EARLYBIRD'
  cart: any = {
    items: [],
    base_price_total: 0,
    discount_total: 0,
    payable_amount: 0,
  }

  payment_data: any = {
    received_by: '',
    payment_mode: '',
    payment_date: (new Date()).toISOString().substring(0, 10),
    img: '',
  }

  product_list_master = [];
  coupon_list_master = [];

  ngOnInit(): void {
  }

  fetchUserDetailsById() {
    this.dataService.getUserById("fetch user by id", this.user_id, this.successCB, this.errorCB);
  }

  fetchProducts() {
    this.dataService.fetchProductList("fetch Products list", this.successCB, this.errorCB);
  }

  fetchCouponsWithProducts() {
    this.dataService.fetchCouponListWithProduct("fetch Coupons with Products", this.successCB, this.errorCB);
  }

  coins_added = false;

  addItemToCart(code: any) {

    if (code == 'PKG_01' && that.user.membership_type == 'PRO') {
      return;
    }


    let product: any = this.product_list_master.find((item: any) => item.product_code == code);
    if (product) {

      var item: any = {
        product_code: product.product_code,
        product_category: product.category,
        product_type: product.type,
        name: product.name,
        desc: product.desc,
        count: 1,
        base_price: product.base_value,
        unit_price: product.base_value,
        final_value: product.base_value,
        applicable_gst_percent: product.gst_percent,
        validity_days: product.validity_days,
        is_coupon_applicable: product.is_coupon_applicable,
        discount: {
          discount_type: '',
          coupon_code: '',
          discount_value_type: '',
          discount_value: 0,
          discount_amount: 0,
          value: 0,
        }
      }

      this.cart.items.push(item);

      this.updateItemPricing(item);
      this.is_coin_added();
    }
  }

  is_coin_added() {
    let coin = this.cart.items.find((item: any) => item.product_code == 'COIN');
    if (coin) {
      this.coins_added = true;
      return true;
    } else {
      this.coins_added = false;
      return false;
    }
  }

  removeItemFromCart(item: any) {
    this.cart.items.splice(this.cart.items.indexOf(item), 1);
    this.updateCartTotalAmount();
    this.is_coin_added();
  }


  check_coin_discount(item: any) {
    if (item.count > 9) {
      this.applyCoupon('COIN10');
    }
    else {
      if (item.discount.coupon_code) {
        this.removeCoupon(item)
      }
    }
  }

  updateItemCount(item: any, action: any) {

    if (action == 'minus') {
      if (item.count != 1) {
        item.count--;
      } else {
        this.removeItemFromCart(item);
      }
    }

    if (action == 'add') {
      item.count++;
    }

    this.updateItemPricing(item);

    if (item.product_type == 'COIN') {
      this.check_coin_discount(item);
    }
  }

  updateItemPricing(item: any) {
    item.base_price = item.count * item.unit_price;
    item.final_value = item.base_price - item.discount.discount_amount;
    this.updateCartTotalAmount();
  }

  updateCartTotalAmount() {
    this.cart.base_price_total = 0;
    this.cart.discount_total = 0;
    this.cart.payable_amount = 0;
    this.cart.items.forEach((item: any) => {
      this.cart.base_price_total += item.base_price;
      this.cart.discount_total += item.discount.discount_amount;
    });
    this.cart.payable_amount = this.cart.base_price_total - this.cart.discount_total;
  }

  placeOrder() {
    if (this.is_form_valid()) {
      // this.paymentService.create_offplatform_Order_Txn("create payment order", this.user.id, this.cart, this.payment_data, this.successCB, this.errorCB);
    }
  }

  applyCoupon(coupon: any) {
    let coupon_item: any = this.coupon_list_master.find((item: any) => item.coupon_code == coupon);
    if (coupon_item) {
      let applicable_products: any = coupon_item.products

      if (applicable_products.length > 0) {
        applicable_products.forEach((product: any) => {
          let cart_item = this.cart.items.find((item: any) => item.product_code == product.product_code);

          if (cart_item) {

            if (!cart_item.discount.coupon_code) {
              this.toastr.success(coupon_item.coupon_code, 'Coupon Applied');
            }

            cart_item.discount.discount_type = 'coupon';
            cart_item.discount.coupon_code = coupon_item.coupon_code;
            cart_item.discount.discount_value_type = coupon_item.discount_value_type;
            cart_item.discount.discount_value = coupon_item.discount_value;

            if (coupon_item.discount_value_type == 'percentage') {
              cart_item.discount.discount_amount = cart_item.base_price * (coupon_item.discount_value / 100);
            } else {
              cart_item.discount.discount_amount = coupon_item.discount_value;
            }

            this.updateItemPricing(cart_item);
          }
        });
      } else {
        this.toastr.error('Coupon Not applicable for any product');
      }

    } else {
      this.toastr.error('Coupon not found');
    }
  }

  SearchCoupon() {

    if (this.coupon_code != '' && this.coupon_code != null) {
      this.applyCoupon(this.coupon_code);
    } else {
      this.toastr.error('Invalid Coupon Code');
    }
  }

  removeCoupon(item: any) {
    this.toastr.error(item.discount.coupon_code, 'Coupon Removed.');

    item.discount.discount_type = '';
    item.discount.coupon_code = '';
    item.discount.discount_value_type = '';
    item.discount.discount_value = 0;
    item.discount.discount_amount = 0;
    this.updateItemPricing(item);
  }


  onSelectFile(event: any) {
    var file = event.target.files[0];
    if (event.target.files && file) {

      const Filetype = (file.type).split("/")[0];
      if (Filetype.toLowerCase() == 'image') {
        var reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event: any) => { // called once readAsDataURL is completed
          this.payment_data.img = event.target.result;
        }
      } else {
        this.toastr.error('Please upload a valid Image', 'Wrong Format')
      }

    }
  }

  is_form_valid() {
    if (this.cart.items.length == 0) {
      this.toastr.error('Please add items to cart.');
      return false;
    }

    if (this.payment_data.received_by == '' || this.payment_data.received_by == null) {
      this.toastr.error('Please enter received by.');
      return false;
    }

    if (this.payment_data.payment_mode == '' || this.payment_data.payment_mode == null) {
      this.toastr.error('Please enter payment mode.');
      return false;
    }

    return true;
  }

  successCB(results: any) {

    if (results.api == "fetch user by id") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.user = results.data;
          if (that.user != null && that.user != undefined && that.user.is_photographer == 1) {
            that.fetchProducts();
          } else {
            that.toastr.error('Not a photographer');
            that.router.navigateByUrl('/');
          }
        }
      }
    } else if (results.api == "fetch Products list") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('product_list_master', results.data);
        that.product_list_master = results.data

        that.addItemToCart('PKG_01');
        // that.addItemToCart('COIN');
        that.fetchCouponsWithProducts();
      }
    } else if (results.api == "fetch Coupons with Products") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('coupon_list_master', results.data);
        that.coupon_list_master = results.data
        // that.applyCoupon('EARLYBIRD');
      }
    } else if (results.api == "create payment order") {
      if (results.servercode == ServerCode.SUCCESS) {
        that.fetchUserDetailsById();
        that.toastr.success('Order Placed Successfully');
      }
    }
  }

  errorCB(error: any) {
    console.log(error);
  }
}
