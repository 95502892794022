import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
var that;
@Component({
  selector: 'app-photeam-deliverables',
  templateUrl: './photeam-deliverables.component.html',
  styleUrls: ['./photeam-deliverables.component.css']
})
export class PhoteamDeliverablesComponent implements OnInit {

  @ViewChild('json_modal', { static: true }) json_modal: ElementRef;
  modalRef: any;
  view_json: any;

  constructor(
    // private dataService: DataService,
    // private adminDataService: AdminDataService,
    // private route: ActivatedRoute,
    // private router: Router,
    // private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    that = this;
  }

  @Input() data: any;

  ngOnInit(): void {
  }

  viewJSON(data) {
    this.view_json = data;
    this.openModal()
  }

  openModal() {
    this.modalRef = this.modalService.open(this.json_modal, {
      centered: true, size: 'lg'

    });
  }

  onModalClose() {
    this.modalRef.close();
  }

}
