export class ADMIN_APIURL {
  // auth

  // public static signup: string = "auth/register";
  public static login: string = "AdminAuth/login";
  public static logout: string = "AdminAuth/logout";
  public static getUserAccesToken: string = "AdminAuth/get-user-access-token";

  public static createUser: string = "admin/create-user";
  public static getUserData: string = "admin/get-users-data";
  public static getUserPhoteamData: string = "admin/get-user-photeam-data";
  public static getUserQuotationData: string = "admin/get-user-quotation-data";
  public static getKycData: string = "admin/get-user-kyc-data";

  public static getPhoteamData: string = "admin/get-photeams-data";
  public static changePhoteamState: string = "admin/photeam/change-photeams-state";
  public static changePhoteamBankState: string = "admin/photeam/change-photeam-bank-state";
  public static updatePhoteamDescription: string = "admin/photeam/update-photeam-description";

  public static getQuotationData: string = "admin/get-quotation-data";
  public static getQuotationDataByBillId: string = "admin/get-quotations-by-bill_id";
  public static getInvoiceData: string = "admin/get-invoice-data";
  public static getInvoiceDataByBillId: string = "admin/get-invoice-by-bill_id";
  public static getBillData: string = "admin/get-bill-data";
  public static getBillDataByBillId: string = "admin/get-bill-by-bill_id";

  public static getSaveData: string = "admin/save-blog-data";
  public static getBlogList: string = "admin/get-blog-list";

  // ------master data -----------
  public static addMasterServicesData: string = "admin/master/add-master-services-data";
  public static addMasterGenreData: string = "admin/master/add-master-genre-data";
  public static addMasterLanguageData: string = "admin/master/add-master-language-data";
  public static addMasterLocationsData: string = "admin/master/add-master-locations-data";

  // --------- bookings -----------
  public static getAllBookingList: string = "admin/booking/get-all-bookings-list";
  public static getBookingDetailsById: string = "admin/booking/get-booking-by-booking_id";

  // --------- payments -----------
  public static offplatform_Order_Txn: string = "admin/payments/save-offplatform-order-txn";

  //---------- products -----------
  public static addProductData: string = "admin/products/update-product";

  //---------- coupon -----------
  public static addCouponData: string = "admin/coupons/update-coupon";

  // ------------- payment orders --------------
  public static getAllPackagesOrdersCashfreeTxn: string = "admin/package-orders/all-cashfree-txn";
  public static getCashfreeTxnData: string = "admin/package-orders/cashfree-txn-data";
  public static getAllPackagesOrdersOffPlatformTxn: string = "admin/package-orders/all-offplatform-txn";
  public static getOffPlatformTxnData: string = "admin/package-orders/offplatform-txn-data";
  public static getPackagesOrdersCashfreeTxnbyPhoteam: string = "admin/package-orders/cashfree-txn-by-photeam";
  public static getPackagesOrdersOffPlatformTxnbyPhoteam: string = "admin/package-orders/offplatform-txn-by-photeam";

  public static getConfirmedOrderTxnbyPhoteam: string = "admin/package-orders/confirmed-order-txn-by-photeam";
  public static getAllProductsbyPhoteam: string = "admin/package-orders/all-products-by-photeam";

}
