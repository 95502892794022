export class MembershipConstants {
  // ----------- Local Host ----------------
  public static FREE = {
    "membership_data": {
      "membership_type": "FREE",
      "membership_name": "Free",
    },
    "active_plans": {
      "studio_plan": false,
      "cloud_plan": false,
      "billing_plan": false,
    },
    "studio_creation": {
      "is_active": false,
      "product_id": '',
      "max_studio_count": 1,
      "max_pricing_package": 4,//per studio
      "max_images_per_genre": 15, //maximum images that can be uploaded per genre
      "max_video_upload": 4, //per studio
      "max_space_for_video": 0.3, // in gb
      "storage_unit": 'GB',
      "max_youtube_links": 4,
      "max_crew_members": 1,// admin only
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": ''
      }
    },
    "booking_mgmt": {
      "is_active": false,
      "product_id": '',
      "max_count": 4,
      "send_payment_reminder": {
        "email": false,
        "whatsapp": false,
        "text": false
      },
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": ''
      }
    },
    "invoice": {
      "is_active": false,
      "product_id": '',
      "max_count": 4,
      "max_clients": 4,
      "max_tnc": 4,
      "add_business_logo": true,
      "download_pdf": true,
      "import_existing": true,
      "share_via": {
        "email": false,
        "whatsapp": false,
        "text": false
      },
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": ''
      }
    },
    "quotation": {
      "is_active": false,
      "product_id": '',
      "max_count": 4,
      "max_clients": 4,
      "max_tnc": 4,
      "add_business_logo": true,
      "download_pdf": true,
      "import_existing": true,
      "share_via": {
        "email": false,
        "whatsapp": false,
        "text": false
      },
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": ''
      }
    },
    "studio_billing": {
      "is_active": false,
      "product_id": '',
      "max_count": 4,
      "max_clients": 4,
      "max_inventory": 10,
      "max_tnc": 4,
      "add_business_logo": true,
      "download_pdf": true,
      "import_existing": true,
      "upload_photos": false,
      "max_storage": 0, // in GB
      "storage_unit": 'GB',
      "share_via": {
        "email": false,
        "whatsapp": false,
        "text": false
      },
      "items": [],
    },
    "file_transfer": {
      "is_active": false,
      "product_id": '',
      "max_storage": 2, // in GB
      "storage_unit": 'GB',
      "max_album_count": 4,
      "login_to_download": true,
      "share_via": {
        "email": false,
        "whatsapp": false,
        "text": false
      },
      "notification": {
        "email": true
      },
      "items": [],
    }
  };

  public static PREM = {
    "membership_data": {
      "membership_type": "PREM",
      "membership_name": "Premium",
    },
    "active_plans": {
      "studio_plan": false,
      "cloud_plan": false,
      "billing_plan": false,
    },
    "studio_creation": {},
    "booking_mgmt": {},
    "invoice": {},
    "quotation": {},
    "studio_billing": {},
    "file_transfer": {}
  };

  public static STUDIO_PLAN = {
    "studio_creation": {
      "is_active": true,
      "product_id": '',
      "max_studio_count": 3,
      "max_pricing_package": 99999,
      "max_images_per_genre": 50,
      "max_video_upload": 99999,
      "max_space_for_video": 1,
      "storage_unit": 'GB',
      "max_youtube_links": 99999,
      "max_crew_members": 99999,
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": '',
      }
    },
    "booking_mgmt": {
      "is_active": true,
      "product_id": '',
      "max_count": 99999,
      "send_payment_reminder": {
        "email": true,
        "whatsapp": true,
        "text": true
      },
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": '',
      },
    },
    "invoice": {
      "is_active": true,
      "product_id": '',
      "max_count": 99999,
      "max_clients": 99999,
      "max_tnc": 99999,
      "add_business_logo": true,
      "download_pdf": true,
      "import_existing": true,
      "share_via": {
        "email": true,
        "whatsapp": true,
        "text": true
      },
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": '',
      }
    },
    "quotation": {
      "is_active": true,
      "product_id": '',
      "max_count": 99999,
      "max_clients": 99999,
      "max_tnc": 99999,
      "add_business_logo": true,
      "download_pdf": true,
      "import_existing": true,
      "share_via": {
        "email": true,
        "whatsapp": true,
        "text": true
      },
      "validity": {
        "validity_type": '',
        "validity": '',
        "start_at": '',
        "renew_at": '',
      }
    },
  }


  public static BILLING_PLAN = {
    "is_active": true,
    "product_id": '',
    "max_count": 99999,
    "max_clients": 99999,
    "max_inventory": 99999,
    "max_tnc": 99999,
    "add_business_logo": true,
    "download_pdf": true,
    "import_existing": true,
    "upload_photos": true,
    "max_storage": 0, // in GB
    "storage_unit": 'GB',
    "share_via": {
      "email": true,
      "whatsapp": true,
      "text": true
    },
    "items": [],
  }

  public static CLOUD_PLAN = {
    "is_active": true,
    "product_id": '',
    "max_storage": 0, // in GB
    "storage_unit": 'GB',
    "max_album_count": 99999,
    "login_to_download": true,
    "share_via": {
      "email": true,
      "whatsapp": true,
      "text": true
    },
    "notification": {
      "email": true
    },
    "items": [],
  }


}
