import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
var that;
@Component({
  selector: 'app-photeam-overview',
  templateUrl: './photeam-overview.component.html',
  styleUrls: ['./photeam-overview.component.css']
})
export class PhoteamOverviewComponent implements OnInit {

  routesPath = AdminRoutesPath;
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    that=this
    this.photeam_id = this.route.snapshot.params.photeam_id;
  }



  photeam_id: any;
  deliverables: any = null;

  ngOnInit(): void {
    if (this.photeam_id != null && this.photeam_id != undefined) {
      this.fetchDeliverablesbyPhoteamId();
    } else {
      this.router.navigateByUrl('/404');
    }
  }

  fetchDeliverablesbyPhoteamId() {
    this.dataService.getPhoteamDeliverablesData("fetch photeam deliverables", this.photeam_id, this.successCB, this.errorCB);
  }

  successCB(results: any) {

    if (results.api == "fetch photeam deliverables") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log('photeam deliverables', results.data)
        that.deliverables = results.data;
        // that.toastr.success('Super highlighted');
      }
    }


  }

  errorCB(error: any) {
    console.log(error);
  }

}
