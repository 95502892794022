<div class="py-5">
  <app-quotation-pdf-preview
    [quotation_data]="quotation_data"
  ></app-quotation-pdf-preview>
  
</div>

<app-quotation-pdf-download [quotation_data]="quotation_data">
</app-quotation-pdf-download>

<div class="d-flex justify-content-center mb-4">
  <button (click)="onDownload()" type="button" class="btn btn-outline-primary">
    Download PDF
  </button>
</div>
