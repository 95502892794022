import { ToastrService } from 'ngx-toastr';
import { ServerCode } from './../../../../data/constants/server-code-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminDataService } from './../../../../data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from './../../../../data/dataservice/user-dataservice/data.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PaymentsDataService } from 'src/app/data/dataservice/payments-dataservice/payments-data.service';
var that: any;
import * as _moment from 'moment';
import Swal from 'sweetalert2';
import { AuthGuardService } from 'src/app/data/dataservice/auth-dataservice/auth-guard.service';
import { MembershipConstants } from 'src/app/data/constants/membership-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
// import { EventEmitter } from 'stream';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { GenricService } from 'src/app/data/services/genric.service';

@Component({
  selector: 'app-upgrade-membership',
  templateUrl: './upgrade-membership.component.html',
  styleUrls: ['./upgrade-membership.component.css']
})
export class UpgradeMembershipComponent implements OnInit {
  @ViewChild('no_photeam_modal', { static: true }) no_photeam_modal: ElementRef;
  @ViewChild('userMobileModal', { static: true }) userMobileModal: ElementRef;
  private subscription: Subscription = new Subscription();
  modalRef: any;


  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private router: Router,
    private paymentService: PaymentsDataService,
    private modalService: NgbModal,
    private genricService: GenricService,
    private route: ActivatedRoute,
  ) {
    that = this;
    this.user_id = this.route.snapshot.params.user_id;
    this.photeam_id = this.route.snapshot.params.photeam_id;

    if (this.genricService.is_var_exist(this.photeam_id) && this.genricService.is_var_exist(this.user_id)) {
      this.fetchUserDetailsById();
      this.fetch_photeam_deliverables_data();
    } else {
      this.router.navigateByUrl('/');
    }
  }
  storageURL = GlobalConstants.storageURL;

  user_id = '';
  user = null;
  photeam_list = [];
  selected_photeam = null;
  photeam_id = null;

  photeam_deliverables_data = MembershipConstants.FREE;
  coupon_code: string = ''; // 'EARLYBIRD'
  cart = {
    cart_items: [],
    total_price: {
      base_price_total: 0,
      final_price_total: 0,
      internal_discount_total: 0,
    },
    cart_coupon: {
      is_coupon_applied: false,
      coupon_code: '',
    },
    cart_discount: {
      discount_category: 'general',
      discount_value_type: 'percentage',
      discount_value: 0,
      discount_amount: 0,
      round_off_amount: 0,
      round_off_payable_after_discount: 0,
    },
    cart_gst: {
      gst_value_type: 'percentage',
      gst_value: 18,
      gst_amount: 0,
      price_including_gst: 0,
      price_excluding_gst: 0,
    },
    payable_amount: 0,
  };

  product_list_master = [];
  // coupon_list_master = [];
  isLoading: boolean = true; // Set this to true while data is loading
  preSelectedPlan='PKG_01';

  ngOnInit(): void {
  }

  payment_data: any = {
    received_by: 'bhargav',
    payment_mode: '',
    payment_date: (new Date()).toISOString().substring(0, 10),
    img: '',
  }

  onSelectFile(event: any) {
    var file = event.target.files[0];
    if (event.target.files && file) {

      const Filetype = (file.type).split("/")[0];
      if (Filetype.toLowerCase() == 'image') {
        var reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event: any) => { // called once readAsDataURL is completed
          this.payment_data.img = event.target.result;
        }
      } else {
        this.toastr.error('Please upload a valid Image', 'Wrong Format')
      }

    }
  }

  fetchUserDetailsById() {
    this.dataService.getUserById("fetch user by id", this.user_id, this.successCB.bind(this), this.errorCB.bind(this));
  }

  is_user_allowed() {
    if (this.user.is_photographer == 0) {
      this.toastr.error('You are not allowed to access this page.', 'Error');
      this.router.navigateByUrl('/');
    } else {
      this.fetchProducts();
      this.fetchPhoteamListCreatedByUser();
    }
  }

  fetchPhoteamListCreatedByUser() {
    this.dataService.fetchPhoteamListCreatedByUser("fetch photeam list created by user", this.user_id, this.successCB.bind(this), this.errorCB.bind(this));
  }

  is_photeam_valid() {
    if (this.photeam_list.length <= 0) {
      // this.openModal()
    } else {
      // that.toastr.success('photeam found')
      // this.fetch_photeam_deliverables_data()
    }
  }

  fetch_photeam_deliverables_data() {
    if (this.isVarExist(this.photeam_id)) {
      this.dataService.getPhoteamDeliverablesData('get photeam deliverables data by photeam_id', this.photeam_id, this.successCB.bind(this), this.errorCB.bind(this));
    } else {
      this.toastr.error('Studio not loaded')
    }
  }

  updateUserMobile() {
    this.modalRef = this.modalService.open(this.userMobileModal, {
      centered: true,
      size: 'md',
    });
  }

  returnToDashboard() {
    // this.onReturnToDashboard.emit();
  }

  closeModal(data) {
    if (data != null) {
      if (data.module == 'mobile' && data.updateUser) {
        this.user.mobile = data.mobile;
        // window.location.reload();
        this.modalRef.close();
      }
    }
    this.modalRef.close();
  }

  // ------ Validation ----------
  isVarExist(value) {
    if (value != null && value != undefined && value != '') {
      return true;
    }
    return false;
  }
  // ------ Validation Ends -----

  fetchProducts() {
    this.dataService.fetchProductListV2(
      'fetch Products list',
      this.user_id,
      this.photeam_id,
      this.successCB.bind(this),
      this.errorCB.bind(this)
    );
  }

  selected_product: any;
  addProductToCart(product) {
    if (product) {
      let is_item_exist = this.cart.cart_items.find(
        (cart_item) => cart_item.product_code == product.product_code
      );
      if (is_item_exist) {
        // this.removeProductFromCart(is_item_exist);
        return;
      }

      if (product.category == 'PACKAGE') {
        if (
          this.photeam_deliverables_data?.membership_data?.membership_type ==
          'PREM'
        ) {
          this.toastr.error('Studio plan already exist');
          return;
        }

        var selected_pricing_option;
        product.selected = true;
        if (product.pricing_options.length > 0) {
          product.pricing_options.forEach((pricing_option) => {
            pricing_option.selected = false;
          });
          product.pricing_options[0].selected = true;
          selected_pricing_option = product.pricing_options[0];
        }

        this.cart.cart_items.forEach((cart_item, index, cart_items_array) => {
          if (
            cart_item.category == 'PACKAGE' &&
            cart_item.product_code != product.product_code
          ) {
            cart_items_array.splice(index, 1);
            this.product_list_master.find(
              (obj) => obj.product_code == cart_item.product_code
            ).selected = false;
            // console.log('remove pkg', cart_item);
          }
        });
      }

      const cart_item = {
        product_id: product.product_id,
        product_code: product.product_code,
        category: product.category,
        type: product.type,
        product_name: product.product_name,
        product_description: product.product_description,
        pricing_option: selected_pricing_option,
        validity: {
          validity_value_type: selected_pricing_option.validity_type,
          validity_value: selected_pricing_option.validity_value,
          start_date: selected_pricing_option.start_date,
          end_date: selected_pricing_option.end_date,
        },
        unit_count: 1,
        per_month_unit_base_price: product.base_price,
        price_based_on: product.category == 'PACKAGE' ? 'validity' : 'unit', // unit / validity
        unit_base_price: product.base_price,
        discount: {
          discount_category: 'general',
          discount_value_type: 'percentage',
          discount_value: selected_pricing_option.discount_percentage,
          discount_amount: 0,
          round_off_amount: 0,
          round_off_payable_after_discount: 0,
        },
        unit_final_price: selected_pricing_option.total_price,
        total_base_price: selected_pricing_option.base_price,
        total_final_price: selected_pricing_option.total_price,
        coupon: {
          is_coupon_applied: false,
          applied_coupon_code: '',
        },
        price_after_discount: 0,
        gst: {
          gst_value_type: 'percentage',
          gst_value: selected_pricing_option.gst_percetage,
          gst_amount: 0,
          price_including_gst: 0,
          price_excluding_gst: 0,
        },
        data: {
          deliverable: null,
        },
      };

      const calc_item_discount = (cart_item) => {
        if (selected_pricing_option.discount_percentage) {
          this.calculate_cart_item_discount(cart_item);
        } else {
          cart_item.discount.discount_amount = 0;
          cart_item.discount.round_off_amount = 0;
          cart_item.discount.round_off_payable_after_discount =
            cart_item.total_final_price;
          cart_item.price_after_discount =
            cart_item.discount.round_off_payable_after_discount;
        }
      };

      const calc_item_gst = (cart_item) => {
        if (selected_pricing_option.gst_percetage) {
          this.calculate_cart_item_gst(cart_item);
        } else {
          cart_item.gst.price_including_gst = cart_item.price_after_discount;
          cart_item.gst.price_excluding_gst = Number(
            (
              cart_item.price_after_discount /
              (product.gst.value / 100 + 1)
            ).toFixed(2)
          );
          cart_item.gst.gst_amount = Number(
            (
              cart_item.gst.price_including_gst -
              cart_item.gst.price_excluding_gst
            ).toFixed(2)
          );
        }
      };

      calc_item_discount(cart_item);
      calc_item_gst(cart_item);

      if (product.category == 'PACKAGE') {
        this.cart.cart_items.splice(0, 0, cart_item);
      } else {
        this.cart.cart_items.push(cart_item);
      }
      this.updateCartTotalAmount();

      this.selected_product = product;
    }
  }

  calculate_cart_item_discount(cart_item) {
    cart_item.discount.discount_amount =
      cart_item.discount.discount_value_type == 'percentage'
        ? (cart_item.total_base_price * cart_item.discount.discount_value) / 100
        : cart_item.discount.discount_value;
    cart_item.discount.round_off_amount = Math.floor(
      cart_item.discount.discount_amount
    );
    cart_item.discount.round_off_payable_after_discount = Math.ceil(
      cart_item.total_base_price - cart_item.discount.discount_amount
    );
    cart_item.price_after_discount =
      cart_item.discount.round_off_payable_after_discount;
  }

  calculate_cart_item_gst(cart_item) {
    cart_item.gst.price_including_gst = cart_item.price_after_discount;
    cart_item.gst.price_excluding_gst = Number(
      (cart_item.price_after_discount / 1.18).toFixed(2)
    );
    cart_item.gst.gst_amount = Number(
      (
        cart_item.gst.price_including_gst - cart_item.gst.price_excluding_gst
      ).toFixed(2)
    );
  }

  removeProductFromCart(cart_item) {
    this.cart.cart_items.splice(this.cart.cart_items.indexOf(cart_item), 1);
    let product = this.product_list_master.find(
      (product) => product.product_code == cart_item.product_code
    );
    product.selected = false;
    product.count = 0;
    this.updateCartTotalAmount();
  }

  // ----- change validity --------
  onPackageOptionChange(option_id: number, product_id: string) {
    // Find the product in the product list
    const product = this.product_list_master.find(
      (item) => item.product_id === product_id
    );

    if (product) {
      // Iterate over pricing options and set the selected property
      product.pricing_options.forEach((option) => {
        option.selected = option.option_id === option_id;
      });

      // Optional: Update the total price for the product
      const selectedOption = product.pricing_options.find(
        (option) => option.selected
      );
      if (selectedOption) {
        product.total_price = selectedOption.total_price;
      }

      // Call any additional methods if needed
      this.onProductValidityChange(product, selectedOption);
    }
  }

  onProductValidityChange(product, selectedOption) {
    let validity = selectedOption.validity_value;
    let cart_item = this.cart.cart_items.find(
      (item) => item.product_code == product.product_code
    );

    if (cart_item) {
      cart_item.pricing_option = selectedOption;
      if (cart_item.validity) {
        cart_item.validity.end_date = selectedOption.end_date;
        cart_item.validity.start_date = selectedOption.start_date;
        cart_item.validity.validity_type = selectedOption.validity_type;
        cart_item.validity.validity_value = selectedOption.validity_value;

        cart_item.discount.value =
          cart_item.discount.value_type == 'percentage'
            ? (cart_item.total_base_price * cart_item.discount.value) / 100
            : cart_item.discount.value;

        // cart_item.discount.discount_value_type = 'percentage'
        cart_item.discount.discount_value = selectedOption.discount_percentage;

        this.updateProductValidityInCart(cart_item, selectedOption);
      }
    }
  }

  updateProductValidityInCart(cart_item, selectedOption) {
    if (cart_item.price_based_on == 'validity') {
      cart_item.unit_base_price = selectedOption.base_price;
      cart_item.total_base_price = cart_item.unit_base_price;
    }

    this.calculate_cart_item_discount(cart_item);

    if (cart_item.price_based_on == 'validity') {
      cart_item.unit_final_price = Math.ceil(
        cart_item.unit_base_price - cart_item.discount.discount_amount
      );
      cart_item.total_final_price = cart_item.unit_final_price;
    }

    this.calculate_cart_item_gst(cart_item);
    this.updateCartTotalAmount();
  }
  // ---------- change validity -------

  cost_breakup;

  onPayableAmountChange(payable_amount) {

    const calc_discount = () => {
      let cart_discount = this.cart.cart_discount;
      // cart_discount.amount = cart_discount.value_type == 'percentage' ? this.cart.total_price.final_price_total * cart_discount.value / 100 : cart_discount.value;

      let total_price = this.cart.total_price.final_price_total;
      let discount_value = total_price - payable_amount;
      let discount_percentage = Number((discount_value / total_price * 100).toFixed(2));

      cart_discount.discount_category = 'by_admin';
      cart_discount.discount_value_type = 'percentage';
      cart_discount.discount_value = discount_percentage;
      cart_discount.discount_amount = discount_value;
      cart_discount.round_off_amount = Math.floor(cart_discount.discount_amount);
      cart_discount.round_off_payable_after_discount = Math.ceil(this.cart.total_price.final_price_total - cart_discount.discount_amount);

    }

    const calc_gst = () => {
      let cart_gst = this.cart.cart_gst;
      cart_gst.price_including_gst = this.cart.cart_discount.round_off_payable_after_discount;
      cart_gst.price_excluding_gst = Number((this.cart.cart_gst.price_including_gst / 1.18).toFixed(2));
      cart_gst.gst_amount = Number((cart_gst.price_including_gst - cart_gst.price_excluding_gst).toFixed(2));
    }

    calc_discount();
    calc_gst();
    this.calculate_cost_breakup();

  }

  updateCartTotalAmount() {
    this.cart.total_price.base_price_total = 0;
    this.cart.total_price.final_price_total = 0;
    this.cart.total_price.internal_discount_total = 0;

    this.cart.cart_items.forEach((item) => {
      this.cart.total_price.base_price_total += item.total_base_price;
      this.cart.total_price.final_price_total += item.total_final_price;
      this.cart.total_price.internal_discount_total +=
        item.discount.round_off_amount;
    });

    const calc_cart_discount = () => {
      let cart_discount = this.cart.cart_discount;
      cart_discount.discount_amount =
        cart_discount.discount_value_type == 'percentage'
          ? (this.cart.total_price.final_price_total *
            cart_discount.discount_value) /
          100
          : cart_discount.discount_value;
      cart_discount.round_off_amount = Math.floor(
        cart_discount.discount_amount
      );
      cart_discount.round_off_payable_after_discount = Math.ceil(
        this.cart.total_price.final_price_total - cart_discount.discount_amount
      );
    };

    const calc_cart_gst = () => {
      let cart_gst = this.cart.cart_gst;
      cart_gst.price_including_gst =
        this.cart.cart_discount.round_off_payable_after_discount;
      cart_gst.price_excluding_gst = Number(
        (this.cart.cart_gst.price_including_gst / 1.18).toFixed(2)
      );
      cart_gst.gst_amount = Number(
        (cart_gst.price_including_gst - cart_gst.price_excluding_gst).toFixed(2)
      );
    };

    calc_cart_discount();
    calc_cart_gst();

    this.cart.payable_amount =
      this.cart.cart_discount.round_off_payable_after_discount;
    this.calculate_cost_breakup();
  }

  calculate_cost_breakup() {
    this.cost_breakup = {
      txn_amount: this.cart.payable_amount,
      is_gateway_txn: true,
      gateway_charges: {
        on_amount: this.cart.payable_amount,
        service_charge: {
          on: 0,
          value_type: 'percentage',
          value: 1.9,
          amount: 0,
        },
        service_tax: {
          on: 0,
          value_type: 'percentage',
          value: 18,
          amount: 0,
        },
        total: 0,
        in_hand: 0,
      },
      gst: {
        on: 0,
        value_type: 'percentage',
        value: 18,
        amount: 0,
        price_excluding_gst: 0,
      },
      in_hand: 0,
    };

    const calc_gateway_charges = () => {
      let gateway = this.cost_breakup.gateway_charges;
      gateway.service_charge.on = this.cart.payable_amount;
      gateway.service_charge.amount = Number(
        (
          (gateway.service_charge.on * gateway.service_charge.value) /
          100
        ).toFixed(2)
      );

      gateway.service_tax.on = gateway.service_charge.amount;
      gateway.service_tax.amount = Number(
        ((gateway.service_tax.on * gateway.service_tax.value) / 100).toFixed(2)
      );
      gateway.total = Number(
        (gateway.service_charge.amount + gateway.service_tax.amount).toFixed(2)
      );
      gateway.in_hand = Number((gateway.on_amount - gateway.total).toFixed(2));
    };

    const calc_gateway_gst_charges = () => {
      let gst = this.cost_breakup.gst;
      gst.on = this.cost_breakup.gateway_charges.in_hand;
      gst.price_excluding_gst = Number((gst.on / 1.18).toFixed(2));
      gst.amount = Number((gst.on - gst.price_excluding_gst).toFixed(2));
    };
    calc_gateway_charges();
    calc_gateway_gst_charges();
    this.cost_breakup.in_hand = this.cost_breakup.gst.price_excluding_gst;
  }

  applyCoupon(coupon) {
    let coupon_item = this.coupon_dtls;
    if (coupon_item) {
      if (coupon_item.applicable_on == 'CART') {
        if (!this.cart.cart_coupon.is_coupon_applied) {
          this.toastr.success(coupon_item.coupon_code, 'Coupon Applied');
        }
        this.cart.cart_coupon.is_coupon_applied = true;
        this.cart.cart_coupon.coupon_code = coupon_item.coupon_code;

        this.cart.cart_discount.discount_category = 'coupon';
        this.cart.cart_discount.discount_value_type = 'percentage';
        this.cart.cart_discount.discount_value =
          coupon_item.discount_percentage;

        this.updateCartTotalAmount();
      }
    } else {
      this.toastr.error('Inavlid Code');
    }
  }

  SearchCoupon() {
    if (this.isVarExist(this.coupon_code)) {
      this.is_coupon_valid();
    } else {
      this.toastr.error('Invalid Coupon Code');
    }
  }

  removeCoupon() {
    // this.toastr.error(cart.discount.coupon_code, 'Coupon Removed.');
  }

  is_form_valid() {
    if (this.cart.cart_items.length == 0) {
      this.toastr.error('Please add atleast one item');
      return false;
    }
    if (this.cart.payable_amount < 0) {
      this.toastr.error('Invalid Amount.');
      return false;
    }

    if (this.payment_data.received_by == '' || this.payment_data.received_by == null) {
      this.toastr.error('Please enter received by.');
      return false;
    }

    if (this.payment_data.payment_mode == '' || this.payment_data.payment_mode == null) {
      this.toastr.error('Please enter payment mode.');
      return false;
    }

    return true;
  }

  transformCartData() {
    // Check if cart_coupon object and cart_coupon.coupon_code exist
    const couponCode = this.coupon_code;

    // Transform the cart_items array
    const cartItems = this.cart.cart_items.map((item) => ({
      product_id: item.product_id,
      option_id: item.pricing_option.option_id, // or whatever the correct mapping is for option_id
    }));

    // Create the final output object
    const output = {
      user_id: this.user_id,
      photeam_id: this.photeam_id,
      coupon_code: couponCode,
      cart_items: cartItems,
    };

    return output;
  }

  placeOrder() {
    if (!this.isVarExist(this.user.mobile)) {
      this.updateUserMobile();
      this.toastr.error('Please update your mobile number');
      return;
    }

    if (
      this.is_form_valid() &&
      this.isVarExist(this.photeam_id) &&
      this.isVarExist(this.user_id)
    ) {
      let payload = this.transformCartData();
      let cart = this.cart;
      // this.paymentService.createProductsPurchaseOrderV2(
      //   'create payment order',
      //   payload,
      //   this.successCB.bind(this),
      //   this.errorCB.bind(this)
      // );

      Swal.fire({
        title: 'Are you sure?',
        // text: 'You will change the state!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Procced!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          if (this.is_form_valid() && this.isVarExist(this.photeam_id) && this.isVarExist(this.user_id)) {
            this.paymentService.create_offplatform_Order_Txn("create payment order", this.user.id, this.photeam_id, this.cart, this.cost_breakup, this.payment_data, this.successCB.bind(this), this.errorCB.bind(this));
          }
        }
      })

    }
  }

  is_coupon_valid() {
    let payload = this.transformCartData();
    this.dataService.isCouponValid(
      'check is coupon valid',
      payload,
      this.successCB.bind(this),
      this.errorCB.bind(this)
    );
  }

  getSelectedPricingOption(product) {
    const selectedOption = product.pricing_options.find(
      (option) => option.selected
    );

    return selectedOption
      ? selectedOption.per_month_price
      : product.per_month_price;
  }

  getSelectedOption(product) {
    const selectedOption = product.pricing_options.find(
      (option) => option.selected
    );
    return selectedOption;
  }

  product_pkg_list_master: any = [];
  product_add_ons_list_master: any = [];

  filter_products() {
    this.product_list_master = this.product_list_master.filter(
      (item) => item.is_active == 1
    );
    this.product_list_master.forEach((item) => {
      item.selected = false;

      if (item.category == 'PACKAGE') {
        if (item.pricing_options.length > 0) {
          item.pricing_options.forEach((pricing_option) => {
            pricing_option.selected = false;
          });
          item.pricing_options[0].selected = true;
          item.total_price = item.pricing_options[0].total_price;
        }
      }
    });
    // console.log(this.product_list_master, 'product_list_master');
  }

  coupon_dtls = null;

  upgrade_adapter(purpose) {
    // this.modal_purpose = purpose;
    // this.modalRef = this.modalService.open(this.upgradeAdapter, {
    //   centered: true,
    //   windowClass: '',
    //   size: 'lg',
    // });
  }
  successCB(results) {
    if (results.api == 'fetch Products list') {
      if (results.servercode == ServerCode.SUCCESS) {
        this.product_list_master = results.data.products;
        this.filter_products();
        let product = this.product_list_master.find(
          (item) => item.product_code == this.preSelectedPlan
        );
        this.addProductToCart(product);
        this.isLoading = false; // Set to false when loading is complete
      }
    } else if (results.api == 'create payment order') {
      if (results.servercode == ServerCode.SUCCESS) {
          this.toastr.success('Membership Upgraded')
          this.router.navigateByUrl('/');
      } else {
        this.toastr.error('something went wrong');
      }
    } else if (results.api == 'check is coupon valid') {
      if (results.servercode == ServerCode.SUCCESS) {
        // console.log(results.data);
        this.coupon_dtls = results.data;
        this.applyCoupon(this.coupon_dtls.coupon_code);
      } else {
        this.toastr.error('Invalid coupon');
      }
    }
    else if (results.api == 'get photeam deliverables data by photeam_id') {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data) {
          this.photeam_deliverables_data = results.data?.deliverable;
        }
      }
    }
    else if (results.api == 'fetch photeam list created by user') {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          if (results.data.length > 0) {
            this.photeam_list = results.data;
            let selected_photeam = this.photeam_list.find( item => item.photeam_data.photeam_id ==  this.photeam_id)
            this.selected_photeam = selected_photeam;
          }
        }
      }
      // this.is_photeam_valid();
    }

    if (results.api == "fetch user by id") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.user = results.data;
          that.is_user_allowed();
        }
      }
    }
  }

  errorCB(error) {
    //
  }
}
