<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Blogs</h1>
    <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
        class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Blogs</li>
    </ol>
  </nav>

  <!-- Content Row -->
  <div class="row">
    <!-- Content Column -->
    <div class="col-lg-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Accordion -->
        <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse" role="button"
          aria-expanded="true" aria-controls="collapseCardExample">
          <h6 class="m-0 font-weight-bold text-primary">{{blogData.id}} <button type="button"
              class="btn btn-outline-primary" (click)="clearform()">Reset</button></h6>
        </a>
        <!-- Card Content - Collapse -->
        <div class="collapse show" id="collapseCardExample">
          <div class="card-body admin-form">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label class="text-gray-900">Title</label>
                  <input [(ngModel)]="blogData.title" class="form-control form-control-admin ">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="text-gray-900">URL</label>
                  <input [(ngModel)]="blogData.url" class="form-control form-control-admin ">
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label class="text-gray-900">Category</label>
                  <input [(ngModel)]="blogData.category" class="form-control form-control-admin ">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="text-gray-900">Published Date</label>
                  <input type="date" [(ngModel)]="blogData.published_date" class="form-control form-control-admin ">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="text-gray-900">description</label>
                  <textarea type="date" [(ngModel)]="blogData.description" class="form-control form-control-admin "></textarea>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="text-gray-900">Cover Image</label>
                  <input type="text" [(ngModel)]="blogData.cover_image" class="form-control form-control-admin ">
                </div>
              </div>
            </div>
            <br><br> <!-- {{blogData.content}} -->
            <ckeditor [(ngModel)]="blogData.content"> </ckeditor>
            <br><br><button type="button" class="btn btn-outline-primary" (click)="saveBlog()">Save</button>

          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Blog List </h6>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="blogList?.length>0">
        <table datatable [dtOptions]="dtOptions" id="myTable" class="table " id="dataTable" width="100%"
          cellspacing="0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Url</th>
              <th>Created_at</th>
              <th>Updated_at</th>
              <th>Deleted</th>

            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let blog of blogList">
              <td (click)='editBlog(blog.url)' class="cursor-pointer text-warning">{{blog.id}}</td>
              <td>{{blog.title}}</td>
              <td>
                <a target="_blank" href="https://pickleit.in/blog/{{blog.url}}">{{blog.url}}</a></td>
              <td>{{blog.created_at | date: 'MMMM d, y, h:mm:ss a'}}</td>
              <td>{{blog.updated_at | date: 'MMMM d, y, h:mm:ss a'}}</td>
              <td>{{blog.deleted_at | date: 'MMMM d, y, h:mm:ss a'}}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
