<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Transaction Details</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/{{ routesPath.TRANSACTIONS }}">Transactions</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Transaction Details
      </li>
    </ol>
  </nav>

  <!-- Page Content -->
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="border rounded tw-bg-orange-100 p-3">
          <div>
            <div>TXN Data</div>
            <pre>{{ data.txn_data | json }}</pre>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="border rounded tw-bg-orange-100 p-3">
          <div>
            <div>Order Data</div>
            <pre>{{ data.orders | json }}</pre>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="border rounded tw-bg-orange-100 p-3">
          <div>
            <div>Products</div>
            <pre>{{ data.products | json }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #json_modal let-modal>
  <div class="container p-3">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <div class="ph5-bold ptc1">View Data</div>

      </div>
      <div>
        <mat-icon class="ph4 ptc1 cursor_pointer" (click)="onModalClose()"
          >close</mat-icon
        >
      </div>
    </div>
    <ng-container>
      <pre>{{ view_json | json }}</pre>
    </ng-container>
  </div>
</ng-template> -->
