<div class="tw-bg-[#F8F9FF]">
  <div class="container">
    <div class="tw-py-[100px]">
      <div class="position-relative">
        <div class="text-center" *ngIf="user?.membership_type != 'PREM'">
          <div class="ph1-bold ptc1">Upgrade to our premium plan</div>
          <div class="ph1-lite ptc1">to get everything unlimited</div>
        </div>
        <div class="text-center" *ngIf="user?.membership_type == 'PREM'">
          <div class="ph1-bold ptc1">Your premium membership is active</div>
          <div class="ph1-lite ptc1">Buy add-ons to enhance your studio</div>
        </div>
        <!-- <div class="text-center pt-3 ptc5 ph5 tw-text-[##757E9D]">
          <span
            ><a href="{{ pricing_page }}" target="_blank">Click here</a>
          </span>
          to read more about our premium plan offerings.
          <br />
          <span
            ><a href="{{ online_demo }}" target="_blank">Click here</a></span
          >
          to set up a free demo.
        </div> -->

        <img class="stonks" src="/assets/img/generic/stonks.svg" alt="" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 py-3">
        <div class="order_items_div">
          <div
            class="prem_pkg mb-3"
            *ngIf="!photeam_deliverables_data?.active_plans?.studio_plan"
          >
            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
              <ng-container *ngFor="let product of product_list_master">
                <div
                  *ngIf="product.category == 'PACKAGE'"
                  class="pkg_grid_item"
                  (click)="addProductToCart(product)"
                >
                  <div
                    class="tw-p-[35px] content"
                    [ngClass]="{ selected_bg: product.selected }"
                  >
                    <div
                      class="month_tooltip"
                      [ngClass]="{ selected: product.selected }"
                    >
                      <div>
                        <div class="">
                          {{ product.validity.validity }} Month<span
                            *ngIf="product.validity.validity_type > 1"
                            >s</span
                          >
                        </div>

                        <div class="d-flex justify-content-center mt-2">
                          <div class="quantity ph5">
                            <div
                              class="quantity__minus"
                              (click)="
                                updateProductValidity(
                                  product,
                                  product.validity.validity - 1
                                )
                              "
                            >
                              -
                            </div>
                            <input
                              id="item_quantity"
                              min="1"
                              name="item_quantity"
                              type="number"
                              class="quantity__input"
                              [(ngModel)]="product.validity.validity"
                              (ngModelChange)="
                                productValidityChange(product, $event)
                              "
                            />
                            <div
                              class="quantity__plus"
                              (click)="
                                updateProductValidity(
                                  product,
                                  product.validity.validity + 1
                                )
                              "
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="text-center py-4">
                      <div class="tw-min-h-[25px]">
                        <div
                          class="original_price"
                          [ngClass]="{ selected: product.selected }"
                          *ngIf="product.base_value != product.final_value"
                        >
                          ₹{{ product.base_value.toLocaleString("en-IN") }}
                        </div>
                      </div>

                      <div
                        class="final_price"
                        [ngClass]="{ selected: product.selected }"
                      >
                        ₹{{ product.final_value.toLocaleString("en-IN") }}
                      </div>
                    </div>

                    <div class="ph6 text-center">
                      <span
                        [ngClass]="
                          product.selected ? 'tw-text-[#CED7FF]' : 'ptc8'
                        "
                        >Plan renews on</span
                      >
                      <br />
                      <span
                        class="bold"
                        [ngClass]="product.selected ? 'ptc2' : 'ptc1'"
                        >{{ product?.validity.renew_at }}</span
                      >
                    </div>
                  </div>

                  <div
                    class="sell_tag tw-gap-3"
                    *ngIf="product.discount != null"
                  >
                    <mat-icon class="ph">sell</mat-icon>
                    <span *ngIf="product.discount.discount_type == 'percentage'"
                      >Save {{ product.discount.value }}%</span
                    >
                    <span *ngIf="product.discount.discount_type == 'number'"
                      >Save ₹{{ product.discount.value }}</span
                    >
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="add_ons">
            <div class="ph5-semibold ptc1 mb-3">Value add-ons</div>
            <ng-container *ngFor="let product of product_list_master">
              <div
                class="add_ons_items p-3 mb-3"
                *ngIf="product.category == 'ADDONS'"
              >
                <div class="row no-gutters">
                  <div class="col-md-3">
                    <img [src]="product.data.html.img" alt="" />
                  </div>
                  <div class="col-md-3 align-self-center">
                    <div class="text-center">
                      <div
                        class="add_on_text"
                        [innerHTML]="product.data.html.text"
                      ></div>
                      <div
                        class="add_on_sub_text pt-2"
                        [innerHTML]="product.data.html.sub_text"
                      ></div>
                    </div>
                  </div>
                  <div class="col-md-6 align-self-center">
                    <div>
                      <div
                        class="d-flex tw-max-w-[250px] align-items-center tw-gap-3 pb-3"
                        *ngFor="let point of product.data.html.disc_points"
                      >
                        <div>
                          <img src="/assets/payments/check_mark.svg" alt="" />
                        </div>
                        <div class="add_ons_points">{{ point }}</div>
                      </div>
                    </div>

                    <div class="quantity" *ngIf="product.count > 0">
                      <div
                        class="quantity__minus"
                        (click)="updateProductCount(product, product.count - 1)"
                      >
                        -
                      </div>
                      <input
                        id="item_quantity"
                        min="0"
                        name="item_quantity"
                        type="number"
                        class="quantity__input"
                        [(ngModel)]="product.count"
                        (ngModelChange)="productCountChange(product, $event)"
                      />
                      <div
                        class="quantity__plus"
                        (click)="updateProductCount(product, product.count + 1)"
                      >
                        +
                      </div>
                    </div>

                    <div cl *ngIf="product.count == 0">
                      <!-- <div class="quantity">
                        <div
                          class="quantity__minus quantity__plus"
                          (click)="
                            updateProductCount(product, product.count + 1)
                          "
                        >
                          +
                        </div>
                      </div> -->

                      <button
                        type="button"
                        (click)="updateProductCount(product, product.count + 1)"
                        class="add_quantity_btn flex_center"
                      >
                        <span class="tw-mr-[8px]">+</span
                        ><span class="tw-text-[12px]">Add Item</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <pre>{{ cart | json }}</pre>
      </div>

      <div class="col-lg-4 py-3">
        <app-photeam-selection-dropdown
          (onDropdownChange)="getSelectedPhoteamFromSelectionDropdown($event)"
          *ngIf="photeam_list.length > 0 && selected_photeam"
          [photeam_list]="photeam_list"
          [selected_photeam]="selected_photeam"
        >
        </app-photeam-selection-dropdown>

        <div class="car_summary mb-4">
          <div class="ph5-bold ptc1">Payment Details</div>
          <div class="py-2">
            <select class="form-control" [(ngModel)]="payment_data.received_by">
              <option value="">Who Received Payment ?</option>
              <option value="bhargav">Bhargav</option>
              <option value="sughosha">Sughosha</option>
              <option value="abhishek">Abhishek</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="py-2">
            <select
              class="form-control"
              [(ngModel)]="payment_data.payment_mode"
            >
              <option value="">Select Payment Method</option>
              <option value="upi">UPI</option>
              <option value="cash">Cash</option>
              <option value="bank_transfer">Bank Transfer</option>
              <option value="card">Card</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div class="py-2">
            <label for="pay_img">Payment Date</label>
            <input
              class="form-control"
              [(ngModel)]="payment_data.payment_date"
              type="date"
            />
          </div>

          <div class="py-2">
            <label for="pay_img">Payment Screnshot</label>
            <input
              class="form-control"
              F
              type="file"
              (change)="onSelectFile($event)"
              accept="image/jpg, image/jpeg, image/png"
              id="pay_img"
            />

            <div class="text-center">
              <img
                [src]="payment_data.img"
                class="img-fluid"
                style="max-height: 200px"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="order_summery_div sticky">
          <div class="ph3-bold">Order Summary</div>
          <div class="coupon_card mt-4">
            <!-- Apply coupon -->
            <div class="apply_coupon">
              <div>
                <div class="ph5-bold">Apply Discount Code</div>
                <!-- <div class="ptc1 ph7 pt-1">
                  Apply <span class="ptc12">'EARLYBIRD'</span> to get
                  <span class="bold">15% discount</span>
                </div> -->
              </div>

              <div class="flex_between_center pt-3 tw-gap-3">
                <div class="md-form md-outline m-0 form-lg w-100">
                  <input
                    id="coupon_code"
                    class="form-control form-control-lg inputField"
                    type="text"
                    mdbInput
                    name="coupon_code"
                    [(ngModel)]="coupon_code"
                    placeholder="Enter Code"
                    (ngModelChange)="coupon_code = coupon_code.toUpperCase()"
                  />
                </div>

                <div class="flex">
                  <button
                    class="btn btn-primary"
                    (click)="SearchCoupon()"
                    type="button"
                  >
                    search
                  </button>
                </div>
              </div>
            </div>

            <!-- Applied coupon -->
            <div
              class="pt-3 applied_coupon"
              *ngIf="cart.cart_coupon.coupon_code"
            >
              <div class="d-flex align-items-center">
                <div class="ph7-semibold mr-3">Coupon applied</div>
                <div class="coupon-label">
                  <span class="ph7-bold ptc0">{{
                    cart.cart_coupon.coupon_code.toUpperCase()
                  }}</span>
                  <mat-icon
                    class="ph4 ptc5 ml-2 cursor_pointer"
                    (click)="removeCoupon()"
                    >close</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <!-- <hr class="horizontalLine" />
            <div class="ph5-bold ptc1">Order Summary</div> -->

            <!-- item list -->
            <div *ngFor="let item of cart.cart_items">
              <div class="flex_between">
                <div>
                  <div class="ph5-semibold d-flex">
                    <div class="ptc13 tw-w-[140px]">{{ item.name }}</div>
                    <div *ngIf="item.unit_count > 1" class="ptc12 ml-2">
                      x {{ item.unit_count }}
                    </div>
                  </div>
                  <div *ngIf="item?.discount?.value > 0" class="ph8 ptc18">
                    {{ item?.discount?.value }}% off applied (₹{{
                      item?.discount?.round_off_amount
                    }})
                  </div>
                </div>
                <div class="d-flex">
                  <div class="tw-w-[60px]">
                    <div class="ph4-bold ptc0">
                      ₹{{ item.price_after_discount.toLocaleString("en-IN") }}
                    </div>
                    <div
                      *ngIf="
                        item.total_final_price != item.price_after_discount
                      "
                      class="ph8 ptc5 tw-line-through"
                    >
                      ₹{{ item.total_final_price.toLocaleString("en-IN") }}
                    </div>
                  </div>

                  <div class="tw-w-[30px] d-flex tw-justify-end">
                    <mat-icon
                      *ngIf="item.category == 'ADDONS'"
                      title="delete"
                      (click)="removeProductFromCart(item)"
                      class="cursor_pointer tw-text-[#606781] hover:tw-text-red-500"
                      >delete_outline</mat-icon
                    >
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <!-- sub total & discount -->
            <div>
              <div class="flex_between_center mt-3">
                <div>
                  <div class="ph6 ptc5">
                    Subtotal ({{ cart.cart_items.length }} Items)
                  </div>
                  <div class="ph8 ptc18">
                    You Save ₹{{
                      cart.total_price.internal_discount_total.toLocaleString(
                        "en-IN"
                      )
                    }}
                  </div>
                </div>

                <div class="d-flex">
                  <div class="ph6-semibold ptc5 tw-w-[60px]">
                    ₹{{
                      cart.total_price.final_price_total.toLocaleString("en-IN")
                    }}
                  </div>
                  <div class="tw-w-[30px]"></div>
                </div>
              </div>

              <div
                *ngIf="cart.cart_discount.value > 0"
                class="flex_between_center mt-3"
              >
                <div class="ph6 ptc5">
                  Discount ({{ cart.cart_discount.value }}%)
                </div>
                <div class="d-flex">
                  <div class="ph6-semibold ptc18 tw-w-[60px] text-nowrap">
                    (-) ₹{{
                      cart.cart_discount.round_off_amount.toLocaleString(
                        "en-IN"
                      )
                    }}
                  </div>
                  <div class="tw-w-[30px]"></div>
                </div>
              </div>

              <hr class="horizontalLine" />
            </div>

            <!-- total  -->
            <div>
              <div class="flex_between_center mt-3">
                <div>
                  <div class="ph5-semibold ptc13">Total Payable</div>
                  <div class="ph8 ptc5">
                    ₹{{ cart.payable_amount?.toLocaleString("en-IN") }}
                  </div>
                </div>

                <div class="d-flex">
                  <div class="ph4-bold ptc12 tw-w-[150px]">
                    <input
                      type="number"
                      [(ngModel)]="cart.payable_amount"
                      (ngModelChange)="onPayableAmountChange($event)"
                      class="w-100"
                    />
                  </div>
                  <!-- <div class="tw-w-[30px]"></div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <button
              class="btn btn-primary w-100"
              (click)="placeOrder()"
              type="button"
            >
              Confirm Order
            </button>
          </div>
        </div>

        <!-- <input type="number" > -->

        <pre>cost_breakup : {{ cost_breakup | json }}</pre>
      </div>
    </div>
  </div>
</div>

<ng-template #no_photeam_modal let-modal>
  <div class="container p-3">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <div class="ph5-bold ptc1">Studio Not Found</div>
        <div class="ph7 ptc5">
          You have not created any Studio yet. Please create your studio.
        </div>
      </div>
      <div>
        <!-- <mat-icon class="ph4 ptc1 cursor_pointer" (click)="onModalClose()"
          >close</mat-icon
        > -->
      </div>
    </div>
    <ng-container>
      <div class="p-3 rounded build_brand_card">
        <div class="ptc13 mb-3">
          <div class="ph3-lite">Build Your</div>
          <div class="ph3-bold">Own Brand</div>
        </div>

        <div class="d-flex tw-flex-col tw-gap-2">
          <div class="d-flex align-items-center">
            <mat-icon class="tw-text-[#c5ad6f] mr-2">storefront</mat-icon>
            <div class="tw-text-[#514a36]">
              Build Online <span class="bold">Studio</span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <mat-icon class="tw-text-[#c5ad6f] mr-2">manage_accounts</mat-icon>
            <div class="tw-text-[#514a36]">
              Manage <span class="bold">Crew Members</span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <mat-icon class="tw-text-[#c5ad6f] mr-2">group</mat-icon>
            <div class="tw-text-[#514a36]">
              Get <span class="bold">100% Verified Leads</span>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <button
            (click)="CreateStudio()"
            class="secondary_btn flex-center !tw-px-3 !tw-py-[6px]"
            type="button"
          >
            <span class="ph7-semibold"> Close</span>
          </button>
        </div>
      </div>

      <!-- <div class="flex_center">
        <app-primary-button
          buttonText="Create Studio"
          size="small"
          (onButtonClick)="CreateStudio()"
        >
        </app-primary-button>
      </div> -->
    </ng-container>
  </div>
</ng-template>
