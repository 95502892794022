
import { Component, OnInit } from '@angular/core';

import { AdminAuthService } from 'src/app/data/dataservice/auth-dataservice/adminAuth.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

var that: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AdminAuthService,
    private router: Router
  ) {
    that = this;
  }

  email: any;
  password: any;


  onLoginSubmit() {
    this.authService.login(
      "login api",
      this.email, this.password,
      this.successCB,
      this.errorCB
    );
  }

  ngOnInit(): void {
  }

  successCB(results: any) {
    if (results.api == 'login api') {
      if (results.servercode == ServerCode.SUCCESS) {
        // that.toastr.success('Logged-in successfully');
        that.router.navigateByUrl('/');
      }
    }

  }

  errorCB(error: any) {
    if (error != null && error != undefined) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Somthing went wrong',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }


}
