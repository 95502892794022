import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

var that: any;
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {


  constructor(
    private adminDataService: AdminDataService,
    private router: Router,
    private toastr: ToastrService
  ) {
    that = this;
  }

  user = {
    name: "",
    mobile: "",
    email: "",
    is_photographer: "1",
    invite_code: null,
  }

  acceptOnlyNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      this.toastr.error('Enter Number');
      return false;
    } else {
      return true;
    }
  }

  acceptOnlyAlphabet(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122) && (charCode != 32)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  toTitleCase(str: any) {
    return str.toLowerCase().split(' ').map(function (word: any) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  isEmailValid(value: any) {
    let email = value;
    if (email != '' && email != null && email != undefined) {
      email = email.toLowerCase();
      email = email.trim();
      if (email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$") != null) {
        return true;
      }
      return false;
    }
    return false;
  }

  isMobileValid(value: any) {
    var regex = /^[6-9]\d{9}$/g
    return regex.test(value)
  }


  validation() {
    if (this.user.name.trim() == "" || this.user.name == null) {
      this.toastr.error("Name is invalid");
      return false;
    }

    if (this.user.mobile.trim() == "" || this.user.mobile == null) {
      this.toastr.error("Mobile is invalid");
      return false;
    }

    if (!this.isMobileValid(this.user.mobile)) {
      this.toastr.error("Mobile Must be 10 Digits");
      return false;
    }

    if (this.user.email.trim() == "" || this.user.email == null) {
      this.toastr.error("Email is required");
      return false;
    }

    if (!this.isEmailValid(this.user.email)) {
      this.toastr.error("Email is required");
      return false;
    }

    if (this.user.is_photographer == "" || this.user.is_photographer == null) {
      this.toastr.error("User Type is invalid");
      return false;
    }

    return true;
  }


  onSubmit() {
    if (this.validation()) {
      this.adminDataService.createUser(
        "create user",
        this.user,
        this.successCB,
        this.errorCB
      );
    }
  }

  ngOnInit(): void {
  }

  successCB(results: any) {
    if (results.api == 'create user') {
      if (results.servercode == ServerCode.SUCCESS) {
        that.toastr.success("User Created");
        Swal.fire({
          icon: 'success',
          title: 'User Added',
          showConfirmButton: false,
          timer: 1500
        })

        that.user = {
          name: "",
          mobile: "",
          email: "",
          is_photographer: "1",
          invite_code: null,
        }
      }
      if (results.servercode == ServerCode.ERROR) {

        Swal.fire({
          icon: 'error',
          title: results.data.msg,
          showConfirmButton: false,
          timer: 2000
        })
      }
    }
  }

  errorCB(error: any) {
    if (error != null && error != undefined) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Somthing went wrong',
        showConfirmButton: false,
        timer: 1500
      })

    }
  }
}
