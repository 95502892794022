export class GlobalConstants {
  // ----------- Local Host ----------------
  // public static baseURL: string = "http://127.0.0.1:8000/api";
  // public static storageURL: string = "http://127.0.0.1:8000/uploads";
  // public static appURL: string = "http://localhost:4200";
  // ----------- Local Host ----------------

  // --------- Live Server--------
  public static baseURL: string = "https://gopickle.ai/pickle_server/public/api";
  public static storageURL: string = "https://gopickle.ai/pickle_server/public/uploads";
  public static appURL: string = "https://gopickle.ai";
  // -------------------------------

  // ------- Test server --------------
  // public static baseURL: string = "https://picklelabs.in/pickle_server/public/api";
  // public static storageURL: string = "https://picklelabs.in/pickle_server/public/uploads";
  // public static appURL: string = "https://picklelabs.in";
  // ------------------------------------
}
