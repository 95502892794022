import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-quotation-pdf-preview',
  templateUrl: './quotation-pdf-preview.component.html',
  styleUrls: ['./quotation-pdf-preview.component.css']
})
export class QuotationPdfPreviewComponent implements OnInit {

  @Input() quotation_data: any;
  @Input() type: any;
  @Output("onClose") onClose: EventEmitter<any> = new EventEmitter();

  constructor(private toastr: ToastrService) { }

  activeformat = { gst: 'Percent', discount: 'Value' };
  discountFieldValue: number = 0;
  gstFieldValue: number = 0;

  is_FormValid() {
    if (!this.quotation_data) {
      return false;
    }

    if (this.quotation_data.header.serial_no == "" || this.quotation_data.header.serial_no == null) {
      return false;
    } else if (this.quotation_data.header.date == "" || this.quotation_data.header.date == null) {
      return false;
    } else if (this.quotation_data.source_details.src_name == "" || this.quotation_data.source_details.src_name == null) {
      return false;
    } else if (this.quotation_data.source_details.src_mobile == "" || this.quotation_data.source_details.src_mobile == null) {
      return false;
    } else if (this.quotation_data.client_details.clt_name == "" || this.quotation_data.client_details.clt_name == null) {
      return false;
    } else if (this.quotation_data.client_details.clt_mobile == "" || this.quotation_data.client_details.clt_mobile == null) {
      return false;
    } else if (!this.is_itemsValid()) {
      return false;
    }
    return true;
  }

  is_itemsValid() {
    for (var i = 0; i < this.quotation_data.item_dtls.data.length; i++) {
      this.quotation_data.item_dtls.data[i].item_name = this.quotation_data.item_dtls.data[i].item_name?.trim();
      let item = this.quotation_data.item_dtls.data[i];
      if (this.quotation_data.item_dtls.is_rate_qnt_present) {


        if (item.item_name == '' || item.item_name == null) {
          return false;
        } else if (item.item_qnt == '' || item.item_qnt == null) {
          return false;
        } else if (item.item_qnt < 1) {
          return false;
        } else if (item.item_rate == '' || item.item_rate == null) {
          return false;
        } else if (item.item_rate < 1) {
          return false;
        } else if (item.item_cost == '' || item.item_cost == null) {
          return false;
        } else if (item.item_cost < 1) {
          return false;
        }

      } else {
        if (item.item_name == '' || item.item_name == '') {
          return false;
        } else if (item.item_cost == '' || item.item_cost == null) {
          return false;
        } else if (item.item_cost < 1) {
          return false;
        }
      }
    }

    return true;
  }

  check_pay_tnc() {

    let value = false;
    this.quotation_data.pay_tnc.forEach(item => {
      if (item.pay_amount && item.pay_before) {
        value = true;
      }
    });

    // console.log(value);
    return value;
  }

  check_bank_dtls() {
    if (this.quotation_data.bank_dtls.account_holder_name) {
      return true;
    } else if (this.quotation_data.bank_dtls.account_number) {
      return true;
    } else if (this.quotation_data.bank_dtls.bank_name) {
      return true;
    } else if (this.quotation_data.bank_dtls.ifsc_code) {
      return true;
    } else if (this.quotation_data.bank_dtls.bank_upi) {
      return true;
    }

    return false;

  }

  check_tnc() {
    let value = false;
    this.quotation_data.tnc.forEach(item => {
      if (item.text) {
        value = true;
      }
    });

    // console.log(value);
    return value;
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.onClose.emit();
  }


}
