import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { APIURL } from '../../url/url-constants';
import { GlobalConstants } from '../../constants/global-constants';
import { Action } from 'rxjs/internal/scheduler/Action';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  constructor(
    private http: HttpClient
  ) { }

  getUserData() {
    //api to get user data upon sign up
  }

  userEmailVerification(apiname: string, email: string, token: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.userEmailVerification;
    const body = {
      email: email,
      token: token
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  resendEmailVerification(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.resendEmailVerification;
    const body = {
      user_id: user_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getUserById(apiname: string, id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getUserById;
    const body = {
      id: id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getUserByMobile(apiname: string, mobile: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getUserByMobile;
    const body = {
      mobile: mobile
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getUserMembershipData(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getUserMembershipData;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamDeliverablesData(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getPhoteamDeliverablesData;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  newsletterSignup(apiname: string, email: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.newsletterSignup;
    const body = {
      email: email
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhotographerById(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getPhotographerById;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  save_user_avatar(apiname: string, user_id: string, file: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveUserProfileImage;
    const body = {
      user_id: user_id,
      file: file
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  savePhotographerInfo(apiname: string, user_id: string, website: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhotographerInfo;
    const body = {
      user_id: user_id,
      website: website
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  saveGenreExpertise(apiname: string, userid: string, total_experience: string, certifications: any, awards: any, genres: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveGenreExpertise;
    const body = {
      user_id: userid,
      total_experience: total_experience,
      certifications: certifications,
      awards: awards,
      genres: genres
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  saveEquipArsenal(apiname: string, userid: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveEquipArsenal;
    const body = {
      user_id: userid,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getConstantsEnum(apiname: string, category: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getConstantsEnum;
    const body = {
      category: category,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getGenreList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getGenreList;
    const body = null;
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getEquipListByCode(apiname: string, code: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getEquipListByCode;
    const body = {
      code: code,
    };;
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getEquipById(apiname: string, equip_id: string, code: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getEquipById;
    const body = {
      equip_id: equip_id,
      code: code,
    };;
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getServicesLocationList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getServicesLocationList;
    const body = null;
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getServicesList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getServicesList;
    const body = null;
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getDeliverableList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getDeliverableList;
    const body = null;
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getLanguagesList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getLanguagesList;
    const body = null;
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }



  fetchPhoteamListByUser(apiname: string, userid: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamListByUser;
    const body = {
      user_id: userid
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchPhoteamListCreatedByUser(apiname: string, userid: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamListCreatedByUser;
    const body = {
      user_id: userid
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  unacceptedPhoteamInviteList(apiname: string, userid: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.unacceptedPhoteamInviteList;
    const body = {
      user_id: userid
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  requestToJoinPhoteamToAdmin(apiname: string, photeam_id: string, userid: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.sendRequestToJoinPhoteamToAdmin;
    const body = {
      user_id: userid,
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }


  // ----------------------- Photeam -----------------------

  getIncompletePhoteamByUser_id(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchIncompletePhoteamByUser_id;
    const body = {
      user_id: user_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamDetailsBy_username(apiname: string, photeam_username: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamDetailsByphoteam_username;
    const body = {
      photeam_username: photeam_username,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamDetailsById(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamDetailsByPhoteam_id;
    const body = {
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  deletePhoteamById(apiname: string, photeam_id: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deletePhoteamById;
    const body = {
      photeam_id: photeam_id,
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamTabStatus(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getPhoteamTabStatus;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // -------------------------------------------------------



  // ----------------- Photeam banner and DP------------------------

  savePhoteam_Profile_and_Banner_ByPhoteamId(apiname: string, photeam_id: string, file: any, type: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamProfileAndBannerImages;
    const body = {
      photeam_id: photeam_id,
      type: type,
      file: file
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteam_Profile_and_Banner_ByPhoteamId(apiname: string, photeam_id: string, type: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamProfileAndBannerImages;
    const body = {
      photeam_id: photeam_id,
      type: type
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }
  // -----------------------------------------------

  validatePhoteamUsername(apiname: string, photeam_username: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.validatePhoteamUsername;
    const body = {
      photeam_username: photeam_username
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.value = photeam_username;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }
  // ------------------ Photeam General ---------------------
  getPhoteamGeneralDataByPhoteamId(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamGeneralData;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  savePhoteamGeneralDataByPhoteamId(apiname: string, photeam: any, photeam_id: any, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamGeneralData;
    const body = {
      photeam: photeam,
      photeam_id: photeam_id,
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  markPhoteamCompleted(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.markPhoteamCompleted;
    const body = {
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }
  // ------------------------------------------------

  // -----------------photeam services-----------
  getPhoteamServicesDataByPhoteamId(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamServicesData;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  savePhoteamServicesDataByPhoteamId(apiname: string, photeam_id: any, services_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamServicesData;
    const body = {
      photeam_id: photeam_id,
      services_data: services_data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }
  // -----------------------------------------

  // -----------------photeam photos -----------------
  // savePhoteamGenreImagesToDB(apiname: string, photeam_id: string, genre_id: string, images: any, successCB: Function, errorCB: Function) {
  //   const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamGenreImagesToDB;
  //   const body = {
  //     photeam_id: photeam_id,
  //     genre_id: genre_id,
  //     images: images
  //   };
  //   var promise = this.http.post<any>(url, body).toPromise();

  //   promise.then((data) => {
  //     data.api = apiname;
  //     successCB(data);
  //   }).catch((err) => {
  //     errorCB(err);
  //   })
  // }

  saveVideoLinks(apiname: string, photeam_id: string, links: any, src: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveVideoLinks;
    const body = {
      photeam_id: photeam_id,
      links: links
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.src = src;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getVideoListByPhoteam_id(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getVideoListByPhoteam_id;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getYoutubeVideoInfo(apiname: string, video_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getYoutubeVideoInfo;
    const body = {
      video_id: video_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamGenreImagesByPhoteam_id(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamGenreImagesByPhoteam_id;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamGenreImagesByGenreId(apiname: string, photeam_id: string, genre_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamGenreImagesByGenreId;
    const body = {
      photeam_id: photeam_id,
      genre_id: genre_id
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  markPhotosStepCompleted(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.markPhotosStepCompleted;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }


  deletePhoteamGenreImagesByFileId(apiname: string, File_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deletePhoteamGenreImagesByFileId;
    const body = {
      file_id: File_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.file_id = File_id;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  deletePortfolioVideoById(apiname: string, File_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deletePortfolioVideoById;
    const body = {
      file_id: File_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.file_id = File_id;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  highlightImageById(apiname: string, File_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.highlightImageById;
    const body = {
      file_id: File_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.file_id = File_id;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }


  // -------------------------------------------------

  // ------------------ Photeam Faq ---------------------
  getPhoteamFaqMasterData(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getFaqsByType;
    const body = {
      type: "photeam"
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamFaqUserAnswersByPhoteamId(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamFaqAnswersData;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  savePhoteamFaqUserAnswersByPhoteamId(apiname: string, photeam_id: any, faq_answers: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamFaqAnswersData;
    const body = {
      photeam_id: photeam_id,
      faq_answers: faq_answers
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ----------------------------------------------------

  // -------------------photeam Members-----------------------
  getUserByContact(apiname: string, member: any, is_photographer: any, is_email: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getUserByContact;
    const body = {
      key: member,
      is_photographer: is_photographer,
      is_email: is_email
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamMembersByPhoteamId(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamMembersData;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  savePhoteamMembersByPhoteamId(apiname: string, photeam_id: any, selectedMembers: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamMembersData;
    const body = {
      photeam_id: photeam_id,
      members: selectedMembers,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  verify_join_photeam_invite_link(apiname: string, photeam_id: any, user_id: any, token: any, action: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.verifyJoinPhoteamInviteLink;
    const body = {
      photeam_id: photeam_id,
      user_id: user_id,
      action: action,
      token: token
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  join_photeam_invite_action(apiname: string, notification: any, notification_action: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.verifyJoinPhoteamInviteLink;
    const body = {
      photeam_id: notification.data.photeam_id,
      user_id: notification.data.member_id,
      action: notification_action,
      token: notification.data.token
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.notification = notification;
      data.action = notification_action == 1 ? 'accepted' : 'declined'
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  join_photeam_request_action(apiname: string, notification: any, notification_action: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.verifyJoinPhoteamRequestLink;
    const body = {
      photeam_id: notification.data.photeam_id,
      user_id: notification.data.member_id,
      action: notification_action,
      token: notification.data.token
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.notification = notification;
      data.action = notification_action == 1 ? 'accepted' : 'declined'
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ---------------------------------------------------------

  // -----------------photeam bank account-------------
  getPhoteamBankAccountByPhoteamId(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamBankAccountData;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  savePhoteamBankAccountByPhoteamId(apiname: string, photeam_id: any, bankAccount: any, file: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamBankAccountData;
    const body = {
      photeam_id: photeam_id,
      bankAccount: bankAccount,
      file: file
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }
  // ---------------------------------------------

  // ----------------photeam package ---------------

  // getPhoteamPackageByPhoteamId(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
  //   const url = GlobalConstants.baseURL + '/' + APIURL.getPhoteamPackageByPhoteamId;
  //   const body = {
  //     photeam_id: photeam_id
  //   };
  //   var promise = this.http.post<any>(url, body).toPromise();

  //   promise.then((data) => {
  //     data.api = apiname;
  //     successCB(data);
  //   }).catch((err) => {
  //     errorCB(err);
  //   })
  // }

  getPhoteamPackageByPhoteamId_v2(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getPhoteamPackageByPhoteamId_v2;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamPackageByPackageId(apiname: string, package_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamPackageDataByPackage_id;
    const body = {
      package_id: package_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // savePhoteamPackageByPhoteamId(apiname: string, photeam_id: any, user_id: any, pkg: any, successCB: Function, errorCB: Function) {
  //   const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamPackageData;
  //   const body = {
  //     photeam_id: photeam_id,
  //     user_id: user_id,
  //     package: pkg
  //   };
  //   var promise = this.http.post<any>(url, body).toPromise();

  //   promise.then((data) => {
  //     data.api = apiname;
  //     successCB(data);
  //   }).catch((err) => {
  //     errorCB(err);
  //   })
  // }

  savePhoteamPackageByPhoteamId_v2(apiname: string, pkg: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamPackageData_v2;
    const body = {
      data: pkg
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  deletePackageById(apiname: string, package_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deletePackageById;
    const body = {
      package_id: package_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  toggleHidePackageById(apiname: string, package_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.toggleHidePackageById;
    const body = {
      package_id: package_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }


  addPhoteamToFavorite(apiname: string, user_id: any, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.addPhoteamToFavorite;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  listFavoritePhoteamsByUser(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.listFavoritePhoteamsByUser;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // getResolvedPackage(apiname: string, package_data: any, successCB: Function, errorCB: Function) {
  //   const url = GlobalConstants.baseURL + '/' + APIURL.getResolvedPackage;
  //   const body = {
  //     package_data: package_data
  //   };
  //   var promise = this.http.post<any>(url, body).toPromise();
  //   promise.then((data) => {
  //     data.api = apiname;
  //     successCB(data);
  //   }).catch((err) => {
  //     errorCB(err);
  //   })
  // }

  // ------------------------------------------


  createPhotographerRequest(apiname: string, user: any, post_req: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.createPhotographerRequest;
    const body = {
      mobile: user.mobile,
      otp: user.otp,
      email: user.email,
      name: user.name,
      event_location: post_req.event_location,
      genre_id: post_req.genre_id,
      event_description: post_req.event_description,
      expectations: "",
      budget_range_min: post_req.budget_range_min,
      budget_range_max: post_req.budget_range_max,
      event_date: post_req.event_date,
      event_expected: post_req.event_expected,
      user_id: user.id,
      time_to_contact: post_req.time_to_contact
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  createPhotographerRequest_v3(apiname: string, user_id: any, post_req: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.createPhotographerRequest_v3;
    const body = {
      user_id: user_id,
      post_req: post_req
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ---------------- Review ------------
  fetchPhoteamReviewList(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamReviewList;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  fetchAggregateRatingByPhoteam(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchAggregateRatingByPhoteam;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  fetchPhoteamReviewByUser(apiname: string, photeam_id: any, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchPhoteamReviewByUser;
    const body = {
      photeam_id: photeam_id,
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  savePhoteamReviewByUser(apiname: string, photeam_id: any, user_id: any, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamReviewByUser;
    const body = {
      photeam_id: photeam_id,
      user_id: user_id,
      data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  upVotePhoteamReviewByReview_id(apiname: string, review_id: any, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.upVotePhoteamReviewByReview_id;
    const body = {
      review_id: review_id,
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  downVotePhoteamReviewByReview_id(apiname: string, review_id: any, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.downVotePhoteamReviewByReview_id;
    const body = {
      review_id: review_id,
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  replyReviewByPhoteam(apiname: string, review_id: any, photeam_id: any, user_id: any, reply: any, date: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.replyReviewByPhoteam;
    const body = {
      review_id: review_id,
      photeam_id: photeam_id,
      reply_data: {
        reply_from: user_id,
        reply: reply,
        date: date
      }
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }




  // -----------refral ----------
  getUserReferralData(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchUserReferralData;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ------------- blog ----------------

  getBlogByUrl(apiname: string, blog_url: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getBlogByUrl;
    const body = {
      url: blog_url
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  getBlogList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getBlogList;
    const body = {};
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }


  // search studio

  photeamSearchList(apiname: string, location: any, genres: any, services: any, rating: any, orderBy: any, page_size: any, page_no: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.photeamSearchList;
    const body = {
      location: location,
      genres: genres,
      services: services,
      rating: rating,
      orderBy: orderBy,
      page_size: page_size,
      page_no: page_no
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getSearchLocationList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getServicesLocationList;
    const body = {
      is_photeam_exist: 1
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getPhoteamsbyNameOrUsername(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getPhoteamsbyNameOrUsername;
    const body = {

    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }




  // ---------- payments ----

  getKycByUserId(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getKycByUserId;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  storeKycByUserId(apiname: string, user_id: any, kycData: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.storeKycByUserId;
    const body = {
      user_id: user_id,
      data: kycData
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  getBankAccountByUserId(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getBankAccountByUserId;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  storeBankAccountByUserId(apiname: string, user_id: any, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.storeBankAccountByUserId;
    const body = {
      user_id: user_id,
      bank_data: data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ----  booking  ------

  getBookingsByPhoteam_id(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getBookingsByPhoteam_id;
    const body = {
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  getUserBookingsByUser_id(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getUserBookingsByUser_id;
    const body = {
      user_id: user_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getResolvedFinalPackageByBooking_id(apiname: string, booking_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getUserBookingsByUser_id;
    const body = {
      booking_id: booking_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  sendBookingInquiry(apiname: string, booking_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.sendBookingInquiry;
    const body = {
      booking_data: booking_data,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  createCustomBooking(apiname: string, booking_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.createCustomBooking;
    const body = {
      booking_data: booking_data,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  deleteCustomBooking(apiname: string, booking_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deleteCustomBooking;
    const body = {
      booking_id: booking_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  updateBookingData(apiname: string, booking_id: any, booking_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.sendBookingInquiry;
    const body = {
      booking_id: booking_id,
      booking_data: booking_data,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getBookingOverviewPhoteam_id(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getBookingOverviewPhoteam_id;
    const body = {
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  updateBookingInquiryStatus(apiname: string, booking_id: any, status: any, booking_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.updateBookingInquiryStatus;
    const body = {
      booking_id: booking_id,
      status: status,
      booking_data: booking_data,
    };

    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  tempBookingInquiry(apiname: string, photeam_id: any, user_id: any, package_id: any, event_location: any, event_date: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.tempBookingInquiry;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      package_id: package_id,
      event_location: event_location,
      event_date: event_date,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }


  // tools Invoice
  fetchInvoicesByUser(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchInvoicesByUser;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchInvoicesByPhoteam(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchInvoicesByPhoteam;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  saveInvoice(apiname: string, user_id: string, photeam_id: string, invoice_id: string, invoice_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveInvoice;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      invoice_id: invoice_id,
      invoice_data: invoice_data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  fetchInvoiceById(apiname: string, user_id: string, invoice_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchInvoiceById;
    const body = {
      user_id: user_id,
      invoice_id: invoice_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  deleteInvoice(apiname: string, user_id: string, invoice_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deleteInvoice;
    const body = {
      user_id: user_id,
      invoice_id: invoice_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  // - tools quotation -------

  fetchQuotationsByUser(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchQuotationsByUser;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchQuotationsByPhoteam(apiname: string, photeam_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchQuotationsByPhoteam;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  saveQuotation(apiname: string, user_id: string, photeam_id: string, quotation_id: string, quotation_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveQuotation;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      quotation_id: quotation_id,
      quotation_data: quotation_data
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  fetchQuotationById(apiname: string, user_id: string, quotation_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchQuotationById;
    const body = {
      user_id: user_id,
      quotation_id: quotation_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  deleteQuotation(apiname: string, user_id: string, quotation_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deleteQuotation;
    const body = {
      user_id: user_id,
      quotation_id: quotation_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  //  -------- tools billing -------
  fetchBillsByUser(apiname: string, user_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchBillsByUser;
    const body = {
      user_id: user_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchBillsByPhoteamID(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchBillsByPhoteamID;
    const body = {
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  saveBillData(apiname: string, bill_id: any, user_id: any, photeam_id: any, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveBillData;
    const body = {
      bill_id: bill_id,
      user_id: user_id,
      photeam_id: photeam_id,
      bill_data: data,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchBillById(apiname: string, user_id: string, bill_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchBillByID;
    const body = {
      user_id: user_id,
      bill_id: bill_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  deleteBill(apiname: string, user_id: string, bill_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deleteBill;
    const body = {
      user_id: user_id,
      bill_id: bill_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  // - tools Generic -------
  fetchToolsStudioDetailsById(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchToolsStudioDetailsById;
    const body = {
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  saveToolsStudioDetailsById(apiname: string, user_id: any, photeam_id: any, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.saveToolsStudioDetailsById;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      data: data,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchToolsTnCbyPhoteamID(apiname: string, photeam_id: any, tool_type: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchToolsTnCbyPhoteamID;
    const body = {
      photeam_id: photeam_id,
      tool_type: tool_type
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  updateToolsTnCbyPhoteamID(apiname: string, user_id: any, photeam_id: any, data: any, tool_type: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.updateToolsTnCbyPhoteamID;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      data: data,
      tool_type: tool_type
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchToolsCustomerDetailsByPhoteamID(apiname: string, photeam_id: any, tool_type: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchToolsCustomerDetailsByPhoteamID;
    const body = {
      photeam_id: photeam_id,
      tool_type: tool_type
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  updateToolsCustomerDetailsByPhoteamID(apiname: string, user_id: any, photeam_id: any, data: any, tool_type: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.updateToolsCustomerDetailsByPhoteamID;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      data: data,
      tool_type: tool_type
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchInventorybyPhoteamID(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.fetchInventorybyPhoteamID;
    const body = {
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  updateInventorybyPhoteamID(apiname: string, user_id: any, photeam_id: any, data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.updateInventorybyPhoteamID;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
      data: data,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }



  // ------ prducts -----------
  fetchProductList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getProductList;
    const body = {
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  fetchProductListV2(
    apiname: string,
    user_id,
    photeam_id,
    successCB: Function,
    errorCB: Function
  ) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getProductListV2;
    const body = {
      user_id: user_id,
      photeam_id: photeam_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise
      .then((data) => {
        data.api = apiname;
        successCB(data);
      })
      .catch((err) => {
        errorCB(err);
      });
  }

  fetchProductListWithCoupon(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getProductListWithCoupons;
    const body = {
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }


  // ------ Coupons -----------
  fetchCouponList(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getCouponList;
    const body = {
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  isCouponValid(
    apiname: string,
    payload: any,
    successCB: Function,
    errorCB: Function
  ) {
    const url = GlobalConstants.baseURL + '/' + APIURL.checkIsCouponValid;
    const body = payload;
    var promise = this.http.post<any>(url, body).toPromise();

    promise
      .then((data) => {
        data.api = apiname;
        successCB(data);
      })
      .catch((err) => {
        errorCB(err);
      });
  }


  fetchCouponListWithProduct(apiname: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getCouponListWithProducts;
    const body = {
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // ------ data transfer --------
  getPhoteamFileTransferProjectList(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getPhoteamFileTransferProjectList;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  getFileTransferProjectbyLink(apiname: string, link_token: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getFileTransferProjectbyLink;
    const body = {
      link_token: link_token
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  updateOrCreateFileTransferProject(apiname: string, project_data: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.updateOrCreateFileTransferProject;
    const body = {
      project_data: project_data
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  deleteAlbumFiles(apiname: string, project_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.deleteAlbumFiles;
    const body = {
      project_id: project_id
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  downloadAlbumFiles(apiname: string, project_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.downloadAlbumFiles;
    const body = {
      project_id: project_id
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }




  // ----------------- Notifications ----------

  getNotificationsByUser_id(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getNotificationsByUser_id;
    const body = {
      user_id: user_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  markNotificationsReadedByUser_id(apiname: string, user_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.markNotificationsReadedByUser_id;
    const body = {
      user_id: user_id,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  updateNotificationStatusByNotification_id(apiname: string, notification: any, status: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.updateNotificationStatusByNotification_id;
    const body = {
      notification: notification,
      status: status,
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })

  }

  sendTelegramBotNotification(apiname: string, data: any, type: any, other: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.telegramBotMsg;
    const body = {
      data: data,
      type: type,
      other: other
    };
    var promise = this.http.post<any>(url, body).toPromise();
    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }



  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------


  sendPasswordResetLink(apiname: string, email: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.sendPasswordResetLink;
    const body = {
      email: email
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  resetPassword(apiname: string, email: string, password: string, token: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.resetPassword;
    const body = {
      email: email,
      token: token,
      password: password,
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  // -----------------photeam photos -----------------
  savePhoteamGenreImagesToDB(apiname: string, photeam_id: string, genre_id: string, images: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamGenreImagesToDB;
    const body = {
      photeam_id: photeam_id,
      genre_id: genre_id,
      images: images
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  superhighlightImageById(apiname: string, File_id: string, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.superhighlightImageById;
    const body = {
      file_id: File_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      data.file_id = File_id;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }
  // -------------------------------------------------


  // ----------------photeam package ---------------
  getPhoteamPackageByPhoteamId(apiname: string, photeam_id: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.getPhoteamPackageByPhoteamId;
    const body = {
      photeam_id: photeam_id
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }

  savePhoteamPackageByPhoteamId(apiname: string, photeam_id: any, user_id: any, pkg: any, successCB: Function, errorCB: Function) {
    const url = GlobalConstants.baseURL + '/' + APIURL.savePhoteamPackageData;
    const body = {
      photeam_id: photeam_id,
      user_id: user_id,
      package: pkg
    };
    var promise = this.http.post<any>(url, body).toPromise();

    promise.then((data) => {
      data.api = apiname;
      successCB(data);
    }).catch((err) => {
      errorCB(err);
    })
  }
  // ------------------------------------------

}

