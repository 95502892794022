<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Photeam</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Photeams</li>
    </ol>
  </nav>
  <!-- Content Row -->
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-8">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div
          class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
        >
          <!-- <h6 class="m-0 font-weight-bold text-primary">Create</h6> -->
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="">
            <div class="text-center">
              <h1 class="h4 text-gray-900 mb-4">Create New Profile</h1>
            </div>
            <form class="user">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-user"
                  name="name"
                  [(ngModel)]="user.name"
                  placeholder="Name"
                  (keypress)="acceptOnlyAlphabet($event)"
                  (ngModelChange)="user.name = toTitleCase($event)"
                />
              </div>

              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-user"
                  (keypress)="acceptOnlyNumbers($event)"
                  name="mobile"
                  maxlength="10"
                  [(ngModel)]="user.mobile"
                  placeholder="Mobile No."
                />
              </div>

              <div class="form-group">
                <input
                  type="email"
                  class="form-control form-control-user"
                  name="email"
                  [(ngModel)]="user.email"
                  placeholder="Email"
                />
              </div>

              <div class="form-group d-flex">
                <div class="mr-4">
                  <input
                    type="radio"
                    id="is_photographer"
                    name="is_photographer"
                    value="1"
                    [(ngModel)]="user.is_photographer"
                    checked
                  />
                  <label for="is_photographer">Photographer</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="is_user"
                    name="is_photographer"
                    value="0"
                    [(ngModel)]="user.is_photographer"
                  />
                  <label for="is_user">User</label>
                </div>
              </div>

              <button
                type="button"
                (click)="onSubmit()"
                class="btn btn-primary btn-user btn-block"
              >
                Create
              </button>
              <hr />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
