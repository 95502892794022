
<div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Billing</h1>
    </div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Booking</li>
      </ol>
    </nav>

    <!-- DataTales Example -->
    <div class="card shadow mb-4 mt-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">All bookings List</h6>
      </div>
      <div class="card-body">
        <div
          class="table-responsive"
          *ngIf="billing_list?.quotationlist?.length > 0"
          style="height: 800px"
        >
          <table
            datatable
            [dtOptions]="dtOptions"
            class="table"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th>Bill ID</th>
                <th>Created By</th>

                <th>deleted_at</th>
                <th>created_at</th>
                <th>updated_at</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let item of billing_list.quotationlist"
                [ngClass]="{ deleted: item.deleted_at != null }"
              >
                <td
                  title="{{ item.billing_id }}"
                  class="cursor-pointer text-primary"
                  routerLink="/bills/{{ item.bill_id }}"
                >
                  {{ item.bill_id }}
                </td>
                <td
                  title="{{ item.user_id }}"
                  class="cursor-pointer text-warning"
                  routerLink="/{{routesPath.USER_DETAILS}}/{{ item.user_id }}"
                >
                  {{ item.user.name }}
                </td>

                <td [title]="item.deleted_at | date: 'MMMM d, y, h:mm:ss a'">
                  {{ item.deleted_at | date: "d-MMM-yy" }}
                </td>
                <td [title]="item.created_at | date: 'MMMM d, y, h:mm:ss a'">
                  {{ item.created_at | date: "d-MMM-yy" }}
                </td>
                <td [title]="item.updated_at | date: 'MMMM d, y, h:mm:ss a'">
                  {{ item.updated_at | date: "d-MMM-yy" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div></div>
  </div>
