<!-- Sidebar -->
<ul
  class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  id="accordionSidebar"
>
  <!-- Sidebar - Brand -->
  <a
    class="sidebar-brand d-flex align-items-center justify-content-center"
    href="/"
  >
    <div class="sidebar-brand-icon rotate-n-15">
      <!-- <i class="fas fa-laugh-wink"></i> -->
      <img src="/assets/img/logo.png" class="w-50" alt="" />
    </div>
    <div class="sidebar-brand-text mx-3">Pickle Admin</div>
  </a>
  <!-- Divider -->
  <hr class="sidebar-divider" />
  <!-- Heading -->
  <div class="sidebar-heading">Analytics</div>
  <!-- Nav Item - Dashboard -->
  <!-- <li class="nav-item " routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <a class="nav-link" routerLink="/">
      <i class="fas fa-fw fa-tachometer-alt"></i>
      <span>Dashboard</span></a>
  </li> -->
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/{{ routesPath.PHOTEAMS }}">
      <i class="fas fa-store-alt"></i>
      <span>Photeams</span></a
    >
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/{{ routesPath.USERS }}">
      <i class="fas fa-users"></i>
      <span>Users</span></a
    >
  </li>

  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/{{ routesPath.BOOKINGS }}">
      <i class="fas fa-rupee-sign"></i>
      <span>Bookings</span></a
    >
  </li>

  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/{{ routesPath.TRANSACTIONS }}">
      <i class="fas fa-rupee-sign"></i>
      <span>Transactions</span></a
    >
  </li>

  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/blogs">
      <i class="far fa-file-alt"></i>
      <span>Blogs</span></a
    >
  </li>

  <!-- Divider -->
  <hr class="sidebar-divider" />
  <!-- Heading -->
  <div class="sidebar-heading">Tools</div>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/quotations">
      <i class="far fa-file-alt"></i>
      <span>Quotations</span></a
    >
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/invoices">
      <i class="far fa-file-alt"></i>
      <span>Invoice</span></a
    >
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/bills">
      <i class="far fa-file-alt"></i>
      <span>Billing</span></a
    >
  </li>

  <!-- Divider -->
  <hr class="sidebar-divider" />
  <!-- Heading -->
  <div class="sidebar-heading">Interface</div>
  <!-- Nav Item - Pages Collapse Menu -->
  <li class="nav-item">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#collapseTwo"
      aria-expanded="true"
      aria-controls="collapseTwo"
    >
      <i class="fas fa-database"></i>
      <span>Master Data</span>
    </a>
    <div
      id="collapseTwo"
      class="collapse"
      routerLinkActive="show"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <h6 class="collapse-header">Genric:</h6>
        <a
          class="collapse-item"
          routerLinkActive="active"
          routerLink="/master-data/constants-enum"
          >Constants & Enum</a
        >
        <h6 class="collapse-header">Photeams Data:</h6>
        <a
          class="collapse-item"
          routerLinkActive="active"
          routerLink="/master-data/genre"
          >Genre</a
        >
        <a
          class="collapse-item"
          routerLinkActive="active"
          routerLink="/master-data/services"
          >Services</a
        >
        <a
          class="collapse-item"
          routerLinkActive="active"
          routerLink="/master-data/locations"
          >Locations</a
        >
        <a
          class="collapse-item"
          routerLinkActive="active"
          routerLink="/master-data/languages"
          >Languages</a
        >
      </div>
    </div>
  </li>

  <!-- Divider -->
  <hr class="sidebar-divider d-none d-md-block" />
  <!-- Heading -->
  <div class="sidebar-heading">Admin</div>

  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/products">
      <i class="far fa-file-alt"></i>
      <span>Products</span></a
    >
  </li>

  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/coupons">
      <i class="far fa-file-alt"></i>
      <span>Coupons</span></a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#adminTools"
      aria-expanded="true"
      aria-controls="adminTools"
    >
      <i class="fas fa-fw fa-cog"></i>
      <span>Tools</span>
    </a>
    <div
      id="adminTools"
      class="collapse"
      routerLinkActive="show"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <a
          class="collapse-item"
          routerLinkActive="active"
          routerLink="/add-user"
          >Add User</a
        >
      </div>
    </div>
  </li>

  <!-- Sidebar Toggler (Sidebar) -->
  <div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle"></button>
  </div>
</ul>
<!-- End of Sidebar -->
