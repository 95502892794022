import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AdminAuthService } from './adminAuth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public authService: AdminAuthService, public router: Router) { }
    canActivate(): boolean {
        if (!this.authService.isAuthenticated()) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}
