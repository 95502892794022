<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Master Data</h1>
    <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
        class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Services List</li>
    </ol>
  </nav>
  <!-- Content Row -->

  <!-- <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Services List </h6>
    </div>
    <div class="card-body">

    </div>
  </div> -->



  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3 d-flex justify-content-between">
      <h6 class="m-0 font-weight-bold text-primary">Services List
      </h6>

      <span class="btn btn-sm btn-info" (click)="open(content); addAction()">
        <i class="fas fa-plus fa-sm text-white-50"></i> Add New
      </span>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="servicesList?.length>0">
        <table datatable [dtOptions]="dtOptions" id="myTable" class="table " id="dataTable" width="100%"
          cellspacing="0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>sub_text</th>
              <th>sort_order</th>
              <th>Actions</th>
              <!-- <th>is_deleted</th> -->
              <th>Created at</th>
              <!-- <th>updated at</th> -->
              <!-- <th>deleted at</th> -->
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of servicesList">
              <td>{{item.service_id }}</td>
              <td>{{item.service_name}}</td>
              <td>{{item.sub_text}}</td>
              <td>{{item.sort_order}}</td>
              <td>
                <span title="Edit" (click)="open(content); editAction(item)" class="text-light btn-warning btn-circle">
                  <i class="fas fa-edit"></i>
                </span>
              </td>
              <!-- <td>{{item.is_deleted}}</td> -->
              <td>{{item.created_at | date: 'MMMM d, y, h:mm:ss a'}}</td>
              <!-- <td>{{item.updated_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
              <!-- <td>{{item.deleted_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType=='edit'">Update Service</h4>
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType=='add'">Create Service</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body admin-form">
      <form *ngIf="servicesData!=undefined">
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Service ID</label>
          <input class="form-control" [ngClass]="{'is-invalid': servicesData.service_id=='' }" name="service_id"
            [(ngModel)]='servicesData.service_id'>
          <div *ngIf="servicesData.service_id==''" class="invalid-feedback">
            Required
          </div>
        </div>
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Service Name</label>
          <input class="form-control " [ngClass]="{'is-invalid': servicesData.service_name=='' }" name="service_name"
            [(ngModel)]='servicesData.service_name'>
          <div *ngIf="servicesData.service_name==''" class="invalid-feedback">
            Required
          </div>
        </div>
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Sub Text</label>
          <input class="form-control " name="sub_text" [(ngModel)]='servicesData.sub_text'>
        </div>
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Sort Order</label>
          <input type="number" class="form-control " [ngClass]="{'is-invalid': servicesData.sort_order<0 }"
            name="sort_order" [(ngModel)]='servicesData.sort_order'>
          <div *ngIf="servicesData.sort_order<0" class="invalid-feedback">
            Required
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark"
        (click)="modal.close('Save click');updateService() ">{{formType=='edit'?'update':"create"}}</button>
    </div>
  </ng-template>

</div>
