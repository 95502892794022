import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { ActivatedRoute } from '@angular/router';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { QuotationPdfDownloadComponent } from 'src/app/components/genric/tools/quotation/quotation-pdf-download/quotation-pdf-download.component';

var that: any;

@Component({
  selector: 'app-quotaion-details',
  templateUrl: './quotaion-details.component.html',
  styleUrls: ['./quotaion-details.component.css']
})
export class QuotaionDetailsComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
  ) { that = this; }


  @ViewChild(QuotationPdfDownloadComponent) private quotationPdfDownloadComponent!: QuotationPdfDownloadComponent;

  ngOnInit(): void {
    let quotation_id = this.route.snapshot.params.quotation_id;
    console.log(quotation_id);
    this.fetchQuotationDataById(quotation_id)
  }

  fetchQuotationDataById(quotation_id: any) {
    this.adminDataService.getQuotationDataById("fetch quotation by quotation_id", quotation_id, this.successCB, this.errorCB);
  }

  onDownload() {
    this.quotationPdfDownloadComponent.onDownloadPDF();
  }

  quotation_data: any;

  successCB(results: any) {
    if (results.api == "fetch quotation by quotation_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.quotation_data = results.data.quotation_data;
          console.log("quotation", that.quotation_data);
        }
      }
    }
  }

  errorCB(error: any) {
    console.log(error);
  }

}
