import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { InvoicePdfDownloadComponent } from 'src/app/components/genric/tools/invoice/invoice-pdf-download/invoice-pdf-download.component';

var that: any;

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.css']
})

export class InvoiceDetailsComponent implements OnInit {
  
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
  ) { that = this; }


  @ViewChild(InvoicePdfDownloadComponent) private invoicePdfDownloadComponent!: InvoicePdfDownloadComponent;

  ngOnInit(): void {
    let invoice_id = this.route.snapshot.params.invoice_id;
    console.log(invoice_id);
    this.fetchInvoiceDataById(invoice_id)
  }

  fetchInvoiceDataById(invoice_id: any) {
    this.adminDataService.getInvoiceDataById("fetch invoice by invoice_id", invoice_id, this.successCB, this.errorCB);
  }

  onDownload() {
    this.invoicePdfDownloadComponent.onDownloadPDF();
  }

  invoice_data: any;

  successCB(results: any) {
    if (results.api == "fetch invoice by invoice_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.invoice_data = results.data.invoice_data;
          console.log("invoice", that.invoice_data);
        }
      }
    }
  }

  errorCB(error: any) {
    console.log(error);
  }

}
