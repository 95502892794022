<div class="loader" *ngIf="loader"></div>
<div class="container d-none">
  <div class="" id="element-to-export">
    <ng-container *ngIf="quotation_data">
      <!-- header -->
      <div class="header-wrapper">
        <div class="invoice-heading">
          <div class="invoice-title">
            <!-- <span>{{ type == "quotation" ? "Quotation" : "Invoice" }} </span> -->
            <span>Quotation</span>
          </div>
        </div>
        <div class="invoice-header">
          <div class="invoice-detail-section">
            <table border="0" class="invoice-table invoice-head-table">
              <tbody>
                <tr *ngIf="quotation_data?.header?.title">
                  <th>{{ quotation_data?.header?.title }}</th>
                </tr>
                <tr *ngIf="quotation_data?.header?.serial_no">
                  <th class="hash-header">
                    Serial No
                    <div class="false">#</div>
                  </th>
                  <td>{{ quotation_data?.header?.serial_no }}</td>
                </tr>
                <tr *ngIf="quotation_data?.header?.date">
                  <th>Date</th>
                  <td>
                    <div>
                      <span>{{ quotation_data?.header?.date | date }}</span>
                    </div>
                  </td>
                </tr>

                <ng-container
                  *ngFor="let item of quotation_data?.header?.custom_fields"
                >
                  <tr *ngIf="item?.value">
                    <th>{{ item?.key }}</th>
                    <td>{{ item?.value }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="logo-wrapper">
            <img
              class="responsive-image"
              [src]="quotation_data?.header?.logo"
            />
          </div>
        </div>
      </div>

      <!-- address -->
      <div class="address-section-wrapper">
        <div class="address-section-billed-by ptc1">
          <div class="billed-by-badge">
            <span class="ptc2 ph6 mb-2">Billed by</span>
          </div>
          <div class="details">
            <div
              class="ph4-semibold my-1"
              *ngIf="quotation_data?.source_details?.src_name"
            >
              {{ quotation_data?.source_details?.src_name }}
            </div>

            <div
              class="ph6"
              *ngIf="quotation_data?.source_details?.src_address"
            >
              {{ quotation_data?.source_details?.src_address }}
            </div>
            <div class="ph6" *ngIf="quotation_data?.source_details?.src_email">
              <span class="semibold">Email: </span>
              <span>{{ quotation_data?.source_details?.src_email }}</span>
            </div>
            <div class="ph6" *ngIf="quotation_data?.source_details?.src_mobile">
              <span class="semibold">Phone: </span>
              <span>+91 {{ quotation_data?.source_details?.src_mobile }}</span>
            </div>
            <div class="ph6" *ngIf="quotation_data?.source_details?.src_gst">
              <span class="semibold">GST: </span>
              <span>{{ quotation_data?.source_details?.src_gst }}</span>
            </div>
            <div class="ph6" *ngIf="quotation_data?.source_details?.src_pan">
              <span class="semibold">PAN: </span>
              <span>{{ quotation_data?.source_details?.src_pan }}</span>
            </div>

            <ng-container
              *ngFor="let item of quotation_data?.source_details?.custom_fields"
            >
              <div class="ph6" *ngIf="item?.value">
                <span class="semibold">{{ item?.key }}: </span>
                <span>{{ item?.value }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="address-section-billed-to ptc1">
          <div class="billed-to-badge mb-2">
            <span class="ptc2 ph6">Billed to</span>
          </div>
          <div class="details">
            <div
              class="ph4-semibold my-1"
              *ngIf="quotation_data?.client_details?.clt_name"
            >
              {{ quotation_data?.client_details?.clt_name }}
            </div>

            <div
              class="ph6"
              *ngIf="quotation_data?.client_details?.clt_address"
            >
              {{ quotation_data?.client_details?.clt_address }}
            </div>
            <div class="ph6" *ngIf="quotation_data?.client_details?.clt_email">
              <span class="semibold">Email: </span>
              <span>{{ quotation_data?.client_details?.clt_email }}</span>
            </div>
            <div class="ph6" *ngIf="quotation_data?.client_details?.clt_mobile">
              <span class="semibold">Phone: </span>
              <span>+91 {{ quotation_data?.client_details?.clt_mobile }}</span>
            </div>
            <div class="ph6" *ngIf="quotation_data?.client_details?.clt_gst">
              <span class="semibold">GST: </span>
              <span>{{ quotation_data?.client_details?.clt_gst }}</span>
            </div>
            <div class="ph6" *ngIf="quotation_data?.client_details?.clt_pan">
              <span class="semibold">PAN: </span>
              <span>{{ quotation_data?.client_details?.clt_pan }}</span>
            </div>

            <ng-container
              *ngFor="let item of quotation_data?.client_details?.custom_fields"
            >
              <div class="ph6" *ngIf="item?.value">
                <span class="semibold">{{ item?.key }}: </span>
                <span>{{ item?.value }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- event Details -->
      <div
        class="event_details"
        *ngFor="let event of quotation_data?.event_details"
      >
        <div class="ptc1 ph7-bold mb-2">
          {{ event?.event_name ? event?.event_name : "Event" }}
        </div>

        <table border="0" class="invoice-table invoice-head-table mt-1 w-100">
          <tbody>
            <tr class="ptc1">
              <td *ngIf="event?.event_description" width="300">
                <div class="d-flex">
                  <span class="material-icons ptc20 mr-2"> description </span>
                  <span class="ph7">{{ event?.event_description }}</span>
                </div>
              </td>
              <td *ngIf="event?.event_location">
                <div class="d-flex">
                  <span class="material-icons ptc20 mr-2"> location_on </span>
                  <span class="ph7"> {{ event?.event_location }}</span>
                </div>
              </td>
              <td *ngIf="event?.event_date">
                <div class="d-flex">
                  <span class="material-icons ptc20 mr-2"> today </span>
                  <span class="ph7"> {{ event?.event_date | date }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--  items -->
      <div class="invoice-items-table-wrapper">
        <table
          class="invoice-table invoice-items-table"
          cellpadding="0"
          cellspacing="0"
        >
          <thead>
            <tr class="aside-collpased">
              <th width="10" aria-label="Item Number"></th>
              <th class="text-left">Service</th>
              <th *ngIf="quotation_data?.item_dtls?.is_rate_qnt_present">
                Quantity
              </th>
              <th *ngIf="quotation_data?.item_dtls?.is_rate_qnt_present">
                Rate
              </th>

              <th>Amount</th>
            </tr>
          </thead>

          <tbody class="">
            <ng-container
              *ngFor="let item of quotation_data?.item_dtls?.data; index as i"
            >
              <tr
                [ngClass]="{
                  'bg-5':
                    ((i + 1) % 2 == 0 &&
                      quotation_data?.item_dtls?.data?.length % 2 == 0) ||
                    ((i + 1) % 2 != 0 &&
                      quotation_data?.item_dtls?.data?.length % 2 != 0)
                }"
              >
                <td class="text-left ph5" style="vertical-align: baseline">
                  {{ i + 1 }}.
                </td>
                <td width="250" style="vertical-align: baseline">
                  <div class="text-left ph5-semibold">
                    {{ item?.item_name }}
                  </div>
                  <div
                    class="text-left ph6 ptc5"
                    style="white-space: pre-line"
                    *ngIf="item?.item_desc"
                  >
                    {{ item?.item_desc }}
                  </div>
                </td>
                <td
                  style="vertical-align: baseline"
                  class=""
                  *ngIf="quotation_data?.item_dtls?.is_rate_qnt_present"
                  width="10"
                >
                  {{ item?.item_qnt }}
                </td>
                <td
                  class=""
                  style="vertical-align: baseline"
                  *ngIf="quotation_data?.item_dtls?.is_rate_qnt_present"
                  width="10"
                >
                  ₹{{ item?.item_rate?.toLocaleString("en-IN") }}
                </td>

                <td class="" width="10" style="vertical-align: baseline">
                  ₹{{ item?.item_cost?.toLocaleString("en-IN") }}
                </td>
              </tr>
              <!-- <tr
                [ngClass]="{
                  'bg-5':
                    ((i + 1) % 2 == 0 &&
                      quotation_data?.item_dtls?.data?.length % 2 == 0) ||
                    ((i + 1) % 2 != 0 &&
                      quotation_data?.item_dtls?.data?.length % 2 != 0)
                }"
                *ngIf="item?.item_desc"
              >
                <td >
                  <div
                    class="text-left"
                    style="width: calc(100% - 100px); white-space: pre-line"
                  >
                    {{ item?.item_desc }}
                  </div>
                </td>
              </tr> -->
            </ng-container>
          </tbody>
        </table>
      </div>

      <!-- payment & final Amount -->
      <!-- page_break -->
      <div id="final_payment" class="d-flex justify-content-between">
        <div class="d-flex">
          <!-- payment_terms -->
          <ng-container *ngIf="quotation_data?.pay_tnc?.length > 0">
            <div class="payment_terms mt-3 mr-3" *ngIf="check_pay_tnc()">
              <div class="ptc10 bold ph5">Payment Terms</div>
              <table border="0" class="bank-info-table ph6">
                <tbody>
                  <ng-container *ngFor="let item of quotation_data?.pay_tnc">
                    <tr *ngIf="item?.pay_amount && item?.pay_before">
                      <td>Pay</td>
                      <th class="px-2">
                        ₹{{ item?.pay_amount?.toLocaleString("en-IN") }}
                      </th>
                      <td>Before</td>
                      <th class="px-2">{{ item?.pay_before }}</th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>

          <!-- bank_details -->
          <ng-container *ngIf="quotation_data?.bank_dtls">
            <div class="bank_details mt-3" *ngIf="check_bank_dtls()">
              <div class="ptc10 bold ph5">Bank Details</div>
              <table border="0" class="bank-info-table ph6">
                <tbody>
                  <tr *ngIf="quotation_data?.bank_dtls?.account_holder_name">
                    <th>Name</th>
                    <td>
                      {{ quotation_data?.bank_dtls?.account_holder_name }}
                    </td>
                  </tr>
                  <tr *ngIf="quotation_data?.bank_dtls?.account_number">
                    <th>Account No</th>
                    <td>{{ quotation_data?.bank_dtls?.account_number }}</td>
                  </tr>
                  <tr *ngIf="quotation_data?.bank_dtls?.bank_name">
                    <th>Bank</th>
                    <td>{{ quotation_data?.bank_dtls?.bank_name }}</td>
                  </tr>
                  <tr *ngIf="quotation_data?.bank_dtls?.ifsc_code">
                    <th>IFSC</th>
                    <td>{{ quotation_data?.bank_dtls?.ifsc_code }}</td>
                  </tr>
                  <tr *ngIf="quotation_data?.bank_dtls?.bank_upi">
                    <th>UPI</th>
                    <td>{{ quotation_data?.bank_dtls?.bank_upi }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>

        <!-- sub total calculation -->
        <div class="invoice-total-calculation">
          <table border="0" class="invoice-table invoice-totals-table mt-3">
            <tbody>
              <tr *ngIf="quotation_data?.cost?.item_total">
                <th>Sub Total</th>
                <td>
                  ₹{{
                    quotation_data?.cost?.item_total?.toLocaleString("en-IN")
                  }}
                </td>
              </tr>
              <tr *ngIf="quotation_data?.cost?.discount_value">
                <th>
                  Discount ({{ quotation_data?.cost?.discount_percent }}%)
                </th>
                <td>
                  ₹{{
                    quotation_data?.cost?.discount_value?.toLocaleString(
                      "en-IN"
                    )
                  }}
                </td>
              </tr>
              <tr *ngIf="quotation_data?.cost?.gst_value">
                <th>GST ({{ quotation_data?.cost?.gst_percent }}%)</th>
                <td>
                  ₹{{
                    quotation_data?.cost?.gst_value?.toLocaleString("en-IN")
                  }}
                </td>
              </tr>
              <tr *ngIf="quotation_data?.cost?.total">
                <th>Total (INR)</th>
                <td>
                  <b
                    >₹{{
                      quotation_data?.cost?.total?.toLocaleString("en-IN")
                    }}</b
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- --- terms & condition --- -->
      <ng-container *ngIf="quotation_data?.tnc?.length > 0">
        <div class="invoice-terms mt-2" *ngIf="check_tnc()">
          <div class="ptc10 bold ph5">Terms and Conditions</div>
          <ol class="invoice-terms ph6">
            <ng-container *ngFor="let term of quotation_data?.tnc">
              <li *ngIf="term?.text">{{ term?.text }}</li>
            </ng-container>
          </ol>
        </div>
      </ng-container>

      <!-- footer -->

      <div
        class="invoice-contact-wrapper"
        *ngIf="quotation_data?.contactemail || quotation_data?.contactnumber"
      >
        <span>For any enquiry, reach out via</span>
        <span *ngIf="quotation_data?.contactemail">
          &nbsp;email at <b>{{ quotation_data?.contactemail }}</b></span
        >
        <span *ngIf="quotation_data?.contactnumber"
          >, call on <b>+91 {{ quotation_data?.contactnumber }}</b></span
        >
      </div>

      <!-- logo -->
      <div class="d-flex mt-4">
        <span class="ptc9 ph8"
          >Powered By &nbsp;&nbsp;&nbsp;<img
            src="../assets/generic/banner-logo.png"
            width="80px"
            height="30px"
        /></span>
      </div>
    </ng-container>
  </div>
</div>
