<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Photeam Overview</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/{{ routesPath.PHOTEAMS }}">Photeams</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Photeam Overview
      </li>
    </ol>
  </nav>

  <!-- Page Content -->
  <div class="row tw-justify-center">
    <div class="col-md-4 col-sm-6">
      <div
        routerLink="/{{ routesPath.PHOTEAM_DETAILS }}/{{ photeam_id }}"
        class="ct-featureBox border rounded tw-cursor-pointer"
      >
        <div class="inner">
          <div class="image">
            <!-- <mat-icon>delete</mat-icon> -->
          </div>
          <h4>Photeam Details</h4>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-6">
      <div
        routerLink="/{{ routesPath.PHOTEAM_PURCHASES }}/{{ photeam_id }}"
        class="ct-featureBox border rounded tw-cursor-pointer"
      >
        <div class="inner">
          <div class="image">
            <!-- <mat-icon>delete</mat-icon> -->
          </div>
          <h4>Purchases History</h4>
        </div>
      </div>
    </div>
  </div>

  <div>
    <hr>
    <h4 class="text-center">Deliverables Details</h4>
    <app-photeam-deliverables *ngIf="deliverables" [data]="deliverables">

    </app-photeam-deliverables>
  </div>
</div>
