import { ToastrService } from 'ngx-toastr';
import { ServerCode } from './../../../../data/constants/server-code-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminDataService } from './../../../../data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from './../../../../data/dataservice/user-dataservice/data.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaymentsDataService } from 'src/app/data/dataservice/payments-dataservice/payments-data.service';
var that: any;
import * as _moment from 'moment';
import Swal from 'sweetalert2';
import { AuthGuardService } from 'src/app/data/dataservice/auth-dataservice/auth-guard.service';
import { MembershipConstants } from 'src/app/data/constants/membership-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-upgrade-user-membership-v2',
  templateUrl: './upgrade-user-membership-v2.component.html',
  styleUrls: ['./upgrade-user-membership-v2.component.css']
})
export class UpgradeUserMembershipV2Component implements OnInit {

  @ViewChild('no_photeam_modal', { static: true }) no_photeam_modal: ElementRef;
  modalRef: any;

  constructor(
    private authService: AuthGuardService,
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private paymentService: PaymentsDataService,
    private modalService: NgbModal,
  ) {
    that = this;
    this.user_id = this.route.snapshot.params.user_id;

    if (this.user_id != null && this.user_id != undefined && this.user_id != '') {
      this.fetchUserDetailsById();
    } else {
      this.router.navigateByUrl('/membership');
    }
  }

  user_id: any;
  user: any = null;
  photeam_list = [];
  selected_photeam = null;
  photeam_id = null;

  photeam_deliverables_data = MembershipConstants.FREE;
  coupon_code: string = '';   // 'EARLYBIRD'
  cart: any = {
    cart_items: [],
    total_price: {
      base_price_total: 0,
      final_price_total: 0,
      internal_discount_total: 0,
    },
    cart_coupon: {
      is_coupon_applied: false,
      coupon_code: '',
    },
    cart_discount: {
      discount_type: 'general',
      value_type: 'percentage',
      value: 0,
      amount: 0,
      round_off_amount: 0,
      round_off_payable_after_discount: 0
    },
    cart_gst: {
      value_type: 'percentage',
      value: 18,
      amount: 0,
      price_including_gst: 0,
      price_excluding_gst: 0
    },
    payable_amount: 0,
  }

  product_list_master: any[] = [];
  coupon_list_master = [];

  ngOnInit(): void {
  }

  getSelectedPhoteamFromSelectionDropdown(photeam: any): void {
    console.log('selcted photeam from dropdown in dashboard section : ', photeam);
  }

  payment_data: any = {
    received_by: '',
    payment_mode: '',
    payment_date: (new Date()).toISOString().substring(0, 10),
    img: '',
  }

  onSelectFile(event: any) {
    var file = event.target.files[0];
    if (event.target.files && file) {

      const Filetype = (file.type).split("/")[0];
      if (Filetype.toLowerCase() == 'image') {
        var reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event: any) => { // called once readAsDataURL is completed
          this.payment_data.img = event.target.result;
        }
      } else {
        this.toastr.error('Please upload a valid Image', 'Wrong Format')
      }

    }
  }

  fetchData() {
    if (this.isVarExist(this.user_id)) {
      this.is_user_allowed()
    }
  }

  fetchUserDetailsById() {
    this.dataService.getUserById("fetch user by id", this.user_id, this.successCB, this.errorCB);
  }

  // fetchUserData() {
  //   this.user_id = this.authService.getUserIdfromJWT();
  //   if (this.user_id != '') {
  //     this.dataService.getUserById('get user data by id', this.user_id, this.successCB, this.errorCB);
  //   }
  // }

  is_user_allowed() {
    if (this.user.is_photographer == 0) {
      this.toastr.error('You are not allowed to access this page.', 'Error');
      this.router.navigateByUrl('/dashboard');
    } else {
      this.fetchProducts();
      this.fetchPhoteamListCreatedByUser();
    }
  }

  fetchPhoteamListCreatedByUser() {
    this.dataService.fetchPhoteamListCreatedByUser("fetch photeam list created by user", this.user_id, this.successCB, this.errorCB);
  }


  is_photeam_valid() {
    if (this.photeam_list.length <= 0) {
      this.openModal()
    } else {
      // that.toastr.success('photeam found')
      this.fetch_photeam_deliverables_data()
    }
  }

  openModal() {
    this.modalRef = this.modalService.open(this.no_photeam_modal, {
      centered: true, size: 'md', backdrop: 'static',
      keyboard: false
    });
  }

  onModalClose() {
    this.modalRef.close();
  }

  CreateStudio() {
    this.router.navigateByUrl('/');
    this.onModalClose()
  }

  fetch_photeam_deliverables_data() {
    if (this.isVarExist(this.photeam_id)) {
      this.dataService.getPhoteamDeliverablesData('get photeam deliverables data by photeam_id', this.photeam_id, this.successCB, this.errorCB);
    } else {
      this.toastr.error('Studio not loaded')
    }
  }

  // ------ Validation ----------
  isVarExist(value) {
    if (value != null && value != undefined && value != "") {
      return true;
    }
    return false;
  }
  // ------ Validation Ends -----

  fetchProducts() {
    this.dataService.fetchProductList("fetch Products list", this.successCB, this.errorCB);
  }

  fetchCouponsWithProducts() {
    this.dataService.fetchCouponListWithProduct("fetch Coupons with Products", this.successCB, this.errorCB);
  }


  addProductToCart(product: any) {
    if (product) {
      let is_item_exist = this.cart.cart_items.find((cart_item: any) => cart_item.product_code == product.product_code);
      if (is_item_exist) {
        this.removeProductFromCart(is_item_exist);
        return;
      }

      if (product.category == "PACKAGE") {

        if (this.photeam_deliverables_data?.active_plans?.studio_plan) {
          this.toastr.error('Studio plan already exist')
          return;
        }

        product.selected = true;

        this.cart.cart_items.forEach((cart_item: any, index: any, cart_items_array: any) => {
          if (cart_item.category == "PACKAGE" && cart_item.product_code != product.product_code) {
            cart_items_array.splice(index, 1);
            this.product_list_master.find((obj: any) => obj.product_code == cart_item.product_code).selected = false;
            // console.log('remove pkg', cart_item);
          }
        });

      }

      var cart_item: any = {
        product_code: product.product_code,
        category: product.category,
        type: product.type,
        name: product.name,
        desc: product.desc,
        data: {
          deliverable: product.data.deliverable,
        },
        unit_base_price: product.base_value,
        unit_final_price: product.final_value,
        unit_count: 1,
        total_base_price: product.base_value,
        total_final_price: product.final_value,
        coupon: {
          is_coupon_applied: false,
          applied_coupon_code: '',
        },
        discount: {
          discount_type: 'general',
          value_type: 'percentage',
          value: 0,
          amount: 0,
          round_off_amount: 0,
          round_off_payable_after_discount: 0,
        },
        price_after_discount: 0,
        gst: {
          value_type: 'percentage',
          value: 18,
          amount: 0,
          price_including_gst: 0,
          price_excluding_gst: 0
        },
        validity: product.validity,

        final_price: product.value,
      }

      const calc_discount = (cart_item: any) => {
        if (product.discount) {
          cart_item.discount.value_type = product.discount.discount_type;
          cart_item.discount.value = product.discount.value;
          this.calculate_cart_item_discount(cart_item);
        } else {
          cart_item.discount.amount = 0;
          cart_item.discount.round_off_amount = 0;
          cart_item.discount.round_off_payable_after_discount = cart_item.total_final_price;
          cart_item.price_after_discount = cart_item.discount.round_off_payable_after_discount;
        }
      }

      const calc_gst = (cart_item: any) => {
        if (product.gst) {
          cart_item.gst.value_type = product.gst.gst_type;
          cart_item.gst.value = product.gst.value;
          this.calculate_cart_item_gst(cart_item);
        } else {
          cart_item.gst.price_including_gst = cart_item.price_after_discount;
          cart_item.gst.price_excluding_gst = Number((cart_item.price_after_discount / 1.18).toFixed(2));
          cart_item.gst.amount = Number((cart_item.gst.price_including_gst - cart_item.gst.price_excluding_gst).toFixed(2));
        }

      }

      calc_discount(cart_item);
      calc_gst(cart_item);
      if (product.category == "PACKAGE") {
        this.cart.cart_items.splice(0, 0, cart_item);
      } else {
        this.cart.cart_items.push(cart_item);
      }
      this.updateCartTotalAmount();

    }
  }

  // insertItemAtBegining(item: any) {
  //   this.cart.cart_items.unshift(item);

  // }

  calculate_cart_item_discount(cart_item) {
    cart_item.discount.amount = cart_item.discount.value_type == 'percentage' ? cart_item.total_base_price * cart_item.discount.value / 100 : cart_item.discount.value;
    cart_item.discount.round_off_amount = Math.floor(cart_item.discount.amount);
    cart_item.discount.round_off_payable_after_discount = Math.ceil(cart_item.total_base_price - cart_item.discount.amount);
    cart_item.price_after_discount = cart_item.discount.round_off_payable_after_discount;
  }

  calculate_cart_item_gst(cart_item) {
    cart_item.gst.price_including_gst = cart_item.price_after_discount;
    cart_item.gst.price_excluding_gst = Number((cart_item.price_after_discount / 1.18).toFixed(2));
    cart_item.gst.amount = Number((cart_item.gst.price_including_gst - cart_item.gst.price_excluding_gst).toFixed(2));
  }

  productCountChange(product, updated_count) {
    // console.log('productCountChange', product.count, updated_count);
    this.onProductCountChange(product, updated_count);
  }

  updateProductCount(product, updated_count) {
    // console.log('updateProductCount', product.count, updated_count);
    this.onProductCountChange(product, updated_count);
  }

  onProductCountChange(product, updated_count) {
    product.count = updated_count;
    let count = product.count;
    // console.log('onProductCountChange', product.product_code, count);

    let cart_item = this.cart.cart_items.find(item => item.product_code == product.product_code);

    if (!cart_item) {
      this.addProductToCart(product);
    } else if (cart_item && count == 0) {
      this.removeProductFromCart(cart_item);
    } else if (cart_item) {
      this.updateProductCountInCart(cart_item, count);
    }
  }

  updateProductCountInCart(cart_item, count) {

    cart_item.unit_count = count;
    cart_item.total_base_price = cart_item.unit_count * cart_item.unit_base_price;
    cart_item.total_final_price = cart_item.unit_count * cart_item.unit_final_price;
    // cart_item.payable_amount = cart_item.total_final_price - cart_item.discount.amount;

    if (cart_item.product_code == 'ADD_01') {
      this.check_coin_discount(cart_item);
    }

    if (cart_item.product_code == 'ADD_02' || cart_item.product_code == 'ADD_03') {
      cart_item.data.deliverable.quantity = count;
      cart_item.data.deliverable.total_value = cart_item.data.deliverable.unit_value * count;
    }

    this.calculate_cart_item_discount(cart_item);
    this.calculate_cart_item_gst(cart_item);

    this.updateCartTotalAmount();
  }


  removeProductFromCart(cart_item) {
    // console.log('removeProductFromCart', cart_item.product_code);
    this.cart.cart_items.splice(this.cart.cart_items.indexOf(cart_item), 1);
    let product = this.product_list_master.find(product => product.product_code == cart_item.product_code);
    product.selected = false;
    product.count = 0;
    this.updateCartTotalAmount();
  }

  // ----- change validity --------

  productValidityChange(product, updated_validity) {
    // console.log('productValidityChange', product.validity.validity, updated_validity);
    // if (updated_validity > 0) {
    this.onProductValidityChange(product, updated_validity);
    // }

  }

  updateProductValidity(product, updated_validity) {
    // console.log('updateProductValidity', product.validity.validity, updated_validity);
    this.onProductValidityChange(product, updated_validity);
  }

  onProductValidityChange(product, updated_validity) {

    if (!(updated_validity > 0)) {
      // console.log('negative');
      product.validity.validity = 1
      return;
    }


    product.validity.validity = updated_validity;
    let validity = product.validity.validity;
    console.log('onProductValidityChange', product.product_code, validity);

    let cart_item = this.cart.cart_items.find(item => item.product_code == product.product_code);

    if (cart_item) {
      if (cart_item.validity) {
        let now = _moment();
        cart_item.validity.renew_at = (now.add(cart_item.validity.validity, cart_item.validity.validity_type).format("YYYY-MM-DD"));
      }
    }
  }

  // ---------- change validity -------


  cost_breakup;

  onPayableAmountChange(payable_amount) {

    const calc_discount = () => {
      let cart_discount = this.cart.cart_discount;
      // cart_discount.amount = cart_discount.value_type == 'percentage' ? this.cart.total_price.final_price_total * cart_discount.value / 100 : cart_discount.value;

      let total_price = this.cart.total_price.final_price_total;
      let discount_value = total_price - payable_amount;
      let discount_percentage = Number((discount_value / total_price * 100).toFixed(2));

      cart_discount.discount_type = 'by_admin';
      cart_discount.value_type = 'percentage';
      cart_discount.value = discount_percentage;
      cart_discount.amount = discount_value;
      cart_discount.round_off_amount = Math.floor(cart_discount.amount);
      cart_discount.round_off_payable_after_discount = Math.ceil(this.cart.total_price.final_price_total - cart_discount.amount);

    }

    const calc_gst = () => {
      let cart_gst = this.cart.cart_gst;
      cart_gst.price_including_gst = this.cart.cart_discount.round_off_payable_after_discount;
      cart_gst.price_excluding_gst = Number((this.cart.cart_gst.price_including_gst / 1.18).toFixed(2));
      cart_gst.amount = Number((cart_gst.price_including_gst - cart_gst.price_excluding_gst).toFixed(2));
    }

    calc_discount();
    calc_gst();
    this.calculate_cost_breakup();

  }


  updateCartTotalAmount() {
    this.cart.total_price.base_price_total = 0;
    this.cart.total_price.final_price_total = 0;
    this.cart.total_price.internal_discount_total = 0;

    this.cart.cart_items.forEach(item => {
      this.cart.total_price.base_price_total += item.total_base_price;
      this.cart.total_price.final_price_total += item.total_final_price;
      this.cart.total_price.internal_discount_total += item.discount.round_off_amount
    });

    this.check_combo_offer()

    const calc_discount = () => {
      let cart_discount = this.cart.cart_discount;
      cart_discount.amount = cart_discount.value_type == 'percentage' ? this.cart.total_price.final_price_total * cart_discount.value / 100 : cart_discount.value;
      cart_discount.round_off_amount = Math.floor(cart_discount.amount);
      cart_discount.round_off_payable_after_discount = Math.ceil(this.cart.total_price.final_price_total - cart_discount.amount);
    }

    const calc_gst = () => {
      let cart_gst = this.cart.cart_gst;
      cart_gst.price_including_gst = this.cart.cart_discount.round_off_payable_after_discount;
      cart_gst.price_excluding_gst = Number((this.cart.cart_gst.price_including_gst / 1.18).toFixed(2));
      cart_gst.amount = Number((cart_gst.price_including_gst - cart_gst.price_excluding_gst).toFixed(2));
    }

    calc_discount();
    calc_gst();

    this.cart.payable_amount = this.cart.cart_discount.round_off_payable_after_discount;
    this.calculate_cost_breakup();

  }

  check_combo_offer() {
    let cart_discount = this.cart.cart_discount;
    cart_discount.value_type == 'percentage'

    let cart_items = this.cart.cart_items;
    const is_brand = cart_items.some(el => el.product_code === 'PKG_01');
    const is_cloud = cart_items.some(el => el.product_code === 'ADD_02');
    const is_billing = cart_items.some(el => el.product_code === 'ADD_03');


    if (((is_brand && is_cloud) || (is_cloud && is_billing) || (is_brand && is_billing)) && (this.cart.cart_items.length == 2)) {
      cart_discount.discount_type = 'combo_offer'
      cart_discount.value = 5
    } else if (is_brand && is_cloud && is_billing) {
      cart_discount.discount_type = 'combo_offer'
      cart_discount.value = 10
    } else {
      cart_discount.discount_type = 'general'
      cart_discount.value = 0
    }
  }

  calculate_cost_breakup() {
    this.cost_breakup = {
      txn_amount: this.cart.payable_amount,
      is_gateway_txn: false,
      gateway_charges: {
        on_amount: this.cart.payable_amount,
        service_charge: {
          on: 0,
          value_type: 'percentage',
          value: 0,
          amount: 0
        },
        service_tax: {
          on: 0,
          value_type: 'percentage',
          value: 0,
          amount: 0
        },
        total: 0,
        in_hand: 0
      },
      gst: {
        on: 0,
        value_type: 'percentage',
        value: 18,
        amount: 0,
        price_excluding_gst: 0
      },
      in_hand: 0
    }

    const calc_gateway_charges = () => {
      let gateway = this.cost_breakup.gateway_charges;
      gateway.service_charge.on = this.cart.payable_amount;
      gateway.service_charge.amount = Number(((gateway.service_charge.on * gateway.service_charge.value) / 100).toFixed(2))

      gateway.service_tax.on = gateway.service_charge.amount;
      gateway.service_tax.amount = Number(((gateway.service_tax.on * gateway.service_tax.value) / 100).toFixed(2))
      gateway.total = Number((gateway.service_charge.amount + gateway.service_tax.amount).toFixed(2))
      gateway.in_hand = Number((gateway.on_amount - gateway.total).toFixed(2))
    }

    const calc_gst_charges = () => {
      let gst = this.cost_breakup.gst;
      gst.on = this.cost_breakup.gateway_charges.in_hand;
      gst.price_excluding_gst = Number((gst.on / 1.18).toFixed(2));
      gst.amount = Number((gst.on - gst.price_excluding_gst).toFixed(2));
    }
    calc_gateway_charges();
    calc_gst_charges();
    this.cost_breakup.in_hand = this.cost_breakup.gst.price_excluding_gst;
  }


  check_coin_discount(item) {
    // if (item.unit_count > 9 && item.discount.value == 0) {
    //   console.log("add_coin_discount");
    //   item.discount.value_type = 'percentage';
    //   item.discount.value = 10;
    // }
    // else if (item.unit_count < 10 && item.discount.value != 0) {
    //   console.log("remove_coin_discount");
    //   item.discount.value_type = 'percentage';
    //   item.discount.value = 0;
    // }
  }


  applyCoupon(coupon) {

    this.toastr.error('Inavlid Coupon Code');
    return;


    let coupon_item = this.coupon_list_master.find(item => item.coupon_code == coupon);
    if (coupon_item) {

      if (coupon_item.applicable_on == 'PRODUCT') {
        let applicable_products = coupon_item.products

        if (applicable_products.length > 0) {
          applicable_products.forEach(product => {
            let cart_item = this.cart.cart_items.find(item => item.product_code == product.product_code);

            if (cart_item) {

              if (!cart_item.coupon_code) {
                this.toastr.success(coupon_item.coupon_code, 'Coupon Applied');
              }
              cart_item.coupon.is_coupon_applied = true;
              cart_item.coupon.applied_coupon_code = coupon_item.coupon_code;

              cart_item.discount.discount_type = 'coupon';
              cart_item.discount.value_type = coupon_item.discount.discount_type;
              cart_item.discount.value = coupon_item.discount.value;

              this.calculate_cart_item_discount(cart_item);
              this.calculate_cart_item_gst(cart_item);
              this.updateCartTotalAmount();

            } else {
              this.toastr.error('Product not found in cart', 'Coupon Not Applied');
            }
          });
        } else {
          this.toastr.error('Coupon Not applicable for any product');
        }
      }

      if (coupon_item.applicable_on == 'CART') {
        if (!this.cart.cart_coupon.is_coupon_applied) {
          this.toastr.success(coupon_item.coupon_code, 'Coupon Applied');
        }
        this.cart.cart_coupon.is_coupon_applied = true;
        this.cart.cart_coupon.coupon_code = coupon_item.coupon_code;

        this.cart.cart_discount.discount_type = 'coupon';
        this.cart.cart_discount.value_type = coupon_item.discount.discount_type;
        this.cart.cart_discount.value = coupon_item.discount.value;

        this.updateCartTotalAmount();
      }



    } else {
      this.toastr.error('Inavlid Code');
    }
  }

  SearchCoupon() {
    if (this.coupon_code != '' && this.coupon_code != null) {
      this.applyCoupon(this.coupon_code);
    } else {
      this.toastr.error('Invalid Coupon Code');
    }
  }

  removeCoupon() {
    // this.toastr.error(cart.discount.coupon_code, 'Coupon Removed.');

  }



  is_form_valid() {
    if (this.cart.cart_items.length == 0) {
      this.toastr.error('Please add atleast one item');
      return false;
    }
    if (!(this.cart.payable_amount > 0)) {
      this.toastr.error('Invalid Amount.');
      return false;
    }


    if (this.payment_data.received_by == '' || this.payment_data.received_by == null) {
      this.toastr.error('Please enter received by.');
      return false;
    }

    if (this.payment_data.payment_mode == '' || this.payment_data.payment_mode == null) {
      this.toastr.error('Please enter payment mode.');
      return false;
    }
    return true;
  }

  placeOrder() {
    if (this.is_form_valid()) {
      Swal.fire({
        title: 'Are you sure?',
        // text: 'You will change the state!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Procced!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          if (this.is_form_valid() && this.isVarExist(this.photeam_id) && this.isVarExist(this.user_id)) {
            this.paymentService.create_offplatform_Order_Txn("create payment order", this.user.id, this.photeam_id, this.cart, this.cost_breakup, this.payment_data, this.successCB, this.errorCB);
          }
        }
      })
    }
  }

  product_pkg_list_master: any = [];
  product_add_ons_list_master: any = [];

  filter_products() {
    // const pkg = () => {
    that.product_list_master = that.product_list_master.filter(item => item.is_active == 1)
    that.product_list_master.forEach(item => {

      item.selected = false;
      if (item.category == 'PACKAGE') {
        if (item.validity) {
          let now = _moment();
          item.validity.start_at = (now.format("YYYY-MM-DD"));
          item.validity.renew_at = (now.add(item.validity.validity, item.validity.validity_type).format("YYYY-MM-DD"));
        }

      }

      if (item.category == 'ADDONS') {
        item.count = 0;

        if (item.validity) {
          let now = _moment();
          item.validity.start_at = (now.format("YYYY-MM-DD"));
          item.validity.renew_at = (now.add(item.validity.validity, item.validity.validity_type).format("YYYY-MM-DD"));
        }

      }
    });
    // }

    // const add_ons = () => {
    // that.product_add_ons_list_master = that.product_list_master.filter(item => item.category == 'ADDONS')
    // }

    // pkg();
    // add_ons();
  }



  successCB(results: any) {
    if (results.api == "fetch Products list") {
      if (results.servercode == ServerCode.SUCCESS) {

        that.product_list_master = results.data
        // console.log("product_list_master", that.product_list_master)

        that.filter_products();

        let product = that.product_list_master.find(item => item.product_code == 'PKG_01');
        that.addProductToCart(product);
        that.fetchCouponsWithProducts();
      }
    } else if (results.api == "fetch Coupons with Products") {
      if (results.servercode == ServerCode.SUCCESS) {

        that.coupon_list_master = results.data
        // console.log("coupon_list_master", that.coupon_list_master)
        // that.applyCoupon('EARLYBIRD');
      }
    } else if (results.api == "create payment order") {
      if (results.servercode == ServerCode.SUCCESS) {

        that.fetchUserDetailsById();
        that.toastr.success('Order Placed Successfully');

      } else {
        that.toastr.error('something went wrong');
      }
    } else if (results.api == "fetch user by id") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.user = results.data;
          that.is_user_allowed();
          if (that.user != null && that.user != undefined && that.user.is_photographer == 1) {
            that.fetchProducts();
          } else {
            that.toastr.error('Not a photographer');
            that.router.navigateByUrl('/');
          }
        }
      }
    }

    else if (results.api == 'get photeam deliverables data by photeam_id') {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data) {
          that.photeam_deliverables_data = results.data?.deliverable;
        }
      }
    } else if (results.api == 'fetch photeam list created by user') {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          if (results.data.length > 0) {
            that.photeam_list = results.data;
            that.selected_photeam = that.photeam_list[0]
            that.photeam_id = that.selected_photeam.photeam_data.photeam_id;
            // console.log("fetch photeam list created by user --- dashboard comp");
          }
        }
      }
      that.is_photeam_valid()
    }
  }

  errorCB(error: any) {
    // console.log(error);
  }


}
