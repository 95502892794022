import { Component, OnInit } from '@angular/core';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

var that: any;

@Component({
  selector: 'app-master-genre-lists',
  templateUrl: './master-genre-lists.component.html',
  styleUrls: ['./master-genre-lists.component.css']
})
export class MasterGenreListsComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private modalService: NgbModal
  ) { that = this; }

  genreList: any;
  genreData: any;
  formType = '';

  open(content: any) {
    this.modalService.open(content);
  }

  fetchGenreList() {
    this.dataService.getGenreList("fetch genre list", this.successCB, this.errorCB);
  }

  editAction(item: any) {
    this.formType = "edit"
    this.genreData = item;
    // console.log(this.genreData);
  }

  addAction() {
    this.formType = "add"
    this.genreData = {
      genre_id: "",
      genre_name: "",
      img_src: "",
      mobile_img_src: "",
      sort_order: -1,
      color:""
    };
  }
  updateGenre() {

    if (this.genreData.genre_id != "" && this.genreData.genre_name != "" && this.genreData.img_src != "" && this.genreData.mobile_img_src != "") {
      // console.log("new_values", this.genreData);
      Swal.fire({
        title: 'Are you sure?',
        // text: 'You will change the state!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {

          this.adminDataService.addMasterGenreData("update master genre data", this.genreData, this.successCB, this.errorCB);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )

          // console.log(e.source.checked );
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Some Feilds are emplty!',
        showConfirmButton: false,
        timer: 1500
      })
    }



  }
  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.fetchGenreList();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order: []
    };
  }

  successCB(results: any) {

    if (results.api == "fetch genre list") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.genreList = results.data;
          console.log("genre_list", results.data);

        }
      }
    }

    if (results.api == "update master genre data") {
      if (results.servercode == ServerCode.SUCCESS) {

        Swal.fire({
          icon: 'success',
          title: 'Changes saved!',
          showConfirmButton: false,
          timer: 1500
        })

        that.fetchGenreList();
      }
    }


  }

  errorCB(error: any) {
    console.log(error);
  }

}
