
import { Component, OnInit } from '@angular/core';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
var that: any;
@Component({
  selector: 'app-photeam-detail',
  templateUrl: './photeam-detail.component.html',
  styleUrls: ['./photeam-detail.component.css']
})
export class PhoteamDetailComponent implements OnInit {
  view_portfolio_img: any;
  routesPath = AdminRoutesPath;
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    that = this;
  }

  photeam_id: any;
  photeam_dtls: any;
  url_dp: any;
  storageURL = GlobalConstants.storageURL;
  photeam_bank: any;
  selected_genre = 'all';
  selected_genre_name: any;
  show_img = false;
  photeam_description: any;

  showimg() {
    this.show_img = !this.show_img;
  }

  selectedGenre(id: any, name: any) {
    this.selected_genre = id;
    this.selected_genre_name = name;
  }

  fetchPhoteamDetailsById() {
    // console.log("Fetching photeam details");
    this.dataService.getPhoteamDetailsById("fetch photeam by id", this.photeam_id, this.successCB, this.errorCB);
  }
  genre_img_count: any = {};
  super_highlight_image: any;

  calculateImgCount() {
    for (let genre of this.photeam_dtls.photeam_service.genres) {
      let count = this.photeam_dtls.photeam_portfolio_images.filter((obj: any) => obj.genre_id == genre.genre_id).length;
      let is_highlighted = this.photeam_dtls.photeam_portfolio_images.filter((obj: any) => (obj.genre_id == genre.genre_id && obj.is_highlighted == 1)).length;
      var values = {
        // "genre_id": genre,
        "img_count": count,
        "highlighted_count": is_highlighted
      }
      // this.genre_img_count.push(values)
      this.genre_img_count[genre.genre_id] = values;
    }
  }


  fetchPhoteamBankAccountByPhoteamId() {
    // console.log("Fetching photeam details");
    this.dataService.getPhoteamBankAccountByPhoteamId("fetch bank_account by photeam_id", this.photeam_id, this.successCB, this.errorCB);
  }

  update_description() {
    console.log("Updating description");
    Swal.fire({
      title: 'Are you sure?',
      text: 'Your Changes will affect live data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.adminDataService.updatePhoteamDescription("update description by photeam_id", this.photeam_id, this.photeam_dtls.photeam.photeam_description, this.successCB, this.errorCB);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })

  }

  toggle_state(photeam_id: any, value: boolean, state: any, e: any) {
    console.log("passed_value", value);
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will change the state!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {

        if (state == 'is_bank_verified') {
          this.adminDataService.changePhoteamBankState("change photeam bank state", photeam_id, value, this.successCB, this.errorCB);
        }


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
        e.source.checked = !e.source.checked;
        this.photeam_bank.is_verified = !value;
        // console.log(e.source.checked );
      }
    })

  }

  open_img_model(view_img_model: any, img: any) {
    this.view_portfolio_img = img;
    this.modalService.open(view_img_model, { size: 'lg' })
  }

  img_delete(img_id: any) {

    // if (this.genre_img_count[genre_id].img_count < 6) {
    //   this.toastr.error("Minimum 5 images in each genre is required");
    // } else {

    // }

    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete the Image!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.dataService.deletePhoteamGenreImagesByFileId("delete img by file_id", img_id, this.successCB, this.errorCB);
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }



  highlightImage(item: any) {

    if (item.is_highlighted == 1) {
      this.dataService.highlightImageById('highlight image', item.id, this.successCB, this.errorCB);
    } else {
      if (this.genre_img_count[item.genre_id].highlighted_count < 5 && item.is_highlighted == 0) {
        this.dataService.highlightImageById('highlight image', item.id, this.successCB, this.errorCB);
      } else {
        this.toastr.error("maximum 5 highlighted images in each genre is allowed");
      }
    }
  }

  superhighlightImage(item: any) {
    if (item.is_super_highlighted == 1) {
      this.dataService.superhighlightImageById('super highlight image', item.id, this.successCB, this.errorCB);
    }
    else {
      if (this.super_highlight_image < 5 && item.is_super_highlighted == 0) {
        this.dataService.superhighlightImageById('super highlight image', item.id, this.successCB, this.errorCB);
      } else {
        this.toastr.error("maximum 5 highlighted images in each genre is allowed");
      }
    }
  }

  ngOnInit(): void {

    this.photeam_id = this.route.snapshot.params.photeam_id;

    if (this.photeam_id != null && this.photeam_id != undefined) {
      this.fetchPhoteamDetailsById();
      this.fetchPhoteamBankAccountByPhoteamId();
    } else {
      that.router.navigateByUrl('/404');
    }
  }

  successCB(results: any) {
    if (results.api == "fetch photeam by id") {
      // console.log(results);
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {

          that.photeam_dtls = results.data;
          console.log("photeam_dtl", that.photeam_dtls);

          if (that.photeam_dtls.photeam.profile != null) {
            that.url_dp = that.storageURL + '/photeam/' + that.photeam_dtls.photeam.photeam_id + '/display/' + that.photeam_dtls.photeam.profile;
          }
          that.calculateImgCount();
          that.super_highlight_image = that.photeam_dtls.photeam_portfolio_images.filter((obj: any) => (obj.is_super_highlighted == 1)).length;

          // if (that.photeam_dtls.photeam.banner != null) {
          //   that.url_banner = that.storageURL + '/photeam/' + that.photeam_dtls.photeam.photeam_id + '/banner/' + that.photeam_dtls.photeam.banner;
          // }

        }
      }
    } if (results.api == "fetch bank_account by photeam_id") {
      // console.log(results);
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {

          that.photeam_bank = results.data;
          console.log("bank_dt;", that.photeam_bank);

        }
      }
    } if (results.api == "update description by photeam_id") {
      // console.log(results);
      if (results.servercode == ServerCode.SUCCESS) {

        that.fetchPhoteamDetailsById();
        Swal.fire({
          icon: 'success',
          title: 'Your changes has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
    if (results.api == "change photeam bank state") {
      console.log(results);
      if (results.servercode == ServerCode.SUCCESS) {

        that.fetchPhoteamDetailsById();
        Swal.fire({
          icon: 'success',
          title: 'Your changes has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }

    if (results.api == "delete img by file_id") {
      console.log(results);
      if (results.servercode == ServerCode.SUCCESS) {

        that.fetchPhoteamDetailsById();
        Swal.fire({
          icon: 'success',
          title: 'Your changes has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }

    if (results.api == "highlight image") {
      if (results.servercode == ServerCode.SUCCESS) {
        that.toastr.success('Image highlighted');
        that.fetchPhoteamDetailsById();


        // let obj = that.images.find(o => o.id === results.file_id);
        // if (obj.is_highlighted == 0) {
        //   that.toastr.success('Image highlighted');
        // }
        // obj.is_highlighted = !obj.is_highlighted;
      }
    }

    if (results.api == "super highlight image") {
      if (results.servercode == ServerCode.SUCCESS) {
        that.toastr.success('Super highlighted');
        that.fetchPhoteamDetailsById();
        that.super_highlight_image = that.photeam_dtls.photeam_portfolio_images.filter((obj: any) => (obj.is_super_highlighted == 1)).length;
      }
    }
    // else if (results.api == "fetch genre list") {
    //   console.log(results);
    // }
  }

  errorCB(error: any) {
    console.log(error);
  }

}
