<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Bookings</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Booking</li>
    </ol>
  </nav>

  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">All bookings List</h6>
    </div>
    <div class="card-body">
      <div
        class="table-responsive"
        *ngIf="booking_list?.length > 0"
        style="height: 800px"
      >
        <table
          datatable
          [dtOptions]="dtOptions"
          class="table"
          id="dataTable"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>Ref_ID</th>
              <th>Photeam</th>
              <th>Customer</th>
              <th>Pkg Amount</th>
              <th>User Amount</th>
              <th>Final Amount</th>
              <th>Booking Status</th>
              <th>Payment Status</th>
              <th>Received</th>
              <th>Created at</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let item of booking_list"
              [ngClass]="{ deleted: item.is_deleted == 1 }"
            >
              <td
                title="{{ item.booking_id }}"
                class="cursor-pointer text-primary"
                routerLink="/{{routesPath.PHOTEAMS}}/{{ item.booking_id }}"
              >
                {{ item.reference_id }}
              </td>
              <td
                title="{{ item.photeam.photeam_id }}"
                class="cursor-pointer text-warning"
                routerLink="/{{routesPath.PHOTEAMS}}/{{ item.photeam.photeam_id }}"
              >
                {{ item.photeam.photeam_name }}
              </td>
              <td
                title="{{ item.user.id }}"
                class="cursor-pointer text-primary"
                routerLink="/{{routesPath.USERS}}/{{ item.user.id }}"
              >
                {{ item.user.name }}
              </td>

              <!-- <td>
                {{photeam.is_completed==1?"completed":"Incomplete"}}
                <mat-slide-toggle [(ngModel)]="photeam.is_completed"  (change)='toggle_state(photeam.photeam_id,photeam.is_completed,"is_completed",$event)' > </mat-slide-toggle>
              </td>
              <td>
                {{photeam.is_verified==1?"verified":"Unverified"}}
                <mat-slide-toggle [(ngModel)]="photeam.is_verified" (change)='toggle_state(photeam.photeam_id,photeam.is_verified,"is_verified",$event)' > </mat-slide-toggle>
              </td>
              <td>
                {{photeam.is_public==1?"Live":"Hidden"}}
                <mat-slide-toggle [(ngModel)]="photeam.is_public" (change)="toggle_state(photeam.photeam_id,photeam.is_public,'is_public',$event)"  > </mat-slide-toggle>
              </td>
              <td >
                {{photeam.is_deleted==1?"Deleted":"Active"}}
                <mat-slide-toggle [(ngModel)]="photeam.is_deleted" (change)='toggle_state(photeam.photeam_id,photeam.is_deleted,"is_deleted",$event)' > </mat-slide-toggle>
              </td>
              <td > <span class="d-none">{{photeam.created_at | date:'yMMdd'}}</span> {{photeam.created_at | date: 'MMMM d, y, h:mm:ss a'}}</td>
              <td>{{photeam.deleted_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
              <td title="Original Pkg Amount">{{ item.pkg_amount }}</td>
              <td title="Amount Requested by User">{{ item.user_amount }}</td>
              <td title="Final Amount by Photographer">
                {{ item.final_amount }}
              </td>
              <td>{{ item.booking_status }}</td>
              <td>{{ item.payment_status }}</td>

              <td>{{ item.total_amount_received }}</td>
              <!-- <td>{{ item.total_amount_dispersed }}</td> -->
              <td [title]="item.created_at | date: 'MMMM d, y, h:mm:ss a'">
                {{ item.created_at | date: "d-MMM-yy" }}
              </td>
              <!-- <td>{{item.deleted_at | date: 'MMMM d, y, h:mm:ss a'}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
