import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';
var that: any;

@Component({
  selector: 'app-billing-dashboard',
  templateUrl: './billing-dashboard.component.html',
  styleUrls: ['./billing-dashboard.component.css']
})

export class BillingDashboardComponent implements OnInit {
  routesPath = AdminRoutesPath;
  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
  ) { that = this; }

  dtOptions: DataTables.Settings = {};
  ngOnInit(): void {

    this.fetchAllBillingData()

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: []
    };
  }

  billing_list: any;

  fetchAllBillingData() {
    this.adminDataService.getAllBillingData("fetch all billing data", this.successCB, this.errorCB);
  }

  successCB(results: any) {
    if (results.api == "fetch all billing data") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.billing_list = results.data;
          console.log("billing_list_data", results.data);
        }
      }
    }
  }

  errorCB(error: any) {
    console.log(error);
  }

}
