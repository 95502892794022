<div class="container">

  <div>
    user_type : {{user.membership_type}}
  </div>
  <div class="row" style="padding-top: 60px">
    <!-- center -->
    <div class="col-lg-8">
      <div class="p-3 bg-white rounded border">
        <div class="ph5-bold ptc1">Overview</div>

        <div class="cart">
          <div class="cart-item my-4" *ngFor="let item of cart.items">
            <div class="justify-content-between d-flex">
              <div style="max-width: 80%">
                <div class="ph5-semibold ptc1">{{ item.name }}</div>
                <div class="ph6 ptc5">
                  {{ item.desc }}
                </div>
                <div class="ph7 ptc8 my-2">See Details</div>

                <div
                  class="quantity my-2"
                  *ngIf="item.product_category == 'COIN'"
                >
                  <span
                    class="quantity__minus"
                    (click)="updateItemCount(item, 'minus')"
                    >-</span
                  >
                  <input
                    id="item_quantity"
                    min="1"
                    name="item_quantity"
                    type="number"
                    class="quantity__input"
                    [(ngModel)]="item.count"
                  />
                  <span
                    class="quantity__plus"
                    (click)="updateItemCount(item, 'add')"
                    >+</span
                  >
                </div>

                <div
                  class="ph6-semibold mt-2"
                  *ngIf="item.product_category == 'PACKAGE'"
                >
                  <span *ngIf="!item.discount.coupon_code" class="ptc4"
                    >Apply 'EARLYBIRD' to get 20% discount</span
                  >
                  <span *ngIf="item.discount.coupon_code" class="ptc7">
                    You got &#8377;
                    {{ item.discount.discount_amount }} OFF</span
                  >
                </div>

                <div
                  class="ph6-semibold mt-2"
                  *ngIf="item.product_category == 'COIN'"
                >
                  <span *ngIf="item.count < 10" class="ptc4">
                    Buy {{ 10 - item.count }} More coins to get 10%
                    discount</span
                  >
                  <span *ngIf="item.discount.coupon_code" class="ptc7">
                    You got &#8377;
                    {{ item.discount.discount_amount }} OFF</span
                  >
                </div>
              </div>

              <div>
                <div class="ph4-bold ptc1">&#8377; {{ item.final_value }}</div>
                <div
                  class="ph5-semibold ptc5"
                  *ngIf="item.discount.coupon_code"
                >
                  <s>&#8377; {{ item.base_price }}</s>
                </div>
              </div>
            </div>

            <!-- <pre>{{ item | json }}</pre> -->
          </div>

          <div class="cart-item my-4" *ngIf="!coins_added">
            <div class="d-flex tw-justify-between align-items-center">
              <div>
                <div class="ph3-semibold ptc1">Add Pickle Coin</div>
                <div class="ph6 ptc5 mt-2">
                  Pickle Coins are used to buy the products.
                  <span class="ptc12 tw-cursor-pointer"><u>Know More</u></span>
                </div>

                <button
                  class="btn btn-primary mt-2"
                  type="button"
                  (click)="addItemToCart('COIN')"
                >
                  Add to Cart
                </button>
              </div>

              <div class="tw-h-[200px] w-fit">
                <img
                  class="img-fluid h-100"
                  src="/assets/generic/wallet.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <pre>{{ cart | json }}</pre> -->
    </div>
    <!-- right -->
    <div class="col-lg-4">
      <div class="car_summary mb-4">
        <div class="ph5-bold ptc1">Payment Details</div>
        <div class="py-2">
          <select class="form-control" [(ngModel)]="payment_data.received_by">
            <option value="">Who Received Payment ?</option>
            <option value="bhargav">Bhargav</option>
            <option value="sughosha">Sughosha</option>
            <option value="abhishek">Abhishek</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div class="py-2">
          <select class="form-control" [(ngModel)]="payment_data.payment_mode">
            <option value="">Select Payment Method</option>
            <option value="upi">UPI</option>
            <option value="cash">Cash</option>
            <option value="bank_transfer">Bank Transfer</option>
            <option value="card">Card</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div class="py-2">
          <label for="pay_img">Payment Date</label>
          <input
            class="form-control"
            [(ngModel)]="payment_data.payment_date"
            type="date"
          />
        </div>

        <div class="py-2">
          <label for="pay_img">Payment Screnshot</label>
          <input
            class="form-control"
            F
            type="file"
            (change)="onSelectFile($event)"
            accept="image/jpg, image/jpeg, image/png"
            id="pay_img"
          />

          <div class="text-center">
            <img [src]="payment_data.img" class="img-fluid" style="max-height: 200px;" alt="">
          </div>
        </div>
      </div>

      <div class="car_summary">
        <div class="ph5-bold ptc1">Apply Coupon Code</div>
        <div class="flex_between_center">
          <div class="md-form md-outline form-lg w-100">
            <input
              id="coupon_code"
              class="form-control inputField"
              type="text"
              mdbInput
              placeholder="Enter Coupon Code"
              [(ngModel)]="coupon_code"
              name="coupon_code"
              (ngModelChange)="coupon_code = coupon_code.toUpperCase()"
            />
          </div>

          <button
            class="btn btn-secondary"
            type="button"
            (click)="SearchCoupon()"
          >
            Apply
          </button>
        </div>

        <div class="d-flex mt-3">
          <ng-container *ngFor="let item of cart.items">
            <div class="coupon-label" *ngIf="item.discount.coupon_code">
              <span class="ph7 ptc5">{{ item.discount.coupon_code }}</span>
              <mat-icon
                class="ph4 ptc5 ml-2 cursor_pointer"
                (click)="removeCoupon(item)"
                >close</mat-icon
              >
            </div>
          </ng-container>
        </div>

        <!-- <div class="ph6-semibold ptc6 mt-2">Invalid Coupon Code</div>
        <div class="ph6-semibold ptc7 mt-2">Coupon Code Applied</div> -->

        <!-- Order Summary -->
        <div>
          <hr class="horizontalLine" />
          <div class="ph5-bold ptc1">Order Summary</div>
          <div>
            <div class="flex_between_center mt-3">
              <div class="ph6 ptc5">
                Subtotal ({{ cart.items.length }} Items)
              </div>
              <div class="ph6-semibold ptc5">
                &#8377; {{ cart.base_price_total }}
              </div>
            </div>

            <div
              *ngIf="cart.discount_total > 0"
              class="flex_between_center mt-3 ptc18"
            >
              <div class="ph6">Discount</div>
              <div class="ph6-semibold">
                (-) &#8377; {{ cart.discount_total }}
              </div>
            </div>

            <hr class="horizontalLine" />

            <div class="flex_between_center mt-3">
              <div>
                <div class="ph6 ptc5">Amount Payable</div>
                <div class="ph8 ptc5">(Inc. Tax & Charges)</div>
              </div>

              <div class="ph4-bold ptc1">&#8377; {{ cart.payable_amount }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <button class="btn btn-primary" (click)="placeOrder()" type="button">
            Place Order
          </button>
        </div>
      </div>
      <!-- <pre>{{ payment_data | json }}</pre> -->
    </div>
  </div>
</div>
