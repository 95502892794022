import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-pkg-details',
  templateUrl: './booking-pkg-details.component.html',
  styleUrls: ['./booking-pkg-details.component.css']
})
export class BookingPkgDetailsComponent implements OnInit {

  constructor() { }
  @Input() package: any;
  view_type = 'normal';

  ngOnInit(): void {
  }

}
