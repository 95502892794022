<div class="tw-py-8">
  <div class="row tw-justify-center">
    <div class="col-md-4">
      <div class="border rounded tw-bg-white p-3">
        <div class="tw-text-center">Plan Details ()</div>

        <table class="tw-table-autos table-responsive" style="width: 100%">
          <tbody class="tw-table">
            <tr>
              <th>plan_name</th>
              <td class="tw-capitalize px-3">
                {{ data.deliverable.plan_data.plan_name }}
              </td>
            </tr>
            <tr>
              <th>plan_id</th>
              <td class="px-3">{{ data.deliverable.plan_data.plan_id }}</td>
            </tr>
            <tr>
              <th>validity</th>
              <td>
                <div class="px-3">
                  {{ data.deliverable.plan_data.validity.validity_value }}
                  {{ data.deliverable.plan_data.validity.validity_value_type }}
                </div>
                <div class="px-3">
                  {{
                    data.deliverable.plan_data.validity.start_date
                      | date : "d-MMM-yy"
                  }}
                  to
                  {{
                    data.deliverable.plan_data.validity.end_date
                      | date : "d-MMM-yy"
                  }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex align-item-center tw-justify-center">
    <div
      class="tw-w-fit tw-text-blue-800 tw-cursor-pointer"
      (click)="viewJSON(data)"
    >
      View Deliverables JSON
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Studio</div>
      <pre>{{ data.deliverable.studio_creation | json }}</pre>
    </div>
  </div>

  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Booking</div>
      <pre>{{ data.deliverable.booking_mgmt | json }}</pre>
    </div>
  </div>
  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Album</div>
      <pre>{{ data.deliverable.client_album | json }}</pre>
    </div>
  </div>

  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Invoice</div>
      <pre>{{ data.deliverable.invoice | json }}</pre>
    </div>
  </div>
  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Quotation</div>
      <pre>{{ data.deliverable.quotation | json }}</pre>
    </div>
  </div>
  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Billing</div>
      <pre>{{ data.deliverable.billing | json }}</pre>
    </div>
  </div>
</div>

<ng-template #json_modal let-modal>
  <div class="container p-3">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <div class="ph5-bold ptc1">View JSON</div>
        <!-- <div class="ph7 ptc5">
          You have not created any Studio yet. Please create your studio.
        </div> -->
      </div>
      <div>
        <mat-icon class="ph4 ptc1 cursor_pointer" (click)="onModalClose()"
          >close</mat-icon
        >
      </div>
    </div>
    <ng-container>
      <pre>{{ view_json | json }}</pre>
    </ng-container>
  </div>
</ng-template>
