import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';
import { BillPdfDownloadComponent } from 'src/app/components/genric/tools/billing/bill-pdf-download/bill-pdf-download.component';

var that: any;

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.css']
})

export class BillingDetailsComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
  ) { that = this; }


  @ViewChild(BillPdfDownloadComponent) private billPdfDownloadComponent!: BillPdfDownloadComponent;

  ngOnInit(): void {
    let bill_id = this.route.snapshot.params.bill_id;
    console.log(bill_id);
    this.fetchBillingDataById(bill_id)
  }

  fetchBillingDataById(bill_id: any) {
    this.adminDataService.getBillingDataById("fetch bill by bill_id", bill_id, this.successCB, this.errorCB);
  }

  onDownload() {
    this.billPdfDownloadComponent.onDownloadPDF();
  }

  bill_data: any;

  successCB(results: any) {
    if (results.api == "fetch bill by bill_id") {
      if (results.servercode == ServerCode.SUCCESS) {
        if (results.data != null && results.data != undefined) {
          that.bill_data = results.data.bill_data;
          console.log("bill", that.bill_data);
        }
      }
    }
  }

  errorCB(error: any) {
    console.log(error);
  }

}
